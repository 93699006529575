import {Component, Input, OnInit} from '@angular/core';
import {BreadCrumbs} from '../logo-area/logo-area.component';
import {Organization} from '../../types';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-switcher.component.html',
  styleUrls: ['./organization-switcher.component.scss']
})
export class OrganizationSwitcherComponent implements OnInit {

  breadcrumbs: BreadCrumbs = [
    {
      commands: null,
      display: 'Content Portal'
    },
    {
      commands: null,
      display: 'My Organizations'
    }
  ]
  @Input()
  organizations: Organization[];

  constructor() {
  }

  ngOnInit(): void {
  }


}
