<div class="modal-dialog">
<div class="modal-content">
  <div class="modal-header">Rename</div>
  <div class="modal-body cl-form">

    <label for="dest_name" class="form-label">New file name</label>
    <input id="dest_name" type="text" [(ngModel)]="dest_name">

  </div>
  <div class="modal-footer">
    <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="dialogRef.close()">
                        <i class="fas fa-angle-left"></i><span>Cancel</span>
                    </button></span>
    <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
    <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary" [class.cl-btn-spinner]="startmoving"
                            (click)="rename();"
                    >
                        <span>Rename file</span><i class="fas fa-angle-right"></i>
                    </button></span>
  </div>
</div>
</div>
