<app-logo-area [breadcrumbs]="this.breadcrumbs$ | async"></app-logo-area>


<main>
  <app-headline-section [title]="'Assets'"></app-headline-section>
  <ng-container *ngIf="(folder$ | async) as folder; else loading">

    <ng-container [ngSwitch]="view">
      <app-folder-listing *ngSwitchCase="'listing'" [folder]="folder" [organization]="organization"></app-folder-listing>
      <app-folder-asset-view *ngSwitchCase="'asset'" [folder]="folder" [organization]="organization"></app-folder-asset-view>
    </ng-container>
    <section class="cl-section-alternate" *ngIf="allowPoSubmit">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <app-cl-tabs >
              <!--
StartWorkflowAerqDefault
StartWorkflowAboveDefault
StartWorkflowVodBatch
StartWorkflowVodDefault
StartWorkflowDefault
-->
              <app-cl-tab tab_title="IFE Workflow" (activated)="this.poMetaService.setActive('above_default')"
                          *ngIf="'contentplatform:workflow:Mutation/StartWorkflowDefault'|isAllowed:'contentplatform:asset:input.content_identifier/input_brefix://' + (assetpath$ | async) + '/'">
                <app-above-default-po [organization]="organization" [airlines]="airlines"></app-above-default-po>
              </app-cl-tab>
              <app-cl-tab tab_title="AERQ Order" (activated)="this.poMetaService.setActive('aerq_default')"
                          *ngIf="'contentplatform:workflow:Mutation/StartWorkflowAerqDefault'|isAllowed :'contentplatform:asset:input.input_brefix/' + (assetpath$ | async) + '/'">
                <app-aerq-default-po [organization]="organization" [airlines]="airlines"></app-aerq-default-po>
              </app-cl-tab>

              <app-cl-tab tab_title="VOD Default" (activated)="this.poMetaService.setActive('vod_default')"
                          *ngIf="'contentplatform:workflow:Mutation/StartWorkflowVodDefault'|isAllowed:'contentplatform:asset:input.input_brefix/' + (assetpath$ | async) + '/'">
                <app-vod-default-po [organization]="organization" [airlines]="airlines"></app-vod-default-po>
              </app-cl-tab>
              <app-cl-tab tab_title="VOD Batch" (activated)="this.poMetaService.setActive('vod_batch')"
                          *ngIf="'contentplatform:workflow:Mutation/StartWorkflowVodBatch'|isAllowed:'contentplatform:asset:input.input_brefix/' + (assetpath$ | async) + '/'">
                <app-vod-watermark-po [organization]="organization" [airlines]="airlines"></app-vod-watermark-po>
              </app-cl-tab>

            </app-cl-tabs>
          </div>
        </div>
      </div>
    </section>

  </ng-container>
</main>
<ng-template #loading>
  <div style="width: 100%">
      <div style="margin: auto; width: fit-content;margin-top: 30%">Loading file directory... <i class="fas fa-spinner fa-spin"></i></div>
  </div>
</ng-template>

