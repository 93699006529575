import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {File, Folder, Organization} from '../../types';
import {ActivatedRoute} from '@angular/router';
import {UserProfileService} from '../../services/user-profile.service';


function fofTransformer(fof: string | File | Folder) {

  return fof;
}

@Component({
  selector: 'app-s3-link',
  templateUrl: './s3-link.component.html',
  styleUrls: ['./s3-link.component.scss']
})
export class S3LinkComponent implements OnInit, OnChanges {

  _fof: File | Folder;
  link: string;

  deepLink$: Observable<boolean>;

  @Input()
  set fof(fof: string | File | Folder) {
    if (fof) {
      if (typeof fof === 'string') {
        if (fof.endsWith('/')) {
          this._fof = {id: fof} as Folder
        } else {
          this._fof = {id: fof} as File
        }
      } else {
        this._fof = fof;
      }
    }
  }


  constructor(userProfileService: UserProfileService) {
    this.deepLink$ = userProfileService.staff
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._fof) {
      const sp = this._fof.id.split('/')
      this.link = `https://s3.console.aws.amazon.com/s3/buckets/${sp[2]}?region=us-east-1&prefix=${sp.slice(3, -1).join('/')}/&showversions=true`
    }
  }

  ngOnInit(): void {

  }
}
