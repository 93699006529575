<div style="position: relative">
  <div style="top:calc(50%);position: absolute; overflow: hidden; left: calc(50% - 48px) ; z-index: 3"
       *ngIf="playerState.isInitializing || playerState.isLoading">
    <div>
      <div class="cl-spinner"><i class="fas fa-spinner fa-spin"></i></div>
    </div>
  </div>

  <div id="player-container" class="player-container">
    <video id="player" crossOrigin="anonymous" style="height: 100%" (mousemove)="startMouseMovedTimer()">
      Your browser does not support HTML5 video.
    </video>

    <div class="footer-container"   (mouseenter)="mouseEnteredControlFooter()" (mouseleave)="mouseLeftControlFooter()"
           style="display: flex; flex-direction: row; gap: 8px; align-items: center; color: white"
         [style.opacity]="isVisible() ? 1 : 0">
      <div style="display: flex; flex-direction: row; gap: 8px; padding-left: 8px">
        <i role="button" class="fas fa-fw fa-step-backward" (click)="player.seek(player.getPosition() - 5)"></i>
        <i role="button" class="fas fa-fw fa-play" *ngIf="!playerState.isPlaying" (click)="play()"></i>
        <i role="button" class="fas fa-fw fa-pause" *ngIf="playerState.isPlaying" (click)="pause()"></i>
        <i role="button" class="fas fa-fw fa-step-forward" (click)="player.seek(player.getPosition() + 5)"> </i>
      </div>
      <div
        id="progress-bar-container"
        class="progress-bar-container"
        (mousedown)="startSeek($event)">
        <div
          class="progress-bar"
          [style.width]="getProgressPercent()">
        </div>
        <div
          class="progress-bar-button"
          [style.visibility]="playerState.isSeeking ? 'visible' : null"
          [style.left]="'calc(' + getProgressPercent() + ' - 6px)'">
        </div>
      </div>
      <div>
        <div style="text-shadow: 0 0 6px darkgrey;">{{ getFormattedProgress() }}</div>
        <div style="text-shadow: 0 0 6px darkgrey;">{{ getFormattedMediaRunTime() }}</div>
      </div>
      <div style="padding-right: 8px">
        <i role="button" *ngIf="!playerState.isFullscreen" class="fas fa-fw fa-expand-arrows-alt"
           (click)="fullscreenManager.requestFullscreen()"></i>
        <i role="button" *ngIf="playerState.isFullscreen" class="fas fa-fw fa-compress-arrows-alt"
           (click)="fullscreenManager.exitFullscreen()"></i>
      </div>
    </div>
  </div>
</div>
