import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(start_date: string|Date, end_date: string|Date): string {
    if (typeof start_date === 'string') {
      start_date = new Date(Date.parse(start_date))
    }
    if (typeof end_date === 'string') {
      end_date = new Date(Date.parse(end_date))
    }
    let runtime = end_date.getTime() - start_date.getTime();
    runtime /= 1000; // to seconds
    const symbols = ['h', 'm', 's']

    const arr: number[] = [];
    [3600, 60, 1].forEach(val => {
      arr.push(Math.floor(runtime / val));
      runtime %= val;
    });
    return arr.map((val, i) => val === 0 ? '' : val < 10 ? `0${val}${symbols[i]}` : `${val}${symbols[i]}`).join('');
  }
}
