import {Component, Input, OnInit} from '@angular/core';
import {SideNavService} from '../../services/side-nav.service';
import {environment} from '../../../environments/environment';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';


export type BreadCrumbs = BreadCrumb[]

@Component({
  selector: 'app-logo-area',
  templateUrl: './logo-area.component.html',
  styleUrls: ['./logo-area.component.scss']
})
export class LogoAreaComponent implements OnInit {

  @Input()
  breadcrumbs: BreadCrumbs;

  isSmall: Observable<boolean>;

  env = environment.graphql.split('.')[1].replace('content-', '').replace('content', 'production');

  constructor(public sidenav: SideNavService, public breakpointObserver: BreakpointObserver) {
     this.isSmall = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(map(a => a.matches))
  }

  ngOnInit(): void {
  }

}

export class BreadCrumb {
  commands: any[] | string | null | undefined;
  display: string
}
