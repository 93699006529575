<div *ngIf="opened" @growDown style="position: sticky;
  z-index: 10;
  top: 0; overflow: hidden">
  <div class="mat-elevation-z20 banner" [class.sidenav-closed]="!(this.sideNavService.isOpen | async)" [class.sidenav-opened]="(this.sideNavService.isOpen | async)" style="place-content: center space-between; display: flex; flex-direction: row">

    <p [innerHTML]="message" class="message mat-body-strong"></p>
    <div class="action-row">
      <button (click)="actionClicked(idx)"
              *ngFor="let action of actions; let idx=index;" class="btn btn-sm cl-btn-outline-primary"
              type="button">
        {{action}}
      </button>
    </div>

  </div>

</div>
