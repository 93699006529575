<div class="row cl-spacing-small" [formGroup]="form">
  <div class="col-3" [style.display]="vtk_template === 'aerq-ife-audio'?'none':'block'">
    <label for="encrypt" class="form-label">Encryption</label>
    <select id="encrypt" class="form-select" formControlName="encrypt">
      <option [ngValue]="false">Plain</option>
      <option [ngValue]="true">Encrypt</option>
    </select>
  </div>
  <div class="col-3" [style.display]="vtk_template === 'aerq-ife-audio'?'none':'block'">
    <label for="do_forensic_watermark" class="form-label">Forensic Watermark</label>
    <select id="do_forensic_watermark" class="form-select" formControlName="do_forensic_watermark">
      <option [ngValue]="false">No Watermark</option>
      <option [ngValue]="true">Insert Forensic Watermark</option>
    </select>
  </div>
  <div class="col-3" [style.display]="vtk_template === 'aerq-ife-audio'?'none':'block'">
    <label for="render_title_info" class="form-label">Visual Overlay</label>
    <select id="render_title_info" class="form-select" formControlName="render_title_info">
      <option [ngValue]="false">None (prod)</option>
      <option [ngValue]="true">Insert (test)</option>
    </select>
  </div>
  <div class="col-3" [style.display]="vtk_template === 'aerq-ife-audio'?'none':'block'">
    <label for="default_keys" class="form-label">Encryption Keys</label>
    <select id="default_keys" class="form-select" formControlName="default_keys">
      <option [ngValue]="false">Random Key (prod)</option>
      <option [ngValue]="true">Test Keys (test)</option>
    </select>
  </div>

  <div class="col-3" [style.display]="vtk_template === 'aerq-ife-audio'?'block':'none'">
    <label for="album_cover_url" class="form-label">Album Cover URL</label>
    <input id="album_cover_url" type="text" formControlName="album_cover_url">
    <p *ngIf="form.controls.album_cover_url.errors?.required" class="invalid-feedback">This field is
      required.</p>
  </div>
  <div class="col-3" [style.display]="vtk_template === 'aerq-ife-audio'?'block':'none'">
    <label for="artist" class="form-label">Artist Name</label>
    <input id="artist" type="text" formControlName="artist">
    <p *ngIf="form.controls.artist.errors?.required" class="invalid-feedback">This field is
      required.</p>
  </div>
  <div class="col-3" [style.display]="vtk_template === 'aerq-ife-audio'?'block':'none'">
    <label for="album" class="form-label">Album Name</label>
    <input id="album" type="text" formControlName="album">
    <p *ngIf="form.controls.album.errors?.required" class="invalid-feedback">This field is
      required.</p>
  </div>
  <div class="col-3" [style.display]="vtk_template === 'aerq-ife-audio'?'block':'none'">
    <label for="title" class="form-label">Song Title</label>
    <input id="title" type="text" formControlName="title">
    <p *ngIf="form.controls.title.errors?.required" class="invalid-feedback">This field is
      required.</p>
  </div>
</div>
