<app-logo-area></app-logo-area>
<main>
  <app-headline-section [title]="'Audio Order'"></app-headline-section>
  <section class="cl-section-regular">
    <div class="container cl-form " [formGroup]="poFormGroup">
      <div class="row">
        <div *ngIf="airlines.length > 1" class="col-4">
          <label for="airline" class="form-label">Airline<span class="required">*</span></label>
          <select id="airline" class="form-select" formControlName="airline">
            <option *ngFor="let airline of airlines" [value]="airline.iata_code"> {{ airline.airline_name }}
              ({{ airline.iata_code }})
            </option>
          </select>
          <p *ngIf="poFormGroup.controls.airline.errors?.required" class="invalid-feedback">This field is required.</p>
          <p *ngIf="!poFormGroup.controls.airline.errors" class="valid-feedback">Selects the airline on castLabs'
            side.</p>
        </div>


        <div [class.col-4]="airlines.length > 1" [class.col-8]="airlines.length === 1">
          <label for="po_number" class="form-label">PO Name<span class="required">*</span></label>
          <input id="po_number" type="text" formControlName="po_number" pattern="[A-Za-z0-9_\-.]+">
          <p *ngIf="poFormGroup.controls.po_number.errors?.required" class="invalid-feedback">This field is
            required.</p>
          <p *ngIf="poFormGroup.controls.po_number.errors?.pattern" class="invalid-feedback">Only letters, numbers and
            '_', '-' or '.' are allowed</p>
          <p *ngIf="!poFormGroup.controls.po_number.errors" class="valid-feedback">The encoding PO number for later
            reference</p>
        </div>
        <div class="col-4">
          <label for="email_notification" class="form-label">Email Notification</label>
          <input id="email_notification" type="text" formControlName="email_notification">
          <p *ngIf="poFormGroup.controls.email_notification.errors?.required" class="invalid-feedback">This field is
            required.</p>
          <p *ngIf="poFormGroup.controls.email_notification.errors?.email" class="invalid-feedback">Please insert a
            valid
            email.</p>
          <p *ngIf="!poFormGroup.controls.email_notification.errors" class="valid-feedback">A notification email will be
            sent to this address</p>
        </div>
        <div class="col-4 ">
          <label for="ife" class="form-label">IFE System<span class="required">*</span></label>
          <select id="ife" class="form-select" formControlName="ife" [compareWith]="compare_item_id">
            <option [disabled]="sys.formats.length == 0" *ngFor="let sys of availableSystems"
                    [value]="sys.item_id">{{ sys.item_text }}
            </option>
          </select>
          <p *ngIf="poFormGroup.controls.ife.errors?.required" class="invalid-feedback">This field is required.</p>
          <p *ngIf="!poFormGroup.controls.ife.errors" class="valid-feedback">Select the IFE system.</p>

        </div>

        <div class="col-4 ">
          <label for="ife_format" class="form-label">IFE Format<span class="required">*</span></label>
          <select id="ife_format" class="form-select" formControlName="ife_format" [compareWith]="compare_item_id">
            <option *ngFor="let sys of ifeFormats" [value]="sys.item_id">{{ sys.item_text }}</option>
          </select>
          <p *ngIf="poFormGroup.controls.ife_format.errors?.required" class="invalid-feedback">This field is
            required.</p>
          <p *ngIf="!poFormGroup.controls.ife_format.errors" class="valid-feedback">Select the desired format.</p>
        </div>

        <div class="col-4 ">
          <label for="po_destination" class="form-label">Delivery Destination<span class="required">*</span></label>
          <select id="po_destination" class="form-select" formControlName="po_destination"
                  [compareWith]="compare_destination_id">
            <option *ngFor="let sys of poDestinations" [value]="sys.destination_id">{{ sys.display_name }}</option>
          </select>
          <p *ngIf="poFormGroup.controls.po_destination.errors?.required" class="invalid-feedback">This field is
            required.</p>
          <p *ngIf="!poFormGroup.controls.po_destination.errors" class="valid-feedback">Select the desired
            destination.</p>
        </div>

        <div class="col-4 ">
          <label for="auto_publish" class="form-label">Publication</label>
          <select id="auto_publish" class="form-select" formControlName="auto_publish">
            <option [value]="true"> Automatic Publication
            </option>
            <option [value]="false"> Manual Publication
            </option>
          </select>
          <p class="valid-feedback">Manual Publication provides a final QA step before distributing the content, whereas
            Automatic Publication releases the content immediately upon readiness.</p>
        </div>
        <div class="col-4 ">
          <label for="idType" class="form-label">Identifier type</label>
          <select id="idType" class="form-select" formControlName="idType">
            <option [value]="'isrc'">ISRC
            </option>
            <option [value]="'umg-upc'">UMG UPC
            </option>
          </select>
          <p class="valid-feedback">Select identifier type.</p>
        </div>
      </div>

      <div class="row cl-spacing-box-huge">
        <div class="col-12"><h2>Tracks</h2></div>
      </div>

      <div class="row" formArrayName="poItems">

        <div class="col-12" style="padding-top: 16px" *ngFor="let item of poItems.controls; let i = index" [formGroupName]="i">
          <div style="display: flex; ">
            <div style="flex-grow: 1; padding-right: 8px">
              <input (paste)="onPaste($event, i)"
                type="text"
                formControlName="id"
                placeholder="Track Id"
                (input)="onInputChange(i)"
              />
            </div>
            <div style="flex-grow: 1; padding-left: 8px">
              <input
                type="text"
                formControlName="filename"
                placeholder="Output Filename"
                (input)="onInputChange(i)"
              />
            </div>
          </div>
        </div>


      </div>


      <div class="row cl-spacing-small">

        <div class="col-12" style="display: flex">
          <div>
            <button *ngIf="started" (click)="reset()" type="button" class="btn btn-sm cl-btn-outline-text"><span>Reset</span><i
              class="fas fa-undo-alt"></i></button>
          </div>
          <div style="flex-grow: 1"></div>
          <div style="display: flex; flex-direction: column">
            <div>
              <button class="btn btn-sm cl-btn-primary" style="float: right;" [disabled]="poFormGroup.invalid" (click)="submitPo()">
                <span>Start Encode</span><i
                class="fas fa-angle-right"></i></button>
            </div>
            <div style="float: right; text-align: right">
              <p *ngIf="poFormGroup.invalid" class="alert cl-alert-warning" role="alert">Please fill out all required
                fields.</p>
              <p *ngIf="starting" class="alert cl-alert-info" role="alert">Starting encode...</p>
              <p *ngIf="started" class="alert cl-alert-info" role="alert">Started encode. <a
                [routerLink]="['/o', this.organization.id, 'workflows', this.poFormGroup.value.airline, this.poFormGroup.value.airline + '_' + this.poFormGroup.value.po_number]">Go
                to {{ this.poFormGroup.value.airline + '_' + this.poFormGroup.value.po_number }}</a></p>
              <p *ngIf="error" class="alert cl-alert-warning" role="alert">{{error}}</p>

            </div>
          </div>
        </div>
      </div>

    </div>

  </section>

</main>
