import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {Apollo, gql} from 'apollo-angular';
import {Folder, Mutation} from '../../../types';
import {BannerService} from '../../../banner/banner.service';
import {FILE_FOLDER_LIST_WITH_ARCHIVE} from '../../../queries';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';

@Component({
  selector: 'app-mkdir-dialog',
  templateUrl: './mkdir-dialog.component.html',
  styleUrls: ['./mkdir-dialog.component.scss']
})
export class MkdirDialogComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  new_dir_name: string;
  folder: Folder;

  mkdiring = false;

  buttonEnabled = true

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: { folder: Folder },
    private apollo: Apollo,
    public banner: BannerService,
    public snackBar: MatSnackBar) {
    this.folder = data.folder;
  }

  ngOnInit() {
  }

  mkdir() {
    if (this.new_dir_name) {
      this.mkdiring = true;
      const dirs = this.new_dir_name.split(/\r?\n/)
      let mkdirs = ''
      for (const dir of dirs) {
        // tslint:disable-next-line:no-bitwise
        const gql_alias = [...Array(32)].map(() => (~~(Math.random() * 36)).toString(36)).join('').replace(/[0-9]/g, '');
        mkdirs += gql_alias + ': mkdir(input: {folder_id: "' + this.folder.id + dir + '/"}) {\n' +
          '    parent {\n' +
          '      folders {\n' +
          '        id\n' +
          '        name\n' +
          '      }\n' +
          '    }\n' +
          '  }\n'
      }
      console.log(mkdirs)
      this.apollo.mutate<Mutation>({
        mutation: gql('mutation MyMutation {\n' + mkdirs + '\n}'),
        refetchQueries: [{
          query: FILE_FOLDER_LIST_WITH_ARCHIVE,
          variables: {
            show_deleted: true,
            id: this.folder.id
          },
        }]
      }).subscribe(
        () => {
          this.mkdiring = false;
          this.snackBar.open(`Successfully created ${this.folder.name}/${this.new_dir_name}`, '', {
            duration: 5000
          });
          this.dialogRef.close();
        },
        err => {
          this.mkdiring = false;
          this.banner.open(`Failed to create  ${this.folder.name}/${this.new_dir_name}: ${err}`, ['Close'])
        })
    }
  }

  close() {
    this.dialogRef.close();
  }
}
