import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {GET_ORGANIZATIONS} from './queries';
import {Observable} from 'rxjs';
import {Organization, Policy, Query} from './types';
import {map, tap} from 'rxjs/operators';

export const organizationResolver: ResolveFn<Observable<Organization>> = (route, state) => {
  const apollo = inject(Apollo);
  return apollo.query<Query>({
    query: GET_ORGANIZATIONS
  }).pipe(
    map(res => res.data.organizations.organizations),
    map(orgs => orgs.find(org => org.id === route.params['organization_id']))

  );
};


