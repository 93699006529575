import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, tap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {Airline, PoItem, Query} from '../../../types-workflow';
import gql from 'graphql-tag';
import {FormControl, FormGroup} from '@angular/forms';
import {BreadCrumb} from '../../logo-area/logo-area.component';
import {Organization} from '../../../types';

const query = gql`
query GetPreviews($airline: String!, $date_created: AWSDateTime) {
  list_POs(input: {
       filter: {
           airline: { eq: $airline}
           date_created: {gt: $date_created}
           } }){
    pos {
      id
      airline
      po_name
      date_created
      poitems {
        filename
        id
        po_item_id
        preview {
          dash_manifest_last_modified
          dash_manifest_url
          hls_manifest_url
          hls_manifest_last_modified
        }
        po {
          po_name
          airline
        }
      }
    }
  }
}
`

@Component({
  selector: 'app-preview',
  templateUrl: './list-previews.component.html',
  styleUrls: ['./list-previews.component.scss']
})
export class ListPreviewsForAirlineComponent implements OnInit {
  @Input()
  airline: Airline;

  @Input()
  organization: Organization;

  title = new BehaviorSubject<string>(null);
  po$: Observable<string> = null;
  poItems$: Observable<PoItem[]> = null;
  poItemsSortedFiltered$: Observable<PoItem[]> = null;
  loading = true;

  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
  });
  breadCrumbs: BreadCrumb[];

  ngOnInit() {

    this.breadCrumbs = [
      {
        display: 'Content Portal',
        commands: ['/'],
      },
      {
        display: 'Workflows',
        commands: ['/workflows'],
      },
      {
        display: this.airline.airline_name,
        commands: ['/workflows', this.airline.iata_code],
      }
    ];
    const sevenweeksago = new Date();
    sevenweeksago.setDate(sevenweeksago.getDate() - 7 * 7);
    this.poItems$ = this.apollo.use('workflow').query<Query>({
      query: query,
      errorPolicy: 'ignore',
      variables: {
        airline: this.airline.iata_code,
        date_created: sevenweeksago.toISOString()
      }
    }).pipe(
      map(res => res.data.list_POs.pos),
      map(pos => {
        pos = pos.map(po => Object.assign({}, po))
        for (const po of pos) {
          po.poitems = po.poitems.map(poitem => Object.assign({}, poitem))
          po.poitems = po.poitems.filter(poitem =>
            poitem.preview !== null && (poitem.preview.hls_manifest_url !== null || poitem.preview.dash_manifest_url !== null))
        }
        pos = pos.filter(po => po.poitems.length > 0)
        return pos
      }),
      map(pos => {
        return pos.map(po => po.poitems).reduce((a, b) => [].concat(a, b), [])
      }),
      tap(poitems => this.loading = false),
      tap(poitems => console.log(poitems)),
    )
    this.poItemsSortedFiltered$ = combineLatest(
      [
        this.poItems$,
        this.filters.valueChanges
      ]).pipe(
      map(([poitems, filters]) => {
        return poitems.filter(poitem => {
          return poitem.po_item_id.toLowerCase().includes((filters['t'] || '').toLowerCase()) ||
            poitem.po.po_name.toLowerCase().includes((filters['t'] || '').toLowerCase());
        })
      })
    )
  }

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo) {

    this.po$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('po')),
    )

  }
}
