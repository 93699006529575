import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {GET_AIRLINES, GET_POLICIES} from './queries';
import {Observable} from 'rxjs';
import {Airline, Query} from './types-workflow';
import {filter, map, tap} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';

export const airlinesResolver: ResolveFn<Observable<Airline[]>> = (route, state) => {
  const apollo = inject(Apollo);
  const query = GET_AIRLINES(route.params['organization_id']);
  return apollo.use('workflow').query<Query>({
    query: query,
    context: {
      headers: new HttpHeaders({
        'X-Castlabs-Organization': route.params['organization_id'],
        'Organization': route.params['organization_id'],
      })
    }
  }).pipe(
    map(res => res.data.airlines.airlines),
    filter(airlines => !!airlines),
    map(airlines => [...airlines].sort((a, b) => a.airline_name.localeCompare(b.airline_name))));
};



