import {Component, OnInit} from '@angular/core';
import {
  ChangePasswordCommand,
  CognitoIdentityProviderClient,
  GetUserCommand,
  UpdateUserAttributesCommand
} from '@aws-sdk/client-cognito-identity-provider';
import {environment} from '../../../environments/environment';
import {OAuthService} from 'angular-oauth2-oidc';
import {MFASetupComponent} from './mfasetup/mfasetup.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BannerService} from '../../banner/banner.service';
import {Dialog} from '@angular/cdk/dialog';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  family_name = '';
  given_name = '';
  oldpw: string;
  newpw: string;
  sub = '';
  email = '';
  hasmfa: boolean;
  client = new CognitoIdentityProviderClient({region: environment.region});

  constructor(
    private oauth: OAuthService,
    private dialog: Dialog,
    private snackBar: MatSnackBar,
    private banner: BannerService
  ) {

  }

  updateData(): void {

    const command = new GetUserCommand({
      AccessToken: this.oauth.getAccessToken()
    });
    const response = this.client.send(command).then(value => {
      for (const userAttribute of value.UserAttributes) {
        if (userAttribute.Name === 'given_name') {
          this.given_name = userAttribute.Value
        }
        if (userAttribute.Name === 'family_name') {
          this.family_name = userAttribute.Value
        }
        if (userAttribute.Name === 'sub') {
          this.sub = userAttribute.Value
        }
        if (userAttribute.Name === 'email') {
          this.email = userAttribute.Value
        }
      }
      console.log(value.UserAttributes)
      console.log(value.Username)
      console.log(value.UserMFASettingList)
      this.hasmfa = value.UserMFASettingList !== undefined && value.UserMFASettingList.find((v) => v === 'SOFTWARE_TOKEN_MFA') === 'SOFTWARE_TOKEN_MFA'
    });

  }

  save(): void {
    const command = new UpdateUserAttributesCommand({
      AccessToken: this.oauth.getAccessToken(),
      UserAttributes: [
        {
          Name: 'family_name',
          Value: this.family_name
        },
        {
          Name: 'given_name',
          Value: this.given_name
        },
      ]
    })
    this.client.send(command).then(v1 => {
      if (!(this.newpw === undefined || this.newpw === '' || this.oldpw === '' || this.oldpw === undefined)) {
        const command2 = new ChangePasswordCommand({
          AccessToken: this.oauth.getAccessToken(),
          PreviousPassword: this.oldpw,
          ProposedPassword: this.newpw
        })
        this.client.send(command2).then(v2 => {
          this.snackBar.open('Name and password changed successfully.', null, {duration: 5000})
        }).catch(reason => {
          this.banner.open(reason, ['CLOSE'])
        })
      } else {
        this.snackBar.open('Name updated successfully', null, {duration: 5000})
      }
    }).catch(reason => {
      this.banner.open('Name not updated. ' + reason, ['CLOSE'])
    })


  }

  ngOnInit(): void {
    this.updateData()
  }

  mfa() {
    this.dialog.open(MFASetupComponent, {
      data: {
        access_token: this.oauth.getAccessToken(),
        email: this.email
      }
    }).closed.subscribe(() => {
      this.updateData()
    });

  }

}
