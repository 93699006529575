import {Pipe, PipeTransform} from '@angular/core';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
 */
@Pipe({name: 'removeLastSegment'})
export class RemoveLastSegmentPipe implements PipeTransform {

  transform(inPath: string): string {
    if (inPath.endsWith('//')) {
      return inPath.substr(0, inPath.length - 2);
    }
    const pathSegments = inPath.split('/');
    pathSegments.pop();
    if (pathSegments.length > 0) {
      return pathSegments.join('/') + '/'
    } else {
      return ''
    }
  }
}

@Pipe({name: 'lastSegment'})
export class LastSegmentPipe implements PipeTransform {

  transform(inPath: string): string {
    if (inPath.endsWith('//')) {
      return '/';
    }
    const pathSegments = inPath.split('/').filter(s => s.length > 0);
    return pathSegments.pop();
  }
}

@Pipe({name: 'addBreakers'})
export class AddBreakersPipe implements PipeTransform {

  transform(inPath: string): string {

    inPath = inPath.replace(/\//g, '/\u200B')
    inPath = inPath.replace(/_/g, '_\u200B')
    return inPath
  }
}
