import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contains',
  pure: true
})
export class ContainsPipe implements PipeTransform {

  transform(array: any[], value: string): boolean {
    return array.some(item => item === value);
  }

}
