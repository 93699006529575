<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Download encodes</div>
    <div class="modal-body">
      <table class="cl-table-data cl-table-data-light">
        <thead>
        <tr>
          <th class="cl-table-head cl-p-tiny ">
            Filename
          </th>
          <th class="cl-table-head cl-p-tiny ">
            Last Modified
          </th>
          <th class="cl-table-head cl-p-tiny ">
            Actions
          </th>
        </tr>
        </thead>
        <tbody *ngIf="encodes$ | async as encodes; else loading">
        <ng-container *ngIf="encodes.length > 0; else none">
          <tr *ngFor="let file of encodes">
            <td>
              {{ file.name }}
            </td>
            <td>
              {{ file.last_modified | date: 'd MMM y,&nbsp;HH:mm' }}
            </td>
            <td class="cl-td-links">
              <a class="cl-a-btn cl-a-primary" role="button" *ngIf="'contentplatform:repository:Mutation/GenerateDownloadUrl' | isAllowed:'contentplatform:folder:input.file_id/' + file.id" (click)="download(file)">Download</a>
              <a class="cl-a-btn" role="button" (click)="deleteEncode(file)">Delete</a>
            </td>
          </tr>
        </ng-container>
        <ng-template #none>
          <tr class="cl-tr-no-data">
            <td colspan="100">No encodes available. Please wait for encodes to finish.</td>
          </tr>
        </ng-template>
        </tbody>

        <ng-template #loading>
          <td colspan="100" style="text-align: center">
            <div class="cl-spinner">
              Loading ...<i class="fas fa-spinner fa-spin"></i>
            </div>
          </td>
        </ng-template>
      </table>
    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    </span>
      <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok"
                            (click)="dialogRef.close();">
                        <span>Close downloads</span><i class="fas fa-angle-right"></i>
                    </button></span>
    </div>
  </div>
</div>
