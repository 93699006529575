<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Select Text Track</div>
    <div class="modal-body">
      <div class="form-check" aria-label="Select Stream Type"
           *ngFor="let rendition of renditions">
        <input type="radio" class="form-check-input" id="radio{{rendition}}" name="type" [value]="rendition.id"
               [(ngModel)]="currentlySelectedRendition">
        <label class="form-check-label" for="radio{{rendition}}">
          <ng-container *ngIf="streamTypeHLS">{{ rendition?.track.label }}</ng-container>
          <ng-container
            *ngIf="streamTypeDASH">{{ rendition?.track.language }} {{ rendition?.track.kind }}
          </ng-container>
        </label>
      </div>
      <div class="form-check" aria-label="Select Stream Type"
           >
        <input type="radio" class="form-check-input" id="radio_none" name="type" [value]="-1"
               [(ngModel)]="currentlySelectedRendition">
        <label class="form-check-label" for="radio_none">None
        </label>
      </div>

    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none"><button class="btn cl-btn-outline-text"
                                                                             (click)="dialogRef.close()"><i
        class="fas fa-angle-left"></i><span>Cancel</span></button></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
      <button class="btn cl-btn-outline-primary" (click)="dialogRef.close(currentlySelectedRendition)"> <span>change track</span><i
        class="fas fa-angle-right"></i></button>
      </span>

    </div>
  </div>
</div>
