import {Injectable} from '@angular/core';
import {clean_datetime, PORoot} from './utils';
import {OAuthService} from 'angular-oauth2-oidc';
import {BehaviorSubject, Subject} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // tslint:disable-next-line:no-bitwise
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


@Injectable()
export class Po2024Service {

  public active: boolean


  private formState = new BehaviorSubject<any>(null);

  private poItemToAdd = new Subject<{
    id: string,
    basics: {
      po_item_id: string,
      input_brefix: string,
    },
    tracks: {
      audio: string[],
      subtitles: string[],
      cc: string[],
      images: string[],
    }
  }>();
  private poItemIdToRemove = new Subject<string>();


  poItemToAdd$ = this.poItemToAdd.asObservable();
  poItemToRemove$ = this.poItemIdToRemove.asObservable();

  constructor(
    oAuthService: OAuthService,
    public action: string
  ) {
    const dateCreated = new Date();
    const po = {
      po_number: clean_datetime(dateCreated),
      airline: null,
      due_date: 'now',
      date_created: dateCreated,
      ife: null,
      ife_format: null,
      poItems: [],
      email_notification: oAuthService.getIdentityClaims()['email']
    }
  }


  addToPO(folder_id: string,
          preselected_audio: string[],
          preselected_subtitles: string[],
          preselected_cc: string[],
          images: string[]) {
    let title = 'title'
    for (const element of folder_id.split('/')) {
      if (element !== '') {
        title = element
      }
    }
    console.log('adding to po: ' + title)
    this.poItemToAdd.next({
      id: uuidv4(),
      basics: {
        po_item_id: title.substring(0, 70),
        input_brefix: folder_id.substring(5), // s3://....
      },
      tracks: {
        audio: preselected_audio,
        subtitles: preselected_subtitles,
        cc: preselected_cc,
        images: images,
      }
    });
  }

  public removePOItem(id: string) {
    this.poItemIdToRemove.next(id)
  }

  setFormState(value: any) {
    this.formState.next(value);
  }

  getFormState() {
    return this.formState.asObservable().pipe(shareReplay(1));
  }
}
