import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Apollo} from 'apollo-angular';
import {File, Folder, Mutation, ProcessStateEnum, Query} from '../../../types';
import gql from 'graphql-tag';
import {Observable, of, forkJoin} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {BannerService} from '../../../banner/banner.service';
import {Router} from '@angular/router';
import {FILE_FOLDER_LIST} from '../../../queries';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';

@Component({
  selector: 'app-move-file-dialog',
  templateUrl: './move-file-dialog.component.html',
  styleUrls: ['./move-file-dialog.component.scss']
})
export class MoveFileDialogComponent implements OnInit {
  src_basefolder: string;
  dst_folder: string;
  selection: [File | Folder]
  startmoving = false;

  files: Observable<(File | Folder)[]>;

  constructor(public dialogRef: DialogRef<MoveFileDialogComponent>,
              @Inject(DIALOG_DATA) public data: { basefolder: string, selection: [File | Folder] },
              private apollo: Apollo,
              public snackBar: MatSnackBar,
              public router: Router,
              public banner: BannerService
  ) {
    this.src_basefolder = data.basefolder;
    this.selection = data.selection;
    this.dst_folder = this.src_basefolder
    const _f: (Observable<File[]> | Observable<Folder[]>) [] = []
    for (const item of this.selection) {
      if (item.__typename === 'Folder') {
        _f.push(this.apollo.query<Query>({
          query: gql`query list($id: ID!) {
            folder(id: $id) {
              id
              files {
                id
                name
                archived
              }
            }
          }`,
          variables: {
            id: item.id
          }
        }).pipe(
          map(d => {
              return d.data.folder.files.map(e => {
                const f = Object.assign({}, e)
                f.name = item.name + '/' + f.name
                return f
              })
            }
          )))
        console.log('push folder ' + item.id)
        _f.push(of([item as Folder]))
      } else {
        console.log('push file ' + item.id)
        _f.push(of([item as File]))
      }

    }

    this.files = forkJoin(..._f).pipe(map(f => {
      console.log('lemme concat')
      return [].concat(...f) as (File | Folder)[];
    }))
  }

  ngOnInit() {


  }


  public do_move() {

    this.files.pipe(
      mergeMap(files => {

        let mutation = 'mutation MyMutation {\n'
        files.forEach((value, i) => {
          mutation += `move${i}: move_file(input: {src: "${value.id}", dest: "${this.dst_folder}${value.id.substring(this.src_basefolder.length)}"}) {
                state
                id
              }
              `
        })
        mutation += '}'
        return this.apollo.mutate<Mutation>({
          mutation: gql(mutation)
        })
      })
    ).subscribe(d => {
      this.dialogRef.close()
      const ref = this.snackBar.open(`Moving  to ${this.dst_folder}  ... `, '', {
        duration: 5000
      });

      let query = 'query MyQuery {\n';
      for (const key of Object.keys(d.data)) {
        query += `${key}: process(id: "${d.data[key].id}") {
              state
              id
              data
              message
            }
            `
      }
      query += '}'

      const wq = this.apollo.watchQuery({
        query: gql(query),
        pollInterval: 1000
      }).valueChanges.subscribe(e => {
        for (const key of Object.keys(e.data)) {
          if (e.data[key].state === ProcessStateEnum.Failed) {
            let add = ''
            if (Object.keys(e.data).length > 1) {
              add = 'Some files might have been moved. '
            }
            this.banner.open(`The move operation did not succeed. ${add}. [${e.data[key].id}]`, ['Close'])
            wq.unsubscribe()
            return
          }
          if (e.data[key].state === ProcessStateEnum.InProgress) {
            return
          }
        }
        wq.unsubscribe()

        // manual refetch as there is no mutation in the end
        this.apollo.query({
          query: FILE_FOLDER_LIST,
          fetchPolicy: 'network-only',
          variables: {
            id: this.src_basefolder,
            show_deleted: true
          }
        }).subscribe()
        this.apollo.query({
          query: FILE_FOLDER_LIST,
          fetchPolicy: 'network-only',
          variables: {
            id: this.dst_folder,
            show_deleted: true
          }
        }).subscribe()

        this.files.subscribe(files => {
          const filenames = files.map(f => f.name)
          this.snackBar.open(
            `${this.src_basefolder}/${filenames.join(' | ')} successfully moved to ${this.dst_folder}`,
            'go to folder',
            {duration: 15000}).onAction().subscribe(() => {
              console.log('e')
              this.router.navigate(['files/' + this.dst_folder.substring(5)])
            }
          )

        })

      })
    })

  }

  public move() {
    if (this.src_basefolder === this.dst_folder) {
      const ref = this.snackBar.open('Destination cannot be the same as the source. Not moving.');
      return
    }


    this.do_move();
  }

}
