
<app-logo-area></app-logo-area>
<main>
  <app-headline-section [title]="'Search'"></app-headline-section>
  <app-filter-section [openByDefault]="true" [filtered]="hits">
    <form [formGroup]="filters" class="cl-form" >
      <input type="text" style="width: 100%" formControlName="t"
             placeholder="Search by name and path">
      <p class="valid-feedback">Search terms are case-insensitive.</p>
    </form>
  </app-filter-section>

  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <table class="cl-table-data">
            <colgroup>
              <col span="1" style="width: 40px">
              <col span="1" style="width: 100%">
              <col span="1" style="width: 180px">
            </colgroup>
            <thead>
            <tr>
              <th class="cl-table-head cl-p-tiny ">
                <div>
                  Type
                </div>
              </th>
              <th class="cl-table-head cl-p-tiny ">
                <div>
                  File or folder name
                </div>
              </th>
              <th class="cl-table-head cl-p-tiny ">
                <div>
                  Last Modified
                </div>
              </th>
            </tr>
            </thead>
            <ng-container *ngIf="search_ongoing; else bodySearchDone">
              <tbody *ngIf="hits.length === 0; else bodyWithData">
              <tr class="cl-tr-no-data">
                <td colspan="100" style="text-align: center">
                  <div class="cl-spinner">
                    Loading ...<i class="fas fa-spinner fa-spin"></i>
                  </div>
                </td>
              </tr>
              </tbody>
            </ng-container>
            <ng-template #bodySearchDone>
              <tbody *ngIf="hits.length === 0; else bodyWithData">
              <tr class="cl-tr-no-data">
                <td colspan="100" style="text-align: center">No files found.</td>
              </tr>
              </tbody>
            </ng-template>
            <ng-template #bodyWithData>
              <tbody>
              <tr *ngFor="let it of hits">
                <td>
                  <app-file-icon [filename]="it.key"></app-file-icon>
                </td>
                <td>
                  <a  [routerLink]="'/o/' + this.organization.id + '/browse/' +  it.brefix">{{it.key | addBreakers}}</a>
                </td>
                <td>
                  {{it.timestamp | date: 'd MMM y,&nbsp;HH:mm'}}
                </td>
              </tr>
              </tbody>
            </ng-template>

          </table>
        </div>
      </div>
    </div>
  </section>
</main>
