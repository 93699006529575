import {Component, Input, OnInit} from '@angular/core';

import {BreadCrumb} from '../logo-area/logo-area.component';
import {Airline} from '../../types-workflow';
import {Organization} from '../../types';

@Component({
  selector: 'app-previews',
  templateUrl: './previews.component.html',
  styleUrls: ['./previews.component.scss'],
})
export class PreviewsComponent implements OnInit {

  @Input()
  airlines: Airline[];

  @Input()
  organization: Organization;

  breadCrumbs: BreadCrumb[] = [];


  constructor() {
  }

  ngOnInit(): void {
    this.breadCrumbs = [
      {display: 'Content Portal', commands: ['/o', this.organization.id]},
      {display: 'Previews', commands: ['/o', this.organization.id, 'previews']},
    ]
  }
}
