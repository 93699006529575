import {Component, NgZone, OnInit} from '@angular/core';
import {Idle} from '@ng-idle/core';
import {DialogRef} from '@angular/cdk/dialog';

@Component({
  selector: 'app-warn-logout',
  templateUrl: './warn-logout.component.html',
  styleUrls: ['./warn-logout.component.scss']
})
export class WarnLogoutComponent implements OnInit {

  constructor(public idle: Idle, public dialogRef: DialogRef<WarnLogoutComponent>, private ngZone: NgZone) {
    this.idle.onIdleEnd.subscribe(() => {
      ngZone.run(() => dialogRef.close())
    })
    this.idle.onTimeout.subscribe(() => {
      ngZone.run(() => dialogRef.close())
    })
    idle.onTimeoutWarning.subscribe(n => console.log(new Date().toISOString() + ': ' + n))
  }

  ngOnInit(): void {
  }

}
