import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {Airline, Query as WorkflowQuery} from '../../types-workflow';

import {debounceTime, map, take} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Organization} from '../../types';

@Component({
  selector: 'app-airline-select-sections',
  templateUrl: './airline-select.component.html',
  styleUrls: ['./airline-select.component.scss']
})
export class AirlineSelectComponent implements OnInit {
  @Input()
  organization: Organization;

  @Input()
  airlines: Airline[];

  airlines$: Observable<Airline[]>;
  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
  });

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
/*    if (this.airlines.length === 1) {
      this.router.navigate(['.', this.airlines[0].iata_code ], {replaceUrl: true, relativeTo: this.activatedRoute}).then(a => console.log('navidated to airline', a))
    }*/
    this.airlines$ = this.filters.valueChanges.pipe(
      map((filters) => this.airlines.filter(airline => {
        return airline.airline_name.toLowerCase().includes((filters['t'] || '').toLowerCase()) ||
          airline.iata_code.toLowerCase().includes((filters['t'] || '').toLowerCase())
      })),
    );
  }

}
