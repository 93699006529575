import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ResolveFn, Router, RouterOutlet} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {Organization, Policy, Query} from '../../types';
import {GET_POLICIES} from '../../queries';
import {OrganizationService} from '../../services/organization.service';
import {map, mergeMap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-organization-selected',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './organization-selected.component.html',
  styleUrl: './organization-selected.component.scss'
})
export class OrganizationSelectedComponent implements OnDestroy, OnInit {
  subscription: Subscription;

  @Input()
  organizations: Organization[];

  @Input()
  policies: Policy[];

  @Input()
  licensedServices: String[];

  constructor(private organizationService: OrganizationService, private apollo: Apollo, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.pipe(
      map(params => params['organization_id'])
    ).subscribe(organizationId => {
      this.organizationService.setOrganization(this.organizations.find(org => org.id === organizationId));
      this.organizationService.setPolicies(this.policies);
      this.organizationService.setLicensedServices(this.licensedServices);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
