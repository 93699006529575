import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Organization} from '../../../types';

@Component({
  selector: 'app-basic-poitem-info',
  templateUrl: './basic-poitem-info.component.html',
  styleUrls: ['./basic-poitem-info.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => BasicPoitemInfoComponent),
  },
    {
      provide: NG_VALIDATORS,
      useExisting: BasicPoitemInfoComponent,
      multi: true
    }]
})
export class BasicPoitemInfoComponent implements OnInit, ControlValueAccessor {
  static DEFAULTS = {
    input_brefix: '',
    po_item_id: '',
    filename: '',
  };
  form: FormGroup;

  @Input()
  organization: Organization;

  private changed;
  private touched;

  constructor(private formBuilder: FormBuilder) {
  }


  writeValue(value) {
    this.form.patchValue(value, {emitEvent: false})
  }

  registerOnChange(fn) {
    this.changed = fn;
  }

  registerOnTouched(fn) {
    this.touched = fn;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      input_brefix: ['', [Validators.required]],
      po_item_id: ['', [Validators.required]],
      filename: ['', [Validators.required]],
    })
    this.form.valueChanges.subscribe((val) => {
      if (this.touched) {
        this.touched();
      }
      if (this.changed) {
        this.changed(val);
      }
    });
    // this.form.get('input_brefix')?.disable()
  }
  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }

}
