import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {Airline, Po, Query} from '../../types-workflow';
import {debounceTime, distinctUntilChanged, map, mergeMap, startWith, tap} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FILTER_DEBOUNCE_TIME} from '../filter-section/filter-section.component';
import {BreadCrumb} from '../logo-area/logo-area.component';
import {Organization} from '../../types';

const LIST_POS = gql`
query GetPOs($airline: String!) {
  list_POs(input: { filter: { airline: { eq: $airline}}}){
    pos {
      id
      airline
      po_name
      date_due
      date_created
      target_system
    }
  }
}`


class UIPo {
  airline: String;
  po_name: String;
  date_created: String;
  date_due: String;
  target_system: String;
}


@Component({
  selector: 'app-list-pos',
  templateUrl: './list-pos.component.html',
  styleUrls: ['./list-pos.component.css']
})
export class ListPosComponent implements OnInit {
  @Input()
  airline: Airline;
  @Input()
  airlines: Airline[];
  @Input()
  organization: Organization;

  pos$: Observable<UIPo[]>;
  allpos$: Observable<UIPo[]>;
  loading = true;
  breadCrumbs: BreadCrumb[];

  filters: FormGroup;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private fb: FormBuilder)  {
    this.filters = fb.group({
      t: new FormControl('')
    })
  }


  ngOnInit() {
    this.breadCrumbs = [
      {
        display: 'Content Portal',
        commands: ['/o', this.organization.id],
      },
      {
        display: 'Workflows',
        commands: ['/o', this.organization.id, 'workflows'],
      },
      {
        display: this.airline.airline_name,
        commands: ['/o', this.organization.id, 'workflows', this.airline.iata_code],
      }
    ];
    this.loading = true
    this.allpos$ = this.apollo.use('workflow').query<Query>({
      query: LIST_POS,
      errorPolicy: 'ignore',
      variables: {
        airline: this.airline.iata_code
      }
    }).pipe(map(d => {
        const pos: UIPo[] = []
        for (const po of d.data.list_POs.pos) {
          pos.push({
            airline: po.airline,
            po_name: po.po_name,
            date_due: po.date_due,
            date_created: po.date_created,
            target_system: po.target_system
          })
        }

        pos.sort((a: UIPo, b: UIPo) => {
          if (a.date_created < b.date_created) {
            return 1;
          } else if (a.date_created > b.date_created) {
            return -1;
          } else {
            return 0;
          }
        });
        return pos
      }),
      tap(airline => this.loading = false),
    )
    this.pos$ = combineLatest(
      [this.allpos$,
        this.filters.valueChanges.pipe(debounceTime(FILTER_DEBOUNCE_TIME), startWith([this.filters.value]))]
    ).pipe(
      map(([pos, filters]) => pos.filter(po => {
        return po.po_name.toLowerCase().includes((filters['t'] || '').toLowerCase()) ||
          po.target_system.toLowerCase().includes((filters['t'] || '').toLowerCase())
      })),
    )
  }

  public navigate(po: Po) {
    this.router.navigate(['/workflow', po.airline, po.po_name])
  }

}
