import {Component, Inject, Input, OnInit, TrackByFunction} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {map, mergeMap, skipWhile, take} from 'rxjs/operators';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BannerService} from '../../../banner/banner.service';
import {Apollo} from 'apollo-angular';
import {Mutation, ProcessStateEnum, Query as WorkflowQuery, Query} from '../../../types-workflow';

import {GET_PROCESS, START_WORKFLOW_VOD_BATCH} from '../../../queries';
import {forkJoin} from 'rxjs';
import {Router} from '@angular/router';
import {Po2024Service} from '../../po-2024.service';
import {BasicPoitemVodInfoComponent} from '../basics-poitem-vod-info/basic-poitem-vod-info.component';
import {VodWatermarkFormatSpecificComponent} from '../vod-watermark-format-specific/vod-watermark-format-specific.component';
import {clean_datetime, shortenJanusOrg} from '../../utils';
import {Organization} from '../../../types';


@Component({
  selector: 'app-vod-watermark-po',
  templateUrl: './vod-watermark-po.component.html',
  styleUrls: ['./vod-watermark-po.component.css'],
})
export class VodWatermarkPoComponent implements OnInit {

  @Input()
  organization: Organization;

  @Input()
  airlines = []

  poFormGroup: FormGroup;

  poItemTrackBy: TrackByFunction<FormGroup> = (index: number, poitem: FormGroup) => {
    return poitem.get('id').value;
  }

  constructor(
    private apollo: Apollo,
    public snackBar: MatSnackBar,
    @Inject('vod_batch') protected poService: Po2024Service,
    formBuilder: FormBuilder,
    private banner: BannerService,
    private router: Router
  ) {
    this.poFormGroup = formBuilder.group({
      po_number: [clean_datetime(new Date()), Validators.required],
      airline: [null, Validators.required],
      auto_publish: [true, Validators.required],
      poItems: formBuilder.array([], Validators.required),
      date_created: [null],
    });


    poService.poItemToAdd$.subscribe(data => {
      console.log('poItemToAdd$')
      if (data) {
        (this.poFormGroup.controls.poItems as FormArray).push(formBuilder.group(
          Object.assign(
            {},
            {
              basics: BasicPoitemVodInfoComponent.DEFAULTS,
              format_specific_data: VodWatermarkFormatSpecificComponent.DEFAULTS
            },
            data)))
      }
    });
    poService.poItemToRemove$.subscribe(id => {
      console.log('poItemToRemove$')
      if (id) {
        const index = (this.poFormGroup.controls.poItems as FormArray).controls.findIndex(poItem => poItem.get('id').value === id);
        (this.poFormGroup.controls.poItems as FormArray).removeAt(index);
      }
    });
  }

  ngOnInit() {
    if (this.airlines.length === 1) {
      this.poFormGroup.controls['airline'].setValue(this.airlines[0].iata_code);
    }
  }


  submit_po() {

    if (!this.poFormGroup.valid) {
      console.log(this.poFormGroup)
      alert('Invalid')
      this.poFormGroup.markAllAsTouched();
      return
    }
    const workflows = []
    for (const poItem of this.poFormGroup.value.poItems) {
      workflows.push(this.apollo.use('workflow').mutate<Mutation>({
        mutation: START_WORKFLOW_VOD_BATCH,
        variables: {
          input_brefix: poItem.basics.input_brefix,
          po_item_id: poItem.basics.po_item_id.trim(),
          po_name: `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number.trim()}`,
          po_destination: 'vod',
          format_specific_data: JSON.stringify(poItem.format_specific_data),
          vtk_template: 'cmaf-abr',
          auto_publish: this.poFormGroup.value.auto_publish,
          email_notification: this.poFormGroup.value.email_notification,
        }
      }).pipe(
        mergeMap(d => {
          const processId = d.data.start_workflow_vod_batch.id
          return this.apollo.use('workflow').watchQuery<Query>(
            {
              query: GET_PROCESS,
              pollInterval: 2000,
              variables: {
                id: processId
              }
            }
          ).valueChanges
        }),
        skipWhile(d => d.data.process.state === ProcessStateEnum.InProgress),
        take(1)
      ))
    }

    const sb_ref = this.snackBar.open('Workflows started.', `go to ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number}`,
      {
        duration: 5000
      })

    sb_ref.onAction().subscribe(() => {
      this.router.navigate(
        ['/o', this.organization.id, 'workflows',
          this.poFormGroup.value.airline,
          `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`])
    })
    forkJoin(workflows).subscribe(
      r => {
        sb_ref.dismiss();
        this.snackBar.open(`All workflows in ${shortenJanusOrg(shortenJanusOrg(this.poFormGroup.value.airline))}_${this.poFormGroup.value.po_number} have completed.`,
          `go to ${shortenJanusOrg(shortenJanusOrg(this.poFormGroup.value.airline))}_${this.poFormGroup.value.po_number}`,
          {
            duration: 5000
          }).onAction().subscribe(() => this.router.navigate(
          ['/workflows',
            this.poFormGroup.value.airline,
            `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`]))
      },
      error => {
        this.banner.open('Workflow submission failed: ' + error, ['Close'])
      }
    )

  }

  get poItems() {
    return this.poFormGroup.get('poItems') as FormArray<FormGroup>;
  }

  get fields() {
    return this.poFormGroup.controls;
  }

}
