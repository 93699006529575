<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Enable MFA</div>

    <div class="modal-body cl-form">
      <p class="cl-p-large"> MFA is required. Please set up a TOTP generator.</p>
    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none"></span>
      <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
         <a (click)="dialogRef.close()" [routerLink]="['/userprofile']"
            class="btn cl-btn-outline-primary it-ok"><span>Setup MFA</span><i class="fas fa-angle-right"></i>
                    </a></span>
    </div>
  </div>
</div>
