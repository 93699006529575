import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {GET_AIRLINES} from './queries';
import {Observable} from 'rxjs';
import {Airline, Query} from './types-workflow';
import {map} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';

export const airlineResolver: ResolveFn<Observable<Airline>> = (route, state) => {
  const apollo = inject(Apollo);
  const query = GET_AIRLINES(route.params['organization_id']);
  console.log(query)
  return apollo.use('workflow').query<Query>({
    query: query,
    context: {
      headers: new HttpHeaders({
        'X-Castlabs-Organization': route.params['organization_id'],
      })
    }
  }).pipe(
    map(res => res.data.airlines.airlines),
    map(airlines => airlines.find(airline => airline.iata_code === route.params['airline'])))
};



