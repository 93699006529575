import {Pipe, PipeTransform} from '@angular/core';
import {OrganizationService} from '../services/organization.service';

@Pipe({name: 'isAllowed'})
export class IsAllowedPipe implements PipeTransform {


  constructor(private organizationService: OrganizationService) {
  }

  transform(action: string, resource: string, debug: boolean = null): boolean {
    return this.organizationService.isAllowed(action, resource, debug)
  }
}

@Pipe({name: 'isAllowedAnywhere'})
export class IsAllowedAnywherePipe implements PipeTransform {


  constructor(private organizationService: OrganizationService) {
  }

  transform(action: string): boolean {
    return this.organizationService.isAllowedAnywhere(action)
  }
}

