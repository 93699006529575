<div class="container nesting">
  <div class="row" *ngFor="let topLevel of metadata$ | async | keyvalue">
    <div class="col-12" *ngIf="topLevel.key == 'dataFeedElement'; else plain">
      <h2>datafeeds</h2>
      <div class="container nesting"
           *ngFor="let dataFeedElement of topLevel.value; let  i = index ">
        <div class="row">
          <div class="col-12">

            <h3>datafeed[{{ i }}]</h3>
            <div class="container nesting" *ngFor="let dataFeed of dataFeedElement | keyvalue">
              <div class="row">
                <div class="col-12">
                  <div class="cl-spacing-box" [ngSwitch]="dataFeed.key">

                    <div class="col-12" *ngSwitchCase="'potentialAction'">
                      <h4>Potential Action</h4>
                      <pre> {{ dataFeed.value | json }} </pre>
                    </div>
                    <div class="col-12" *ngSwitchCase="'releasedEvent'">
                      <h4>Released Event</h4>
                      <pre> {{ dataFeed.value | json }} </pre>
                    </div>
                    <div class="col-12" *ngSwitchCase="'trailer'">
                      <h4>Trailer</h4>
                      <pre> {{ dataFeed.value | json }} </pre>
                    </div>
                    <div class="col-12" *ngSwitchCase="'actor'">
                      <h4>Actors</h4>
                      <table class="cl-table-data">
                        <thead>
                        <th style="width: 25%">Type</th>
                        <th>Name</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let actor of dataFeed.value">
                          <td>{{ actor['@type'] }}</td>
                          <td>{{ actor['name'] }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12" *ngSwitchCase="'description'">
                      <h4>Description</h4>
                      <table class="cl-table-data">
                        <thead>
                        <th style="width: 25%">Language</th>
                        <th>Description</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let localizedText of dataFeed.value">
                          <td>{{ localizedText['@language'] }}</td>
                          <td><p>{{ localizedText['@value'] }}</p></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12" *ngSwitchCase="'name'">
                      <h4>Name</h4>
                      <table class="cl-table-data">
                        <thead>
                        <th style="width: 25%">Language</th>
                        <th>Name</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let localizedText of dataFeed.value">
                          <td>{{ localizedText['@language'] }}</td>
                          <td><p>{{ localizedText['@value'] }}</p></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12" *ngSwitchCase="'director'">
                      <h4>Director</h4>
                      <table class="cl-table-data">
                        <thead>
                        <th style="width: 25%">Type</th>
                        <th>Name</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let actor of dataFeed.value">
                          <td>{{ actor['@type'] }}</td>
                          <td>{{ actor['name'] }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12" *ngSwitchCase="'identifier'">
                      <h4>Identifiers</h4>
                      <table class="cl-table-data">
                        <thead>
                        <th style="width: 25%">Property ID</th>
                        <th>Value</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let identifier of dataFeed.value">
                          <td>{{ identifier['propertyID'] }}</td>
                          <td>{{ identifier['value'] }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12" *ngSwitchCase="'genre'">
                      <h4>Genres</h4>
                      <div class="cl-spacing-box-tiny">
                                <span class="badge cl-badge-text"
                                      *ngFor="let genre of dataFeed.value">{{ genre }}</span>
                      </div>
                    </div>
                    <div class="col-12" *ngSwitchCase="'contentRating'">
                      <h4>Content Rating</h4>
                      <p class="cl-p-large">{{ dataFeed.value }}</p>
                    </div>
                    <div class="col-12" *ngSwitchCase="'duration'">
                      <h4>Duration</h4>
                      <p class="cl-p-large">{{ dataFeed.value }}</p>
                    </div>
                    <ng-container *ngSwitchCase="'image'">
                      <div class="col-12" *ngFor="let image of dataFeed.value; let i = index">
                        <h4>Image[{{ i }}]</h4>
                        <div class="container nesting" *ngFor="let pro of image | keyvalue">
                          <div class="col-12">
                            <dl class="cl-dl cl-dl-tight">
                              <dt>{{ pro.key }}</dt>
                              <ng-container [ngSwitch]="pro.key">
                                <dd *ngSwitchCase="'additionalProperty'">
                                  <table class="cl-table-data">
                                    <thead>
                                    <th style="width: 25%">additionalProperty</th>
                                    <th>Values</th>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let additionalProperty of image.additionalProperty">
                                      <td>{{ additionalProperty['name'] }}</td>
                                      <td>
                                        <div class="cl-spacing-box-tiny">
                          <span class="badge cl-badge-text"
                                *ngFor="let value of additionalProperty['value'] ">{{ value }}</span>
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </dd>
                                <dd *ngSwitchCase="'contentUrl'">
                                  <img [src]="getImgUrl(pro.value)">
                                </dd>
                                <dd *ngSwitchDefault>
                                  <p class="cl-p">{{ pro.value }}</p>
                                </dd>
                              </ng-container>
                            </dl>
                          </div>
                        </div>


                      </div>
                    </ng-container>
                    <div class="col-12" *ngSwitchDefault>
                      <h4>{{ dataFeed.key }}</h4>
                      <div class="col-12">
                        <pre> {{ dataFeed.value }}</pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="col-12"></div>
    </div>
    <ng-template #plain>
      <div class="col-2">
        <pre>{{ topLevel.key }}</pre>
      </div>
      <div class="col-10">
        <pre> {{ topLevel.value }}</pre>
      </div>
    </ng-template>
  </div>
</div>
