<app-logo-area [breadcrumbs]="breadcrumps$|async"></app-logo-area>
<main>
  <app-headline-section
    [title]="'Previews'"></app-headline-section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8">
          <p class="cl-p-large">Use this player to verify video previews, ensuring subtitles match the dialogue, lip
            movements are in sync, and the picture quality meets your standards</p>
        </div>
      </div>
    </div>
  </section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <app-cl-tabs>
            <app-cl-tab tab_title="Video">
              <h2>
                {{ po_item_id$ | async }} <small>{{ filename$ | async }}</small>
              </h2>
              <app-player *ngIf="preview$ | async as preview" [m3u8]="preview.hls_manifest_url"
                          [manifest]="preview.dash_manifest_url" [drmtodayToken]="preview.drmtoday_token"></app-player>
            </app-cl-tab>
            <app-cl-tab tab_title="Flytedge" *ngIf="flytedgeMetadataUrl$ | async">
              <app-flytedge-metadata-preview [url]="flytedgeMetadataUrl$ | async"/>
            </app-cl-tab>
            <app-cl-tab tab_title="Flytedge Raw" *ngIf="flytedgeMetadataUrl$ | async">
              <app-flytedge-metadata-raw [url]="flytedgeMetadataUrl$ | async"/>
            </app-cl-tab>
          </app-cl-tabs>
        </div>
      </div>
    </div>
  </section>



</main>
