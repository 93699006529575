<app-filter-section [all]="airlines" [filtered]="airlines$ | async" [formGroup]="filters">
  <form [formGroup]="filters" class="cl-form">
    <input type="text" style="width: 100%" formControlName="t"
           placeholder="Filter by ID and name">
    <p class="valid-feedback">Filter terms are case-insensitive.</p>
  </form>
</app-filter-section>
<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <table class="cl-table-data">
          <colgroup>
            <col span="1" style="width: 20%">
            <col span="1" style="width: 80%">
          </colgroup>
          <thead>
          <tr>
            <th class="cl-table-head cl-p-tiny ">
              <div>
                ID
              </div>
            </th>
            <th class="cl-table-head cl-p-tiny ">
              <div>
                Name
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let it of (airlines$ | async)">
            <td>
              <a [routerLink]="[it.iata_code]">{{it.iata_code | orgShort}}</a>
            </td>
            <td>
              <a  [routerLink]="[it.iata_code]">{{it.airline_name  | orgShort }}</a>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</section>
