import gql from 'graphql-tag';
import {Organization} from './types';

export const FILE_FOLDER_LIST = gql`
query file_folder_list($id: ID!, $show_deleted: Boolean) {
  folder(id: $id) {
    id
    name
    folders {
      id
      name
    }
    files(show_deleted: $show_deleted) {
      id
      name
      size
      last_modified
      deleted
    }
    parents {
      id
      name
    }
  }
}`

export const GET_PARENTS = gql`
query get_parents($id: ID!) {
  folder(id: $id) {
    id
    name
    parents {
      id
      name
    }
  }
}`

export const GET_AIRLINES = (org_id: string) =>  gql`
query GetAirlines${org_id.replace(new RegExp(':', 'g'), '_')} {
  airlines {
    airlines {
      airline_name
      iata_code
      airline_possessive
      prod_organization
      preview_organization
    }
  }
}
`

export const FILE_FOLDER_LIST_WITH_ARCHIVE = gql`
query filefolderlistwitharchive($id: ID!, $show_deleted: Boolean) {
  folder(id: $id){
    id
    name
    folders {
      id
      name
    }
    files(show_deleted: $show_deleted) {
      id
      name
      size
      last_modified
      deleted
      archived
      archive {
        restore_state
        expiration
        extra {
          restore_tier
          restore_eta
        }
      }
      tracks {
        index
        enabled
        language
        track_type
      }
    }
    parents {
      id
      name
    }
  }
}`

export const FILE_ARCHIVE_STATE = gql`
      query file_archive_state($id: ID!) {
        file(id: $id) {
          id
          archived
          archive {
            restore_state
            expiration
            extra {
              restore_tier
              restore_eta
            }
          }
        }
      }
    `

export const START_WORKFLOW_ABOVE_DEFAULT = gql`mutation start_workflow_above_default($aspect_ratio: String, $email_notification: [AWSEmail!], $filename: String!, $input_brefix: String!, $po_item_id: String!, $po_name: String!, $tracks: [TrackInput!]!, $vtk_template: String!, $watermark: Boolean = false, $po_destination: String, $format_specific_data: AWSJSON, $auto_publish: Boolean) {
  start_workflow_above_default(input: {aspect_ratio: $aspect_ratio, filename: $filename, po_item_id: $po_item_id, email_notification: $email_notification, po_name: $po_name, vtk_template: $vtk_template, watermark: $watermark, input_brefix: $input_brefix, tracks: $tracks, po_destination: $po_destination, format_specific_data: $format_specific_data, auto_publish: $auto_publish}) {
    state
    message
    input
    data
    action
    id
    start_date
    end_date
  }
}
`

export const START_WORKFLOW_DEFAULT = gql`mutation start_workflow_default($aspect_ratio: String, $email_notification: [AWSEmail!], $filename: String!, $content_identifier: String!, $po_item_id: String!, $po_name: String!, $tracks: [TrackInput!]!, $vtk_template: String!, $watermark: Boolean = false, $po_destination: String, $metadata_source: String, $wrap_up_workflow: String, $format_specific_data: AWSJSON, $auto_publish: Boolean) {
  start_workflow_default(input: {aspect_ratio: $aspect_ratio, filename: $filename, po_item_id: $po_item_id, email_notification: $email_notification, po_name: $po_name, vtk_template: $vtk_template, watermark: $watermark, content_identifier: $content_identifier, tracks: $tracks, po_destination: $po_destination, format_specific_data: $format_specific_data, auto_publish: $auto_publish, metadata_source: $metadata_source, wrap_up_workflow: $wrap_up_workflow}) {
    state
    message
    input
    data
    action
    id
    start_date
    end_date
  }
}
`


export const START_WORKFLOW_VOD_BATCH = gql`mutation start_workflow_vod_batch( $po_item_id: String!, $po_name: String!, $input_brefix: String!, $po_destination: String!, $auto_publish: Boolean!, $vtk_template: String!, $format_specific_data: AWSJSON!,  $email_notification: [AWSEmail!]) {
  start_workflow_vod_batch(input: {  po_name: $po_name, po_item_id: $po_item_id, input_brefix: $input_brefix, po_destination: $po_destination, auto_publish: $auto_publish, email_notification: $email_notification, vtk_template: $vtk_template, format_specific_data: $format_specific_data}) {
    state
    message
    input
    data
    action
    id
    start_date
    end_date
  }
}
`

export const START_WORKFLOW_VOD_DEFAULT = gql`mutation start_workflow_vod_default   ( $po_item_id: String!, $po_name: String!, $input_brefix: String!, $po_destination: String!, $auto_publish: Boolean!, $vtk_template: String!, $format_specific_data: AWSJSON!, $email_notification: [AWSEmail!]) {
  start_workflow_vod_default(input: {  po_name: $po_name, po_item_id: $po_item_id, input_brefix: $input_brefix, po_destination: $po_destination, auto_publish: $auto_publish, email_notification: $email_notification, vtk_template: $vtk_template, format_specific_data: $format_specific_data}) {
    state
    message
    input
    data
    action
    id
    start_date
    end_date
  }
}
`


export const START_WORKFLOW_AERQ_DEFAULT = gql`mutation start_workflow_aerq_default( $po_name: String!, $po_item_id: String!, $input_brefix: String!, $aerq_airline: String!, $po_destination: String,  $filename: String!, $aerq_version: String!, $vtk_template: String!, $tracks: [TrackInput!]!, $format_specific_data: AWSJSON! ) {
  start_workflow_aerq_default(input: { po_name: $po_name, po_item_id: $po_item_id, input_brefix: $input_brefix, aerq_airline: $aerq_airline, po_destination: $po_destination, filename: $filename, aerq_version: $aerq_version, vtk_template: $vtk_template,tracks: $tracks, format_specific_data: $format_specific_data }) {
    state
    message
    input
    data
    action
    id
  }
}
`

export const CREATE_UPLOAD_TICKET = gql`mutation create_upload_ticket($folder_id: ID!, $message: String!) {
  createUploadTicket(input: {folder_id: $folder_id, message: $message}) {
      directory
      token
      url
  }
}
`

export const RESTORE = gql`mutation restore_file($files: [ID]!, $tier: RestoreTier!, $days: Int = 14) {
  restore(input: {files: $files, tier: $tier, days: $days}) {
    action
    data
    message
    state
    id
  }
}
`
export const PREVIEW_PREPARE = gql`mutation preview_preparation($folder_id: ID!) {
  previewPrepare(input: {folder_id: $folder_id}) {
    action
    data
    message
    state
    id
  }
}
`
export const PREVIEW_GENERATE = gql`mutation preview_generation($folder_id: ID!) {
  previewGenerate(input: {folder_id: $folder_id}) {
    action
    data
    message
    state
    id
  }
}
`

export const START_GENERATE_STILLS = gql`mutation generate_stills($file_id: ID!) {
  generate_stills(input: {file_id: $file_id}) {
    action
    data
    message
    state
    id
  }
}
`

export const GET_PROCESS = gql`query GetProcess($id: ID!) {
  process(id: $id) {
    action
    data
    id
    message
    state
    start_date
    end_date
  }
}
`

export const GET_UPFRONT_TOKEN = gql`mutation GetUpfrontToken($asset_id: String) {
  previewGenerateToken(input: { asset_id: $asset_id } ) {
    access_token
    drmtoday_token
    asset_id
    dash_url
    hls_url
  }
}
`

export const GET_FOLDER2 = gql`query GetFolder2($id: ID!) {
        folder(id: $id) {
          id
          name
          files {
            id
            tracks {
              track_type
              enabled
              index
              language
            }
          }
          tags {
            key
            value
          }
        }
      }`
export const GET_FOLDER_ALL_DETAILS = gql`query GetFolderAllDetails($id: ID!) {
        folder(id: $id) {
          id
          name
          files {
            id
            name
            archived
            deleted
            last_modified
            tags {
              key
              value
            }

            tracks {
              index
              enabled
              language
              track_type
            }
            stills {
              url
              name
            }
            ffprobe
            warnings
            extra_info
            mediainfo
            mediainfo_json
          }
          tags {
            key
            value
          }
          parents {
            id
            name
          }
        }
      }`
export const FILE_DETAILS = gql`query GetFile($id: ID!) {
          file(id: $id) {
            id
            name
            archived
            deleted
            last_modified
            tags {
              key
              value
            }

            tracks {
              index
              enabled
              language
              track_type
            }
            stills {
              url
              name
            }
            ffprobe
            warnings
            extra_info
            mediainfo
            mediainfo_json
          }
        }`

export const RESTART_WORKFLOW = gql`mutation RestartWorkflow($po_name: String!, $po_item_id: String!) {
  restartPoItem(input: {po_name:$po_name, po_item_id:$po_item_id}) {
    id
    state
    message
    start_date
    end_date
  }
}`

export const PO_ITEM_LIST_FULL = gql`query PoItemListFull($po_name: String!) {
  list_POs(input: { filter: { po_name: { eq: $po_name } } } ){
    __typename
    pos {
      id
      __typename
      poitems {
        __typename
        input_brefix
        filename
        id
        po_item_id
        po_destination
        output_brefix
        po {
          po_name
        }
        publish_process {
          id
          state
          data
          message
          start_date
          end_date
        }
        workflow_process {
          id
          state
          data
          message
          start_date
          end_date
        }
        customer
        vtk_template
        watermark
        workflow
        aspect_ratio
        format_specific_data
        format_specific_data_json  @client
        preview {
          dash_manifest_last_modified
          dash_manifest_url
          hls_manifest_url
          hls_manifest_last_modified
        }
        tracks {
          codec_type
          messages
          source {
            codec_type
            index
            key
            lang
          }
          lang
        }
        checkpoint_content_uploaded
        checkpoint_content_complete
        checkpoint_encodes_done
        checkpoint_metadata_available
        vtk_jobs {
          created
          organization_id
          id
          job_bundle
          modified
          status_text
          tags
        }
      }
    }
  }
}`

export const PO_ITEM_SINGLE_FULL = gql`
query PoItemSingleFull($po_name: String!, $po_item_id: String) {
  list_POs(input: { filter: { po_name: { eq: $po_name } } } ){
    pos {
      id
      poitems(po_item_id: $po_item_id) {
        input_brefix
        filename
        id
        po_item_id
        po_destination
        output_brefix
        po {
          po_name
        }
        publish_process {
          id
          state
          data
          message
          start_date
          end_date
        }
        workflow_process {
          id
          state
          data
          message
          start_date
          end_date
        }
        customer
        vtk_template
        watermark
        workflow
        aspect_ratio
        format_specific_data
        format_specific_data_json  @client
        preview {
          dash_manifest_last_modified
          dash_manifest_url
          hls_manifest_url
          hls_manifest_last_modified
        }
        tracks {
          codec_type
          messages
          source {
            codec_type
            index
            key
            lang
          }
          lang
        }
        checkpoint_content_uploaded
        checkpoint_content_complete
        checkpoint_encodes_done
        checkpoint_metadata_available
        vtk_jobs {
          created
          organization_id
          id
          job_bundle
          modified
          status_text
          tags
        }
      }
    }
  }
}
`

export const GET_POLICIES = (org_id: string) => gql`query GetPolicies${org_id.replace(new RegExp(':', 'g'), '_')} {
  policies {
    organization {
      id
      name
      legacy_name
    }
    licensedServices
    policies {
      display_name
      statements {
        actions
        effect
        resources
        conditions {
          key
          operator
          values
        }
      }
    }
  }
}`

export const GET_USER_PROFILE = gql`query GetUserProfile {
  userProfile {
    staff
    name
    email
  }
}`

export const GET_ORGANIZATIONS = gql`query GetOrganizations {
  organizations {
    organizations {
      id
      name
      legacy_name
    }
  }
}`

export const GET_ROOTS = (org_id: string) => gql`query GetRoots${org_id.replace(new RegExp(':', 'g'), '_')} {
  roots {
    id
    name
  }
}`
