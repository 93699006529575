<form class="row cl-spacing-small" [formGroup]="form">
  <div class="col-4">
    <label for="watermark" class="form-label">Watermark</label>
    <select id="watermark" class="form-select" formControlName="watermark">
      <option [disabled]="environment.env_name === 'prod'" value="stardust/sf">castLabs Single Frame Watermark</option>
      <option value="contentarmor/embed">Content Armor (embed)</option>
    </select>
  </div>
  <div class="col-4">
    <label for="encrypt" class="form-label">Encryption</label>
    <select id="encrypt" class="form-select" formControlName="encrypt">
      <option [disabled]="environment.env_name === 'prod'" [ngValue]="false">Plain</option>
      <option [ngValue]="true">Encrypt</option>
    </select>
  </div>
  <div class="col-4">
    <label for="thumbs" class="form-label">Thumbnails</label>
    <select id="thumbs" class="form-select" formControlName="thumbnail">
      <option [ngValue]="false">No Thumbnails</option>
      <option [disabled]="environment.env_name === 'prod'" [ngValue]="true">Generate Thumbnails</option>
    </select>
  </div>
  <div class="col-6" [style.display]="form.get('watermark').value === 'contentarmor/embed'?'block':'none'">
    <label for="ca_payload_size" class="form-label">Number of bits embedded</label>
    <select id="ca_payload_size" class="form-select" formControlName="ca_payload_size">
      <option *ngFor="let s of ca_payload_sizes" [ngValue]="s.id">{{ s.name }}</option>
    </select>
  </div>
  <div class="col-3" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
    <label for="sd_payload_size" class="form-label">Number of bits embedded</label>
    <select id="sd_payload_size" class="form-select" formControlName="sd_payload_size">
      <option *ngFor="let s of sd_payload_sizes" [ngValue]="s.id">{{ s.name }}</option>
    </select>
  </div>
  <div class="col-3" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
    <label for="sd_strength" class="form-label">Watermark Strength</label>
    <select id="sd_strength" class="form-select" formControlName="sd_strength">
      <option *ngFor="let s of strengths" [ngValue]="s.id">{{ s.name }}</option>
    </select>
  </div>
  <div class="col-3" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
    <label for="sd_superpixel_density" class="form-label">Superpixel Density</label>
    <select id="sd_superpixel_density" class="form-select" formControlName="sd_superpixel_density">
      <option *ngFor="let d of densities" [ngValue]="d.id">{{ d.name }}</option>
    </select>
  </div>

  <div class="col-3" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
    <label for="sd_pixel_density" class="form-label">Pixel Density</label>
    <select id="sd_pixel_density" class="form-select" formControlName="sd_pixel_density">
      <option *ngFor="let d of densities" [ngValue]="d.id">{{ d.name }}</option>
    </select>
  </div>
  <div class="col-12" [style.display]="form.get('watermark').value === 'contentarmor/embed'?'block':'none'">
    <label for="ca_watermarks" class="form-label">Watermarks:</label>
    <textarea id="ca_watermarks" type="text" formControlName="ca_watermarks" commaSeparated>
      </textarea>
    <p *ngIf="form.get('ca_watermarks').errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="form.get('ca_watermarks').errors?.pattern" class="invalid-feedback">This field must contain comma separated decimal numbers.</p>
    <p *ngIf="form.get('ca_watermarks').errors?.range" class="invalid-feedback">Each number in the array needs to be inside the range of
      1 to {{ form.get('ca_watermarks').errors.range.max }}. {{  form.get('ca_watermarks').errors.range.actual }} is outside that range </p>  </div>
  <div class="col-12" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
    <label for="sd_watermarks" class="form-label">Watermarks:</label>
    <textarea id="sd_watermarks" type="text" formControlName="sd_watermarks" commaSeparated>
      </textarea>
    <p *ngIf="form.get('sd_watermarks').errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="form.get('sd_watermarks').errors?.pattern" class="invalid-feedback">This field must contain comma separated decimal numbers.</p>
    <p *ngIf="form.get('sd_watermarks').errors?.range" class="invalid-feedback">Each number in the array needs to be inside the range of
      1 to {{ form.get('sd_watermarks').errors.range.max }}. {{  form.get('sd_watermarks').errors.range.actual }} is outside that range </p>
  </div>
</form>
