import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {authConfigAbove, authConfigAerq, authConfigCastlabs, authConfigPortal, authConfigTouch} from './authConfig';
import {LoginOptions, OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {PortalIdpService} from './services/portal-idp.service';
import {cleanLocalStorage} from './components/utils';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit {

  constructor(public oAuthService: OAuthService,
              private router: Router,
              private portalIdp: PortalIdpService) {
    if (window.location.hostname.startsWith('above')) {
      oAuthService.configure(authConfigAbove)
      this.portalIdp.setIdpName('above')
    } else if (window.location.hostname.startsWith('touch')) {
      oAuthService.configure(authConfigTouch)
      this.portalIdp.setIdpName('touch')
    } else if (window.location.hostname.startsWith('aerq')) {
      oAuthService.configure(authConfigAerq)
      this.portalIdp.setIdpName('aerq')
    } else if (window.location.hostname.startsWith('platform')) {
      oAuthService.configure(authConfigCastlabs)
      this.portalIdp.setIdpName('castlabs')
    } else {
      oAuthService.configure(authConfigPortal)
      this.portalIdp.setIdpName('portal')
    }

    oAuthService.loadDiscoveryDocument()
      .then((doc) => {

        const loginOptions: LoginOptions = {
          preventClearHashAfterLogin: true,
        }
        return oAuthService.tryLogin(loginOptions) // implicit flow
      }).catch(reason => {
      console.error(reason);
    })
      .then((result) => {
        console.log('tryLogin result', result)
        const state = new URLSearchParams(window.location.search).get('state')
        if (state) {
          this.router.navigateByUrl(decodeURIComponent(state.split(';')[1]))
        } else {
          router.initialNavigation()
        }
      })
      .catch(reason => {
        console.error(reason)
        cleanLocalStorage()
      })
    oAuthService.setupAutomaticSilentRefresh();
  }

  ngOnInit(): void {
  }
}
