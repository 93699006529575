<div class="row cl-spacing-small" [formGroup]="form">
  <div class="col-4 cl-spacing-tiny">
    <label for="audio" class="form-label">Audio Languages</label>
    <div>
      <app-language-select id="audio" formControlName="audio" style="height: 100%;"></app-language-select>
    </div>
  </div>
  <div class="col-4 cl-spacing-tiny">
    <label for="subtitles" class="form-label">Subtitle Languages</label>
    <div>
      <app-language-select id="subtitles" formControlName="subtitles" style="height: 100%;"></app-language-select>
    </div>
  </div>
  <div class="col-4 cl-spacing-tiny">
    <label for="cc" class="form-label">Closed Captions Languages</label>
    <div>
      <app-language-select id="cc" formControlName="cc" style=" height: 100%;"></app-language-select>
    </div>
  </div>
</div>
