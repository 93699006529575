<ng-container [formGroup]="form">

  <div class="row cl-spacing-small">
    <div class="col-4">

      <label for="watermark" class="form-label">Watermark</label>
      <select id="watermark" class="form-select" formControlName="watermark">
        <option [ngValue]="null">No Watermark</option>
        <option value="stardust/sf">castLabs Single Frame Watermark</option>
        <option value="contentarmor/ab">Content Armor (A/B)</option>
      </select>
    </div>
    <div class="col-4">
      <label for="encrypt" class="form-label">Encryption</label>
      <select id="encrypt" class="form-select" formControlName="encrypt">
        <option [ngValue]="false">Plain</option>
        <option [ngValue]="true">Encrypt</option>
      </select>
    </div>
    <div class="col-4">
      <label for="thumbs" class="form-label">Thumbnails</label>
      <select id="thumbs" class="form-select" formControlName="thumbnail">
        <option [ngValue]="false">No Thumbnails</option>
        <option [ngValue]="true">Generate Thumbnails</option>
      </select>
    </div>
  </div><div class="row cl-spacing-small">
    <div class="col-6" [style.display]="form.get('watermark').value === 'contentarmor/ab'?'block':'none'">
      <label for="ab_payload_size" class="form-label">Number of bits embedded</label>
      <select id="ab_payload_size" class="form-select" formControlName="ab_payload_size">
        <option *ngFor="let s of ca_payload_sizes" [ngValue]="s.id">{{ s.name }}</option>
      </select>
    </div>
    <div class="col-6" [style.display]="form.get('watermark').value === 'contentarmor/ab'?'block':'none'">
      <label for="ab_shared_session_key" class="form-label">Shared Session Key</label>
      <input id="ab_shared_session_key" type="text" formControlName="ab_shared_session_key">
    </div>

    <div class="col-3" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
      <label for="sd_strength" class="form-label">Watermark Strength</label>
      <select id="sd_strength" class="form-select" formControlName="sd_strength">
        <option *ngFor="let s of strengths" [ngValue]="s.id">{{ s.name }}</option>
      </select>
    </div>
    <div class="col-2" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
      <label for="sd_superpixel_density" class="form-label">Superpixel Density</label>
      <select id="sd_superpixel_density" class="form-select" formControlName="sd_superpixel_density">
        <option *ngFor="let d of densities" [ngValue]="d.id">{{ d.name }}</option>
      </select>
    </div>

    <div class="col-2" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
      <label for="sd_pixel_density" class="form-label">Pixel Density</label>
      <select id="sd_pixel_density" class="form-select" formControlName="sd_pixel_density">
        <option *ngFor="let d of densities" [ngValue]="d.id">{{ d.name }}</option>
      </select>
    </div>

    <div class="col-3" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
      <label for="sd_payload_size" class="form-label">Session Bits</label>
      <select id="sd_payload_size" class="form-select" formControlName="sd_payload_size">
        <option *ngFor="let b of sd_payload_sizes" [ngValue]="b.id"> {{ b.name }}</option>
      </select>
    </div>

    <div class="col-2" [style.display]="form.get('watermark').value === 'stardust/sf'?'block':'none'">
      <label for="sd_wm_id" class="form-label">Watermark ID</label>
      <input id="sd_wm_id" type="number" formControlName="sd_wm_id">
      <p *ngIf="form.get('sd_wm_id').errors?.max" class="invalid-feedback">The maximum value
        is {{ form.get('sd_wm_id').errors.max.max }} but current value is  {{ form.get('sd_wm_id').errors.max.actual }}</p>
      <p *ngIf="form.get('sd_wm_id').errors?.min" class="invalid-feedback">Negative values are not permitted</p>
    </div>
  </div>
</ng-container>
