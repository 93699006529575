import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {CognitoIdentityProviderClient, GetUserCommand} from '@aws-sdk/client-cognito-identity-provider';
import {environment} from '../environments/environment';
import {RemindMFAComponent} from './components/remind-mfa/remind-mfa.component';
import {PortalIdpService} from './services/portal-idp.service';
import {Dialog} from '@angular/cdk/dialog';

@Injectable()
export class MfaGuard implements CanActivate {

  has_mfa = false;

  constructor(private router: Router, private oAuthService: OAuthService, private dialog: Dialog, private portalIdp: PortalIdpService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.portalIdp.getIdpName() === 'portal') {
      if (!this.oAuthService.hasValidAccessToken()) {
        return false;
      }
      if (this.has_mfa) {
        return true;
      }
      const client = new CognitoIdentityProviderClient({region: environment.region});
      const command = new GetUserCommand({
        AccessToken: this.oAuthService.getAccessToken()
      });
      return client.send(command).then(value => {
        if (!value.Username.toLocaleLowerCase().startsWith('castlabs_portal_demo') &&
          (value.UserMFASettingList === undefined ||
            value.UserMFASettingList.find((v) => v === 'SOFTWARE_TOKEN_MFA') !== 'SOFTWARE_TOKEN_MFA')) {
          this.dialog.open(RemindMFAComponent)
          return this.router.createUrlTree(['userprofile']);
        } else {
          this.has_mfa = true;
          return true
        }
      }).catch(reason => {
        console.error(reason);
        if (reason.name === 'NotAuthorizedException') {
          return this.router.createUrlTree(['login'], {
            queryParams: {
              returnUrl: state.url.replace(':', '%3A')
            }
          })
        }
      })
    } else {
      return true;
    }
  }
}
