import {ElementRef, Inject, Injectable} from '@angular/core';
import {combineLatest, concat, defer, fromEvent, Observable, of} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  private pageVisible$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) document: any) {
    this.pageVisible$ = concat(
      defer(() => of(!document.hidden)),
      fromEvent(document, 'visibilitychange')
        .pipe(
          map(e => !document.hidden)
        )
    );
  }

  elementInSight(element: ElementRef): Observable<boolean> {

    const elementVisible$ = new Observable(observer => {
      const intersectionObserver = new IntersectionObserver(entries => {
        for (const entry of entries) {
          observer.next(entry);
        }
      });

      intersectionObserver.observe(element.nativeElement);
      return () => {
        intersectionObserver.disconnect();
      };
    }).pipe(
      map((entry: IntersectionObserverEntry) => entry.isIntersecting),
      distinctUntilChanged()
    );

    const elementInSight$ = combineLatest(
      [this.pageVisible$, elementVisible$])
      .pipe(
        map((bs) => bs.every(b => b)),
        distinctUntilChanged()
      );

    return elementInSight$;
  }

}
