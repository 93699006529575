import { Pipe, PipeTransform } from '@angular/core';
import {Constants} from '../../../../globals';

@Pipe({
  name: 'langName'
})
export class LangNamePipe implements PipeTransform {

  transform(value: string): string {
    return Constants.languages.find(language => language.id === value)?.name || value;
  }

}
