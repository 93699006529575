<app-logo-area></app-logo-area>
<main>
  <app-headline-section [title]="'User profile'"></app-headline-section>
  <section class="cl-section-regular">
    <div class="container cl-form">
      <div class="row">
        <div class="col-8">
          <p class="cl-p-large">
            Edit your profile here.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label for="givenname">First name</label>
          <input id="givenname" [(ngModel)]="given_name" type="text">
        </div>
        <div class="col-6">
          <label for="familiyname">Last name</label>
          <input id="familiyname" [(ngModel)]="family_name" type="text">

        </div>
        <div class="col-6">
          <label for="oldpw">Old password</label>
          <input id="oldpw" [(ngModel)]="oldpw" type="password">

        </div>
        <div class="col-6">
          <label for="newpw">New password</label>
          <input id="newpw" [(ngModel)]="newpw" type="password">
        </div>
      </div>
      <div class="row cl-spacing-medium">
        <div class="col-md-4 col-6" style="display:flex; flex-direction: row">
          <ng-container *ngIf="hasmfa">
            <div style="padding-top:8px"> MFA activated.</div>
            <i class="fa fa-check" style="padding-top: 12px; padding-left: 16px"></i>

          </ng-container>
          <ng-container *ngIf="!hasmfa">
            <div style="padding-top: 8px">Please set up MFA.</div>
            <i class="fa fa-exclamation-triangle" style="padding-top: 12px; padding-left: 16px"></i>


          </ng-container>
        </div>
        <div class="col-md-2 col-6" >
          <ng-container *ngIf="hasmfa">

            <button (click)="mfa()" class="btn cl-btn-outline-text" style="float: right">Redo MFA Setup
            </button>
          </ng-container>
          <ng-container *ngIf="!hasmfa">

            <button (click)="mfa()" class="btn cl-btn-outline-text" style="float: right">Setup MFA
            </button>

          </ng-container>
        </div>
        <div class="col-12 cl-spacing-large">
          <button style="float: right " (click)="save()" class="btn cl-btn-outline-primary">Save changes<i
            class="fas fa-angle-right"></i></button>
        </div>
      </div>
    </div>
  </section>

</main>
