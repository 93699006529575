import {AfterContentInit, Component, ContentChildren, QueryList} from '@angular/core';
import {ClTabComponent} from './cl-tab/cl-tab.component';

@Component({
    selector: 'app-cl-tabs',
    templateUrl: './cl-tabs.component.html',
    styleUrls: ['./cl-tabs.component.scss']
})
export class ClTabsComponent implements AfterContentInit {
    @ContentChildren(ClTabComponent) tabs: QueryList<ClTabComponent>;

    constructor() {
    }

    ngAfterContentInit() {
        const activeTabs = this.tabs.filter((tab) => tab.active);
        // if there is no active tab set, activate the first
        if (activeTabs.length === 0 && this.tabs.length > 0) {
            this.selectTab(this.tabs.first);
        }
    }

    selectTab(tab: ClTabComponent) {
        // deactivate all tabs
        this.tabs.toArray().forEach(_tab => _tab.active = false);
        // activate the tab the user has clicked on.
        tab.active = true;
        tab.activated.emit()
    }

}
