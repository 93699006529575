<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Publish PO?</div>
    <div class="modal-body">

      <div *ngIf="poItemsInitial && poItemsInitial.length > 0" class="cl-form">
        <label for="itempreselect" class="form-label">Select which PO Items to publish</label>
        <select id="itempreselect" class="form-select" [(ngModel)]="publicationMode" (change)="publicationChanged()">
          <option value="all">Force re-publication of all PO Items.</option>
          <option value="unpublished">Publish all unpublished PO Items.</option>
          <option value="poonly">Do not publish any PO Items.</option>
          <option value="selected">Publish selected PO Items.</option>
        </select>
        <div class="course-detail" *ngIf="poItems; else loading">
          <h2>PO Items to be published</h2>
          <span *ngFor="let poitem of poItems" class="badge cl-badge-text">{{ poitem.po_item_id }}
            <a role="button" [routerLink]=""
               queryParamsHandling="preserve"
               aria-label="Remove from list"
               (click)="remove(poitem);"
               ><i
              class="fa fa-times"></i></a></span>
          <div *ngIf="poItems.length == 0" style="display: flex; flex-direction: column; align-self: center">
            <div style="text-align: center">
              <img width="125" src="assets/emptystates/NoTasks.png"/>
            </div>
            <div class="cl-h3" style="text-align: center">No PO items to publish</div>
          </div>

        </div>
      </div>
    </div>
    <ng-template #loading>
      <div style="top:calc(50%);position: absolute; overflow: hidden; left: calc(50% - 48px) ; z-index: 3">
        <div>
          <div class="cl-spinner"><i class="fas fa-spinner fa-spin"></i></div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
      <span class="modal-footer-left  ">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="dialogRef.close()">
                        <i class="fas fa-angle-left"></i><span>Cancel</span>
                    </button></span>
    <span class="modal-footer-center  "></span>
    <span class="modal-footer-right  ">
                    <button type="button" class="btn cl-btn-outline-primary" [class.cl-btn-spinner]="publishing"
                            (click)="publish();"
                    >
                        <span>Publish PO</span><i class="fas fa-angle-right"></i>
                    </button></span>
  </div>
</div>
