import {Component, Inject, OnInit} from '@angular/core';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';

@Component({
  selector: 'app-remind-mfa',
  templateUrl: './remind-mfa.component.html',
  styleUrls: ['./remind-mfa.component.scss']
})
export class RemindMFAComponent implements OnInit {

  constructor(public dialogRef: DialogRef<RemindMFAComponent>,
              @Inject(DIALOG_DATA) public data: { access_token: string, email: string }) {
  }

  ngOnInit(): void {
  }

}
