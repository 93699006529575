import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private oAuthService: OAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.oAuthService.events.pipe(
      startWith(this.oAuthService.hasValidAccessToken()),
      map(event => this.oAuthService.hasValidAccessToken()),
      distinctUntilChanged(),
      map( valid => {
        if (valid) {
          return true;
        }
        console.log('Not authenticated, redirecting to login');
        const urlTree = this.router.createUrlTree(['login'], {
          queryParams: {
            returnUrl: state.url.replace(':', '%3A')
          }
        })

        return urlTree;
      })
    )
  }
}
