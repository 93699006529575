import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export class Preview {
  folder_id: string;
  dash_url: string;
  merchant: string;
  hls_url: string;
  asset_id: string;
}


@Injectable({
  providedIn: 'root'
})
export class PreviewRegistrationService {

  previews = new BehaviorSubject<Preview[]>([])

  constructor() {
  }


  public addPreview(preview: Preview) {
    const previews = this.previews.value.slice()
    previews.push(preview)
    this.previews.next(previews)
  }

  public getPreviews(): Observable<Preview[]> {
    return this.previews.asObservable()
  }

  removePreview(preview: Preview) {
    this.previews.next(this.previews.value.filter(p => p.asset_id !== preview.asset_id))
  }
}
