import {Component, Input, OnInit} from '@angular/core';
import {Airline, PoItem} from '../../../types-workflow';
import {environment} from '../../../../environments/environment';
import {Organization} from '../../../types';

@Component({
  selector: '[app-po-item-details]',
  templateUrl: './po-item-details.component.html',
  styleUrls: ['./po-item-details.component.scss']
})
export class PoItemDetailsComponent implements OnInit {
  public VTK_SERVER = environment.VTK
  envsuffix = environment.env_name === 'prod' ? '' : `-${environment.env_name}`;
  protected open = false

  @Input()
  poItem: PoItem;

  @Input()
  organization: Organization;

  @Input()
  airline: Airline;

  constructor() {
  }

  ngOnInit(): void {
  }


}
