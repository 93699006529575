import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PortalIdpService {

  private idpName: string;

  constructor() {
  }

  setIdpName(idpName: string) {
    this.idpName = idpName
  }

  getIdpName(): string {
    return this.idpName

  }
}
