<ng-template #menu>
  <div class="cl-spacing-box" style="display: flex; flex-direction: row; flex-wrap: wrap; white-space: unset;">
    <div
      *ngIf="(only_one_folder_selected$ | async) && 'contentplatform:repository:Mutation/CreateUploadTicket' | isAllowed: 'contentplatform:folder:input.folder_id/' + folder.id ">
      <button class="cl-a-btn" (click)="openGenUploadLinkFromSelection();">
        Upload files
      </button>
    </div>
    <div *ngIf="allowSubmitOnAllFolder$ | async">
      <button class="cl-a-btn" (click)="submitSelected()">
        Add folders to PO
      </button>
    </div>
    <div *ngIf="(no_deleted_files_selected$ | async) && 'contentplatform:repository:Mutation/MoveFile'|isAllowed: 'contentplatform:file:input.src/' + this.folder.id">
      <button class="cl-a-btn"
              (click)="move()">
        Move files
      </button>
    </div>
    <div
      *ngIf="(no_deleted_files_selected$ | async) && 'contentplatform:repository:Mutation/Delete'| isAllowed:  'contentplatform:file:input.id/' + this.folder.id">
      <button class="cl-a-btn"
              (click)="onClickDelete()">
        Delete files
      </button>
    </div>
    <div
      *ngIf="(only_deleted_files_selected$ | async) && 'contentplatform:repository:Mutation/Undelete'| isAllowed:  'contentplatform:file:input.file_id/' + this.folder.id:true">
      <button class="cl-a-btn"
              (click)="onClickUndelete()">
        Restore files
      </button>
    </div>
    <div
      *ngIf="(only_archived_files_selected$ |async) && 'contentplatform:repository:Mutation/Restore'| isAllowed: 'contentplatform:file:input.files/' + this.folder.id">
      <button class="cl-a-btn"
              (click)="restore()">
        Unarchive files
      </button>
    </div>
    <div>
      <button class="cl-a-btn" (click)="copyid()">
        Copy S3 Link
      </button>
    </div>
    <div
      *ngIf="selection.selected.length === 1 && (no_deleted_files_selected$ | async) && 'contentplatform:repository:Mutation/MoveFile'|isAllowed: 'contentplatform:file:input.src/' + this.folder.id">
      <button class="cl-a-btn" (click)="rename()">
        Rename file
      </button>
    </div>
  </div>
</ng-template>

<div class="contextmenu" *ngIf="this.selection.selected.length > 0 " @flyInAnimation
     [class.menuvisible]="this.sideNavService.isOpen|async">
  <div style="height: 100%; display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  padding-bottom: 24px;
  align-content: baseline;">
    <div style="align-self: flex-end">
      <i role="button" (click)="this.selection.clear()" class="fas fa-window-close ">

      </i>
      {{ this.selection.selected.length }} Selected
    </div>
    <div style="align-self: flex-end;">
      <ng-container [ngTemplateOutlet]="menu"></ng-container>
    </div>
  </div>
</div>

<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2><i class="fa fa-folder cl-faded" style="margin-right: 8px"></i>{{ this.folder.name }}
          <a
            *ngIf="(items$ | async)?.parent as parent" class="cl-a-quickaction" [routerLink]="parent | link ">
            ..&nbsp;(up)
          </a>
          <a
            *ngIf="!(items$ | async)?.parent " class="cl-a-quickaction"
            [routerLink]="['/o', organization?.id, 'files']">
            ..&nbsp;(up)
          </a></h2>
      </div>
      <div class="col-12">
        <p class="alert cl-alert-action">
          <a role="button"
             *ngIf="'contentplatform:repository:Mutation/Mkdir' | isAllowed: 'contentplatform:folder:input.folder_id/' + folder.id"
             (click)="mkdir(); ">
            Create Folder
          </a>
          <a role="button"
             *ngIf="'contentplatform:repository:Mutation/CreateUploadTicket' | isAllowed: 'contentplatform:folder:input.folder_id/' + folder.id"
             (click)="openGenUploadLink();">
            Upload Files
          </a>
          <a role="button" (click)="refreshWithFeedback(); ">
            Refresh Listing
          </a>
          <a role="button" (click)="viewAsAsset()">View as asset</a>

          <app-s3-link style="margin-left: 16px" [fof]="this.folder"></app-s3-link>
        </p>
      </div>
    </div>
  </div>

</section>
<app-filter-section [all]="(items$ | async)?.children || []"
                    [filtered]="(items_sorted_and_filtered$ | async)?.children || []"
                    [formGroup]="filters">
  <form [formGroup]="filters" class="cl-form">
    <input type="text" style="width: 100%" formControlName="t"
           placeholder="Filter by name">
    <p class="valid-feedback">Filters are case-insensitive.</p>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" formControlName="d"
             id="check1">
      <label class="form-check-label" for="check1">hide deleted files</label>
    </div>
  </form>
</app-filter-section>
<section class="cl-section-regular cl-form">

  <div class="container">
    <div class="row">
      <div class="col-12" *ngIf="items_sorted_and_filtered$|async as items">
        <table style="table-layout: fixed" class="cl-table-data">
          <colgroup>
            <col span="1" style="width: 40px">
            <col span="1" style="width: 40px">
            <col span="1" style="width: 100%">
            <col span="1" style="width: 170px;" *ngIf="!(isSmall$ | async)">
            <col span="1" style="width: 100px;" *ngIf="!(isSmall$ | async)">
          </colgroup>

          <thead>
          <tr>
            <th class="cl-table-head ">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="checked$ | async"
                       [indeterminate]="indeterminate$ | async"
                       (change)="toggleAll()"/>
              </div>
            </th>
            <th class="" style="margin-top: -4px; margin-bottom: -4px">
              <button style="padding: 0" class="btn cl-btn-outline-secondary cl-btn-icon"
                      (click)="clicked(1)">
                <i *ngIf="sorters.value[1].dir === null" class="cl-faded fas fa-fw fa-sort"></i>
                <i *ngIf="sorters.value[1].dir === 'up'" class="fas fa-fw fa-sort-up"></i>
                <i *ngIf="sorters.value[1].dir === 'down'" class="fas fa-fw fa-sort-down"></i>
              </button>
            </th>
            <th class="" style="margin-top: -4px; margin-bottom: -4px">
              <button style="padding: 0" class="btn cl-btn-outline-secondary cl-btn-icon"
                      (click)="clicked(2)">
                filename
                <i *ngIf="sorters.value[2].dir === null" class="cl-faded fas fa-fw fa-sort"></i>
                <i *ngIf="sorters.value[2].dir === 'up'" class="fas fa-fw fa-sort-up"></i>
                <i *ngIf="sorters.value[2].dir === 'down'" class="fas fa-fw fa-sort-down"></i>
              </button>
            </th>
            <th class="" style="margin-top: -4px; margin-bottom: -4px" *ngIf="!(isSmall$ | async)">
              <button style="padding: 0" class="btn cl-btn-outline-secondary cl-btn-icon"
                      (click)="clicked(3)">
                date
                <i *ngIf="sorters.value[3].dir === null" class="cl-faded fas fa-fw fa-sort"></i>
                <i *ngIf="sorters.value[3].dir === 'up'" class="fas fa-fw fa-sort-up"></i>
                <i *ngIf="sorters.value[3].dir === 'down'" class="fas fa-fw fa-sort-down"></i>
              </button>
            </th>
            <th class="" style="margin-top: -4px; margin-bottom: -4px" *ngIf="!(isSmall$ | async)">
              <button style="padding: 0" class="btn cl-btn-outline-secondary cl-btn-icon"
                      (click)="clicked(4) ">
                size
                <i *ngIf="sorters.value[4].dir === null" class="cl-faded fas fa-fw fa-sort"></i>
                <i *ngIf="sorters.value[4].dir === 'up'" class="fas fa-fw fa-sort-up"></i>
                <i *ngIf="sorters.value[4].dir === 'down'" class="fas fa-fw fa-sort-down"></i>
              </button>
            </th>
          </tr>
          </thead>
          <tbody *ngIf="items.children.length === 0; else bodyWithData">
          <tr *ngIf="items.parent">
            <td></td>
            <td></td>
            <td><a
              style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"

              [routerLink]="items.parent | link"
              (click)="$event.stopPropagation()">
              .. (up)
            </a></td>
            <td *ngIf="!(isSmall$ | async)"></td>
            <td *ngIf="!(isSmall$ | async)"></td>
          </tr>
          <tr class="cl-tr-no-data">
            <td colspan="100" style="text-align: center">No files available.</td>
          </tr>
          </tbody>
          <ng-template #bodyWithData>
            <tbody>
            <tr *ngIf="items.parent">
              <td></td>
              <td></td>
              <td><a
                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                [routerLink]="items.parent | link"
                (click)="$event.stopPropagation()">
                .. (up)
              </a></td>
              <td *ngIf="!(isSmall$ | async)"></td>
              <td *ngIf="!(isSmall$ | async)"></td>
            </tr>
            <tr *ngIf="!items.parent">
              <td></td>
              <td></td>
              <td><a
                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                [routerLink]="['/o', organization.id, 'files']"
                (click)="$event.stopPropagation()">
                .. (up)
              </a></td>
              <td *ngIf="!(isSmall$ | async)"></td>
              <td *ngIf="!(isSmall$ | async)"></td>
            </tr>
            <tr *ngFor="let it of items.children">
              <td [class.cl-faded]="it.deleted">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" [checked]="selection.isSelected(it)"
                         (change)="this.toggle(it)">
                </div>
              </td>
              <td [class.cl-faded]="it.deleted">
                <div style="margin-top: -8px; margin-bottom: -12px;">
                  <app-asset-icon [thing]="it"></app-asset-icon>
                </div>
              </td>
              <td [class.cl-faded]="it.deleted">
                <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                  <a *ngIf="it.__typename === 'Folder'  "

                     [routerLink]=" it | link"
                     (click)="$event.stopPropagation()">
                    {{ it.name }}
                  </a>
                </div>
                <div *ngIf="it.__typename === 'File'"
                     style="display: flex; flex-direction: row">
                  <a
                    [routerLink]=" it | link">
                    {{ it.name }}
                  </a>
                  <div style="width: 24px; margin-left: 8px "
                       *ngIf="it.archive && it.archive.restore_state === RestoreState.Restored"
                       matTooltip="Will be moved back to archive on {{it.archive.expiration | date: 'd MMM y,&nbsp;HH:mm'}}"
                       matTooltipPosition="right">
                    <i class="fas fa-box-open"></i>
                  </div>

                  <div style="width: 24px; margin-left: 8px "
                       *ngIf="it.archive && it.archive.restore_state === RestoreState.Archived"
                       matTooltip="File has been moved to long term storage">
                    <i class="fas fa-box"></i>
                  </div>
                  <div style="width: 24px; margin-left: 8px "
                       *ngIf="it.archive && it.archive.restore_state === RestoreState.Restoring"
                       matTooltip="Estimated time of restoration: {{it | estimatedRestorationTime}}">
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </td>
              <td *ngIf="!(isSmall$ | async)" [class.cl-faded]="it.deleted">
                <div style="text-overflow: clip; overflow: hidden; white-space: nowrap;"
                     *ngIf="it.__typename === 'File'"> {{ it.last_modified | date: 'd MMM y,&nbsp;HH:mm' }}
                </div>
                <div class="cl-faded">
                  <app-folder-info *ngIf="it.__typename === 'Folder'" [folder]="it"
                                   display="date"
                                   fetchPolicy="cache-first"></app-folder-info>
                </div>
              </td>
              <td *ngIf="!(isSmall$ | async)" [class.cl-faded]="it.deleted">
                <div style="text-overflow: clip; overflow: hidden; white-space: nowrap;"
                     *ngIf="it.__typename === 'File'">{{ it.size | fileSize }}
                </div>
                <div class="info-only">

                  <app-folder-info *ngIf="it.__typename === 'Folder'" [folder]="it"
                                   display="numberOfFiles"
                                   fetchPolicy="cache-only"></app-folder-info>
                </div>
              </td>
            </tr>
            </tbody>
          </ng-template>
        </table>

      </div>
    </div>


  </div>
</section>
