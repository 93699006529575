import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {PortalIdpService} from '../services/portal-idp.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'login-page',
  templateUrl: 'login-page.component.html',
  styleUrls: ['login-page.component.scss']
})
export class LoginPageComponent {
  idp: string
  basehost = environment.production ? '.content.castlabs.com' : '.content-stag.castlabs.com';

  constructor(public route: ActivatedRoute, public oAuthService: OAuthService, public router: Router, private portalIdp: PortalIdpService) {
    this.idp = portalIdp.getIdpName()
  }

  login() {
    if (this.route.snapshot.queryParams['returnUrl']) {
      this.oAuthService.initLoginFlow(this.route.snapshot.queryParams['returnUrl'], {
        //    identity_provider: 'Touch'
      });
    } else {
      this.oAuthService.initLoginFlow('/');
    }
  }

  protected readonly window = window;
  protected readonly location = location;
}
