<div class="container cl-form " [formGroup]="poFormGroup">
  <input *ngIf="airlines.length == 1" type="hidden" formControlName="airline" [value]="airlines[0].iata_code">
  <div class="row cl-spacing-small">
    <div *ngIf="airlines.length > 1" class="col-4">
      <label for="airlines" class="form-label">Airlines</label>
      <select id="airlines" class="form-select" formControlName="airline">
        <option *ngFor="let airline of airlines" [value]="airline.iata_code"> {{ airline.airline_name }}
          ({{ airline.iata_code }})
        </option>
      </select>
    </div>


    <div [class.col-5]="airlines.length > 1" [class.col-9]="airlines.length === 1">
      <label for="projectid" class="form-label">Project ID</label>
      <input id="projectid" type="text" formControlName="po_number" pattern="[A-Za-z0-9_\-.]+">
      <p *ngIf="poFormGroup.controls.po_number.errors?.required" class="invalid-feedback">This field is
        required.</p>
      <p *ngIf="!poFormGroup.controls.po_number.errors" class="valid-feedback">The Project ID groups related encodes for easier reference and organization.</p>
    </div>
    <div class="col-3 ">
      <label for="auto_publish" class="form-label">Publication</label>
      <select id="auto_publish" class="form-select" formControlName="auto_publish">
        <option [value]="true"> Automatic Publication
        </option>
        <option [value]="false"> Manual Publication
        </option>
      </select>
    </div>

  </div>

  <div class="row cl-spacing-small">
    <div class="col-12">
      <h2 *ngIf="poItems.controls.length > 0">PO Items</h2>
    </div>
  </div>
  <ng-container formArrayName="poItems">
    <ng-container [formGroup]="poItems.controls[idx]" [formGroupName]="idx"
                  *ngFor="let item of poItems.controls ; let idx = index; trackBy: poItemTrackBy">
      <div class="row cl-spacing-small">
        <div class="col-12">
          <h3  [class.warn]="poItems.controls[idx].invalid">{{ poItems.controls[idx].value['basics']['po_item_id'] }}<button class="cl-a-quickaction"  (click)="poService.removePOItem(poItems.controls[idx].value['id'])">Remove</button></h3>
        </div>
      </div>
      <input id="idinput" type="hidden" formControlName="id">
      <app-basic-poitem-vod-info formControlName="basics"></app-basic-poitem-vod-info>
      <app-vod-watermark-format-specific formControlName="format_specific_data"></app-vod-watermark-format-specific>
      <div class="row">
        <div class="col-12">
          <hr class="cl-spacing-small">
        </div>
      </div>
    </ng-container>


  </ng-container>
  <div class="row">
    <div class="col-12">

      <div class=" cl-spacing-box text-end">
        <button class="btn btn-sm cl-btn-outline-primary" (click)="submit_po()"><span>Start Encode</span><i class="fas fa-angle-right"></i> </button>
      </div>
    </div>
  </div>

</div>
