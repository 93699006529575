import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';



export const authConfigPortal: AuthConfig = {
  issuer: environment.portal_idp_issuer,
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin + '/',
  clientId: environment.portal_idp_app_client_id,
  responseType: 'code',
  scope: 'openid profile email aws.cognito.signin.user.admin',
  showDebugInformation: true,
  sessionChecksEnabled: true,
  timeoutFactor: 0.75,
  // disablePKCI: true,
  clearHashAfterLogin: false,
  logoutUrl: environment.portal_idp_logout_url,
};

export const authConfigCastlabs: AuthConfig = {
  issuer: environment.castlabs_idp_issuer,
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin + '/',
  clientId: environment.castlabs_idp_app_client_id,
  responseType: 'code',
  scope: 'openid',
  showDebugInformation: true,
  sessionChecksEnabled: true,
  timeoutFactor: 0.75,
  // disablePKCI: true,
  clearHashAfterLogin: false,
  logoutUrl: environment.castlabs_idp_logout_url,
};

export const authConfigAerq: AuthConfig = {
  issuer: environment.aerq_idp_issuer,
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin + '/',
  clientId: environment.aerq_idp_app_client_id,
  responseType: 'code',
  scope: 'openid profile email roles web-origins address phone',
  showDebugInformation: false,
  sessionChecksEnabled: true,
  timeoutFactor: 0.75,
  // disablePKCI: true,
  clearHashAfterLogin: false,
}
export const authConfigTouch: AuthConfig = {
  issuer: environment.touch_idp_issuer,
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin + '/',
  clientId: environment.touch_idp_app_client_id,
  dummyClientSecret: environment.touch_idp_app_client_secret,
  responseType: 'code',
  scope: 'openid profile email aws.cognito.signin.user.admin',
  showDebugInformation: false,
  sessionChecksEnabled: true,
  timeoutFactor: 0.75,
  // disablePKCI: true,
  clearHashAfterLogin: false,
}

export const authConfigAbove: AuthConfig = {
  issuer: environment.above_idp_issuer,
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin + '/',
  clientId: environment.above_idp_app_client_id,
  responseType: 'code',
  scope: 'email openid phone',
  showDebugInformation: false,
  sessionChecksEnabled: true,
  timeoutFactor: 0.75,
  // disablePKCI: true,
  clearHashAfterLogin: false,
}
