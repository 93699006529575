<ng-container *ngIf="thing ">
  <ng-container *ngIf="((thing.__typename == 'Folder' || thing.__typename == 'Parent')) else itsafile">
    <i class="fas fa-folder"></i>
  </ng-container>
  <ng-template #itsafile>
    <ng-container *ngIf="!thing.deleted else deleted">
      <ng-container [ngSwitch]="thing.name.toLowerCase().split('.').pop()">
        <i class="fas fa-fw fa-file-video" *ngSwitchCase="'mov'"></i>
        <i class="fas fa-fw fa-file-video" *ngSwitchCase="'mp4'"></i>
        <i class="fas fa-fw fa-file-video" *ngSwitchCase="'mpg'"></i>
        <i class="fas fa-fw fa-file-video" *ngSwitchCase="'ismv'"></i>
        <i class="fas fa-fw fa-file-audio" *ngSwitchCase="'aif'"></i>
        <i class="fas fa-fw fa-file-audio" *ngSwitchCase="'wav'"></i>
        <i class="fas fa-fw fa-file-audio" *ngSwitchCase="'isma'"></i>
        <i class="fas fa-fw fa-file-alt" *ngSwitchCase="'srt'"></i>
        <i class="fas fa-fw fa-file-alt" *ngSwitchCase="'dfxp'"></i>
        <i class="fas fa-fw fa-file-alt" *ngSwitchCase="'ismt'"></i>
        <i class="fas fa-fw fa-file-alt" *ngSwitchCase="'vtt'"></i>
        <i class="fas fa-fw fa-file-image" *ngSwitchCase="'png'"></i>
        <i class="fas fa-fw fa-file-image" *ngSwitchCase="'jpg'"></i>
        <i class="fas fa-fw fa-file-archive" *ngSwitchCase="'zip'"></i>
        <i class="fas fa-fw fa-file" *ngSwitchDefault></i>
      </ng-container>
    </ng-container>
    <ng-template #deleted>
      <i class="fas fa-fw fa-trash" matTooltip="scheduled for permanent deletion"></i>
    </ng-template>
  </ng-template>
</ng-container>
