<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Select Stream Type</div>
    <div class="modal-body">
        <div class="form-check" aria-label="Select Stream Type"
                          *ngFor="let streamType of STREAM_TYPES">
          <input type="radio" class="form-check-input" id="radio{{streamType}}" name="type"  [value]="streamType" [(ngModel)]="selectedStreamType">
          <label class="form-check-label" for="radio{{streamType}}">{{ streamType }}</label>
        </div>
    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none"><button class="btn cl-btn-outline-text"
                                                                             (click)="dialogRef.close()"><i
        class="fas fa-angle-left"></i><span>Cancel</span></button></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
      <button class="btn cl-btn-outline-primary" (click)="dialogRef.close(selectedStreamType)"> <span>change track</span><i
        class="fas fa-angle-right"></i></button>
      </span>

    </div>
  </div>
</div>
