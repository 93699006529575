<div class="modal-dialog container">
  <div class="modal-content">
    <div class="modal-header">Generate upload link to {{ this.folder.name }}</div>

    <div class="modal-body">
      <app-cl-tabs *ngIf="ticket">
        <app-cl-tab tab_title="Link">
          <div style="display: flex;     align-items: center;
    justify-content: center; margin: 16px" >
          <a class="cl-a cl-a-external" [href]="this.ticket?.url" target=”_blank”>Upload link
            to {{ this.folder.name }}</a>
          <button class="btn cl-a-quickaction"
                  (click)="copyClipboard()">Copy to Clipboard</button>
          </div>
        </app-cl-tab>
        <app-cl-tab tab_title=".aws/profile">
          <pre style="background-color: white">
[profile {{ this.message }} ]
# Valid until {{ ticket.expiration }}
credential_process = curl {{ uploaderUrl }}/api_v1/upload/{{ encodeURIComponent(ticket.directory) }}/{{ ticket.token }}
          </pre>
        </app-cl-tab>
        <app-cl-tab tab_title="Direct Upload">
          <iframe [attr.src]="this.ticket?.embedurl " style="width: 100%; height: 55vh"></iframe>
        </app-cl-tab>
      </app-cl-tabs>
      <ng-container *ngIf="!ticket">
        <p>Create multiple directories by entering one directory per line.</p>
        <label for="message">Type message for uploader</label>
        <textarea id="message" [(ngModel)]="message" rows="4" style="width: 100%"
                  cdkTextareaAutosize
        ></textarea>
      </ng-container>

    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none">
        <ng-container *ngIf="this.ticket === null ">
           <button class="btn cl-btn-outline-text"
                   (click)="genLink()">Generate Upload</button>
          </ng-container>
      </span>
      <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
            <button type="button" class="btn cl-btn-primary it-ok"
                    (click)=" dialogRef.close()">
                      <span>Close</span><i class="fas fa-angle-right"></i></button>
      </span>
    </div>
  </div>
</div>
