<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Are you still there?</div>

    <div class="modal-body cl-form">
      <p class="cl-p-large">  If not we will close this session in: <ng-container *ngIf="this.idle.onTimeoutWarning | async as to; else elseBlock">{{to}}</ng-container>
        <ng-template #elseBlock>{{ this.idle.getTimeout() }}</ng-template></p>
    </div>

  </div>
</div>
