<nav class="cl-nav-top">
  <div class="container">
    <div class="row">
      <div class="col-12 cl-grid-flex-centered">
        <label class="cl-nav-side-burger"  (click)="sidenav.toggle()">
          <i class="fas fa-fw fa-bars"></i>
        </label>
        <div class="cl-path" style=" display: flex; flex-direction: row; " *ngIf="!(isSmall | async)">
    <span *ngFor="let bc of  breadcrumbs" style="white-space: nowrap">
      <a [routerLink]="bc.commands">{{ bc.display | orgShort }}</a>
    </span>
        </div>
        <div class="cl-path" style=" display: flex; flex-direction: row; " *ngIf="(isSmall | async)">
    <span *ngFor="let bc of  (breadcrumbs| slice:-2)" style="white-space: nowrap">
      <a [routerLink]="bc.commands">{{ bc.display | orgShort }}</a>
    </span>
        </div>
        <div class="cl-logo cl-grid-flex-centered">
          <span>{{ env }}</span>
          <a href="https://castlabs.com/" target="_blank" rel="noopener"><i class="cl-asset-logo"></i></a>
        </div>
      </div>
    </div>
  </div>
</nav>
