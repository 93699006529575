
<div
  style="position: absolute; top: calc(50% - 64px); left: calc(50% - 268px); transform: translateY(-50%);  margin-left: 12px; background: white; border-radius: 6px">


  <button *ngIf="idp === 'portal'" (click)="login()" class="btn cl-btn-primary"
          style="font-size: 64px; line-height: 80px;"
  >
    BOARD
  </button>
  <button *ngIf="idp === 'above'" (click)="login()" class="btn cl-btn-primary"
          style="font-size: 64px; line-height: 80px;"
  >
    BOARD
  </button>
  <button *ngIf="idp === 'aerq'" (click)="login()" class="btn cl-btn-primary"
          style="font-size: 64px; line-height: 80px;"
  >
    BOARD
  </button>
</div>
<div class="cl-main" [ngStyle]="{'background-image': 'url(../../assets/background-login-' + idp + '.jpg)'}" style="height: 100vh; background-size:
        cover;background-repeat: no-repeat;background-position: center">
  <main>
    <div class="wm-frontpage-spacer" *ngIf="idp === 'castlabs'" >
      <section class="cl-section-regular">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center"><a href="https://castlabs.com/" aria-label="castLabs"><i
              class="cl-asset-logo wm-asset-logo-large"></i></a></div>
          </div>
        </div>
      </section>
      <section class="cl-section-regular">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center"><h2>Content Platform</h2><!----></div>
          </div>
        </div>
      </section>
      <section class="cl-section-alternate">
        <div class="container">
          <div class="row text-center">
            <div class="col-12 col-lg-6 col-xl-4"><p> Sign in with your castLabs account. </p><a
              class="btn cl-btn-primary cl-spacing-medium it-signin"
              (click)="login()" ><span> Sign in </span><i
              class="fas fa-user"></i></a></div>
          </div>
        </div>
      </section>
    </div>

  </main>
  <app-footer>
  </app-footer>
  <div style="height: 0">
    <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
      <a *ngIf="idp !== 'portal'" type="button" class="btn cl-btn-outline-primary cl-btn-icon"
         [href]="'https://portal' + basehost + location.pathname + location.hash"
         style="margin: 4px; border: #2a80d599 1px dashed; background-color: transparent; background-size: 150px; background-image: url('../../assets/background-login-portal.jpg'); width: 150px; height: 100px"></a>
      <a *ngIf="idp !== 'above'" type="button" class="btn cl-btn-outline-primary cl-btn-icon"
         [href]="'https://above' + basehost + location.pathname + location.hash"
         style="margin: 4px; border: #2a80d599 1px dashed; background-color: transparent; background-size: 150px; background-image: url('../../assets/background-login-above.jpg'); width: 150px; height: 100px"></a>
      <a *ngIf="idp !== 'castlabs'" type="button" class="btn cl-btn-outline-primary cl-btn-icon"
         [href]="'https://platform' + basehost + location.pathname + location.hash"
         style="margin: 4px; border: #2a80d599 1px dashed; background-color: transparent; background-size: 150px; background-image: url('../../assets/background-login-castlabs.jpg'); width: 150px; height: 100px"></a>
      <a *ngIf="idp !== 'aerq'" type="button" class="btn cl-btn-outline-primary cl-btn-icon"
         [href]="'https://aerq' + basehost + location.pathname + location.hash"
         style="margin: 4px; border: #2a80d599 1px dashed; background-color: transparent; background-size: 150px; background-image: url('../../assets/background-login-aerq.jpg'); width: 150px; height: 100px"></a>
    </div>
  </div>
</div>
