import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {File, Folder, Mutation, ProcessStateEnum, Query, Tag, Track, TrackType} from '../../../types';
import {Apollo, gql, QueryRef} from 'apollo-angular';
import {Constants} from '../../../globals';
import {distinctUntilChanged, filter, finalize, map, mergeMap, shareReplay, skipWhile, take, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {BannerService} from '../../../banner/banner.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FILE_DETAILS, GET_PARENTS, GET_PROCESS, START_GENERATE_STILLS} from '../../../queries';
import {ActivatedRoute} from '@angular/router';
import {FileFolderLinkPipe} from '../../file-folder-link.pipe';
import {BreadCrumb} from '../../logo-area/logo-area.component';
import {Dialog} from '@angular/cdk/dialog';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from '@vinlos/ngx-gallery';
import {OrganizationService} from '../../../services/organization.service';


@Component({
  selector: 'app-file-details',
  templateUrl: './file-details.component.html',
  styleUrls: ['./file-details.component.scss']
})
export class FileDetailsComponent implements OnInit, OnDestroy {
  TRACKTYPES = TrackType


  file: File;
  folder: Folder;

  breadcrumps$: Observable<BreadCrumb[]>;


  languages = Constants.languages.slice();

  ffprobe: Array<any>;
  warnings: Array<any>;
  extra_info: any;
  mediainfo: string;
  mediainfo_json: Array<any>;
  tracks: Track[];

  tags: Tag[];

  glacier: boolean;
  query: QueryRef<any>;
  refresh = new BehaviorSubject<boolean>(true);
  refreshDialog = new BehaviorSubject<boolean>(false);
  stills: NgxGalleryImage[] = [];
  has_video: boolean;

  private assetpath$: Observable<string>;

  subscriptions: Subscription[] = []
  galleryOptions: NgxGalleryOptions[] = [
    {
      width: '100%',
      thumbnailsColumns: 6,
      height: '800px',
      imageAnimation: NgxGalleryAnimation.Fade
    },
    {
      breakpoint: 992,
      height: '600px',
    },
    {
      breakpoint: 768,
      height: '400px',
    }

  ];

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    protected dialog: Dialog,
    private snackBar: MatSnackBar,
    private organizationService: OrganizationService,
    private banner: BannerService) {
    this.assetpath$ = this.route.paramMap.pipe(
      map(pm => {
        return pm.get('assetpath') || '';
      }),
      distinctUntilChanged(),
      tap(x => console.log(`assetpath$ next value: ${x}`)),
      shareReplay(1)
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  ngOnInit(): void {
    this.breadcrumps$ = this.assetpath$.pipe(
      mergeMap(assetpath => this.apollo.query<Query>({
          query: GET_PARENTS,
          variables: {
            id: `s3://${assetpath.split('/').slice(0, -1).join('/')}/`
          },
        })
      ),
      map(res => res.data.folder),
      filter(folder => folder.parents !== undefined),
      tap(folder => this.folder = folder),
      map(folder => folder.parents.length > 1 ? folder.parents.map(f => {
          return {
            'commands': new FileFolderLinkPipe(this.organizationService).transform(f),
            'display': f.name
          }
        }) : []
      ))
    this.subscriptions.push(this.assetpath$.subscribe(assetpath => {


      this.query = this.apollo.watchQuery<Query>({
        query: FILE_DETAILS,
        variables: {
          id: `s3://${assetpath}`
        },
        errorPolicy: 'all',
        nextFetchPolicy: 'cache-only',
        useInitialLoading: false
      })
      const vChanges = this.query.valueChanges.pipe(
        skipWhile((response) => response.data?.file === null),
        map(response => {
            const f = Object.assign({}, response.data.file);
            if (response.errors) {
              this.banner.open('Error retrieving metadata. Data might not be complete.', ['Close'])
              for (const err of response.errors) {
                if (err.path[0] === 'file' && err.path[1] === 'tags') {
                  f.tags = []
                }
                if (err.path[0] === 'file' && err.path[1] === 'tracks') {
                  f.tracks = []
                }
                if (err.path[0] === 'file' && err.path[1] === 'mediainfo') {
                  f.mediainfo = ''
                }
                if (err.path[0] === 'file' && err.path[1] === 'mediainfo_json') {
                  f.mediainfo_json = [JSON.stringify({'message': 'no mediainfo data'})]
                }
                if (err.path[0] === 'file' && err.path[1] === 'ffprobe') {
                  f.ffprobe = [JSON.stringify({'message': 'no ffprobe data'})]
                }
              }
            }
            return f;
          }
        ),
        shareReplay(1),
      )
      vChanges.subscribe({
        next: file => {
          this.file = file

          if (file.ffprobe !== null) {
            this.ffprobe = file.ffprobe.map(e => JSON.parse(e))
          } else {
            this.ffprobe = [{'message': 'no ffprobe data'}]
          }
          if (file.warnings !== null) {
            this.warnings = file.warnings.map(e => JSON.parse(e))
          } else {
            this.warnings = []
          }
          if (file.extra_info !== null) {
            this.extra_info = JSON.parse(file.extra_info)
          } else {
            this.extra_info = []
          }
          if (file.mediainfo_json !== null) {
            this.mediainfo_json = file.mediainfo_json.map(e => JSON.parse(e))
          } else {
            this.mediainfo_json = [{'message': 'no mediainfo data'}]
          }
          this.mediainfo = file.mediainfo
          this.has_video = file.tracks !== null ? file.tracks.filter(t => t.track_type === TrackType.Video).length > 0 : false;
          this.stills = file.stills.map((still, i) => {
            return {
              small: still.url,
              big: still.url,
              medium: still.url,
            }
          }).slice(0, 100)
          this.glacier = file.archived;
          if (file.tags !== null) {
            this.tags = file.tags;
            if (this.tags == null || this.tags.length === 0) {
              this.tags = []
            }
            this.tags = this.tags.map(t => {
              return {'key': t.key, 'value': t.value}
            });
            if (file.tracks !== null) {
              for (const track of file.tracks) {
                if (this.languages.filter(a => a.id === track.language).length === 0) {
                  this.languages = [].concat(...this.languages, {'id': track.language, 'name': track.language})
                }
              }

              this.tracks = file.tracks.map((v) => {
                return {
                  'index': v.index,
                  'enabled': v.enabled,
                  'language': v.language,
                  'track_type': v.track_type
                }
              });
            } else {
              this.tracks = []
            }
          }
          this.refresh.next(false)
        },
        error: error => {
          alert(error);
        }
      })
    }));
  }

  refreshTracks() {
    this.refresh.next(true);
    this.subscriptions.push(this.apollo.mutate<Mutation>({
      mutation: gql`mutation RefreshTracks($id: ID!) {
      refreshTracks(input: {file_id: $id, force: true}) {
          file {
            id
            tracks {
              index
              enabled
              language
              track_type
            }
          }
        }
      }
      `,
      variables: {
        id: this.file.id
      },
      fetchPolicy: 'network-only'
    }).pipe(finalize(() => this.refresh.next(false))).subscribe({
      next: res => {
        this.snackBar.open('Tracks have been refreshed from underlying media metadata', null, {duration: 5000});
      },
      error: err => {
        this.banner.open('Tracks were not updated: ' + err.message, ['CLOSE'])
      }
    }));
  }

  onAddTag(event: Event) {
    this.tags.push({
      key: '',
      value: ''
    })
  }

  save() {
    const tag_inputs = [];
    for (const tag of this.tags) {
      if (tag.key !== null && tag.key !== '') {
        let item = ''
        item += '{\n';
        item += `  key: "${tag.key}"`
        item += `  value:" ${tag.value}"`
        item += '}\n'
        tag_inputs.push(item)
      }
    }

    const track_input = [];
    for (const track of this.tracks) {
      let item = ''
      item += '{\n';
      item += `  index: ${track.index}`
      item += `  track_type: ${track.track_type}`
      item += `  language: "${track.language}"`
      item += `  enabled: ${track.enabled}`
      item += '}\n'
      track_input.push(item)
    }
    this.refresh.next(true);
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation UpdateFileDetails($id: ID!) {
        updateFile(input: {
          file_id: $id
          tags: [${tag_inputs.join(',\n')}
          ]
          tracks: [${track_input.join(',\n')}
          ]
        }) {
          file {
            id
            tags {
              key
              value
            }
            tracks {
              index
              enabled
              language
              track_type
            }
          }
        }
    }
    `,
      variables: {
        id: this.file.id,
      }
    }).pipe(finalize(() => this.refresh.next(false))).subscribe(
      res => {
        this.snackBar.open('Workflow metadata has been updated', null, {duration: 5000})
        const file = res.data.updateFile.file;
        this.tracks = file.tracks;
        this.tags = res.data.updateFile.file.tags;
      },
      err => this.banner.open('Workflow metadata was not updated: ' + err.message, ['Close'])
    );
  }


  refresh_ffprobe() {
    this.refreshDialog.next(true);
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation Refresh_ffprobe($id: ID!) {
        refreshFFProbe(input: {file_id: $id, force: true}) {
          file {
            id
            ffprobe
          }
        }
      }
      `,
      fetchPolicy: 'network-only',
      variables: {
        id: this.file.id
      }
    }).pipe(finalize(() => this.refreshDialog.next(false))).subscribe(() => {
        this.snackBar.open('FFProbe has been updated', null, {duration: 5000})
      },
      err => {
        this.dialog.closeAll();
        this.banner.open('FFProbe was not updated: ' + err.message, ['Close'])
      })
  }

  refresh_basic_qc() {
    this.refreshDialog.next(true);
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation RefreshBasicQc($id: ID!) {
        refreshBasicQc(input: {file_id: $id, force: true}) {
          file {
            id
            warnings
          }
        }
      }
      `,
      fetchPolicy: 'network-only',
      variables: {
        id: this.file.id
      }
    }).pipe(finalize(() => this.refreshDialog.next(false))).subscribe(() => {
        this.snackBar.open('Basic QC has been updated', null, {duration: 5000})
      },
      err => {
        this.dialog.closeAll();
        this.banner.open('Basic QC was not updated: ' + err.message, ['Close'])
      })
  }

  refresh_extra_info() {
    this.refreshDialog.next(true);
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation RefreshExtra($id: ID!) {
        refreshExtra(input: {file_id: $id, force: true}) {
          file {
            id
            extra_info
          }
        }
      }
      `,
      fetchPolicy: 'network-only',
      variables: {
        id: this.file.id
      }
    }).pipe(finalize(() => this.refreshDialog.next(false))).subscribe(() => {
        this.snackBar.open('Basic QC has been updated', null, {duration: 5000})
      },
      err => {
        this.dialog.closeAll();
        this.banner.open('Basic QC was not updated: ' + err.message, ['Close'])
      })
  }

  openDialog(dialog: TemplateRef<any>) {
    this.dialog.open(dialog)
  }

  refresh_mediainfo() {
    this.refreshDialog.next(true);
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation refreshMediaInfo($id: ID!) {
        refreshMediaInfo(input: {file_id: $id, force: true}) {
          file {
            id
            mediainfo_json
            mediainfo
          }
        }
      }
      `,
      variables: {
        id: this.file.id
      },
      fetchPolicy: 'network-only'
    }).pipe(finalize(() => this.refreshDialog.next(false))).subscribe(() => {
        this.snackBar.open('MediaInfo has been updated', null, {duration: 5000})
      },
      err => {
        this.dialog.closeAll();
        this.banner.open('MediaInfo was not updated: ' + err.message, ['Close']);
      })
  }

  generate_stills() {
    const ref = this.snackBar.open(`Started generation of still images for ${this.file.name}  ... `, null, {
      duration: 5000
    });
    this.apollo.mutate<Mutation>({
      mutation: START_GENERATE_STILLS,
      variables: {
        file_id: this.file.id
      }
    }).subscribe(d => {
        console.log(d)
        const wq = this.apollo.watchQuery<Query>({
          query: GET_PROCESS,
          pollInterval: 1000,
          variables: {
            'id': d.data.generate_stills.id
          }
        })
        wq.valueChanges.pipe(
          skipWhile((data) => {
            return data.data.process.state === ProcessStateEnum.InProgress
          }),
          take(1),
        ).subscribe(data => {
            if (data.data.process.state === ProcessStateEnum.Success) {
              this.apollo.query<Query>({
                query: FILE_DETAILS,
                variables: {
                  id: this.file.id
                },
                fetchPolicy: 'network-only'
              }).subscribe(() => {
                ref.dismiss()
                this.snackBar.open(
                  `Stills for ${this.file.name} generated successfully.`,
                  null,
                  {duration: 5000})
              })
            } else {
              ref.dismiss()
              this.banner.open(
                `The preview generation for ${this.file.name} did not succeed - ${data.data.process.state}: ${data.data.process.message}`,
                ['Close'])
            }


          }
        )
      },
      err => {
        ref.dismiss()
        this.banner.open(
          `Submission of preview encode failed: ${err}`,
          ['Close'])
      }
    )
  }

  copy_ffprobe() {

    navigator.clipboard.writeText(JSON.stringify(this.ffprobe))
    this.snackBar.open('Copied FFProbe JSON to clipboard', null, {duration: 5000})

  }

  copy_warnings() {

    navigator.clipboard.writeText(JSON.stringify(this.warnings))
    this.snackBar.open('Copied FFProbe JSON to clipboard', null, {duration: 5000})

  }

  copy_extra_info() {

    navigator.clipboard.writeText(JSON.stringify(this.extra_info))
    this.snackBar.open('Copied FFProbe JSON to clipboard', null, {duration: 5000})

  }

  copy_mediainfo() {

    navigator.clipboard.writeText(JSON.stringify(this.mediainfo))
    this.snackBar.open('Copied MediaInfo text to clipboard', null, {duration: 5000})

  }

  copy_mediainfo_json() {

    navigator.clipboard.writeText(JSON.stringify(this.mediainfo_json))
    this.snackBar.open('Copied MediaInfo JSON to clipboard', null, {duration: 5000})

  }

  openBanner() {
    this.banner.open('afsdfsdffds', ['Ok'])
  }
}
