import {Pipe, PipeTransform, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {File, Query} from '../types';
import {FILE_ARCHIVE_STATE} from '../queries';
import {RemainingTimePipe} from './remaining-time-pipe';

@Pipe({
  name: 'estimatedRestorationTime',
  pure: false
})
export class EstimatedRestorationTimePipe implements PipeTransform, OnDestroy {
  private _lastValue?: File;
  private _subscription?: Subscription;

  constructor(private apollo: Apollo, private readonly ref: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
  }

  transform(file: File): string {
    if (!this._subscription) {
      this._subscription = this.apollo.query<Query>({
        query: FILE_ARCHIVE_STATE,
        variables: {
          id: file.id
        }
      }).pipe(map(d => d.data.file)).subscribe(value => {
        this._lastValue = value;
        this.ref.markForCheck();
      });
    }
    const eta = this._lastValue?.archive.extra.restore_eta;
    if (eta) {
      return new RemainingTimePipe().transform(eta, 'almost done');
    }
    return "Calculating.."
  }
}
