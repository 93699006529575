<div class="modal-dialog container">
  <div class="modal-content">
    <div class="modal-header">Scan QR Code</div>

    <div class="modal-body cl-form">
      <div >
        <div style="width: 300px; height: 300px;margin: auto">
          <qrcode *ngIf="secret != null" elementType="svg"
                  qrdata="otpauth://totp/Portal:{{ data.email }}?secret={{ this.secret }}&issuer=castLabs"
                  width="300"></qrcode>
        </div>
      </div>
      <label for="code">enter your 6 digit code to verify the setup</label>
      <input id="code" [(ngModel)]="code" style="width: 100%" type="text">

    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="dialogRef.close()">
                        <i class="fas fa-angle-left"></i><span>Cancel</span>
                    </button></span>
      <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok"
                            (click)="verifyCode()"
                    >
                        <span>Verify code</span><i class="fas fa-angle-right"></i>
                    </button></span>
    </div>
  </div>
</div>

