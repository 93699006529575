import {Component, Input} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Organization} from '../../types';
import {Airline, CodecType, Mutation, ProcessStateEnum, Query} from '../../types-workflow';
import {clean_datetime, shortenJanusOrg} from '../utils';
import {ALL_SYSTEMS, IfeFormat, PoDestination} from '../../target_systems';
import {OAuthService} from 'angular-oauth2-oidc';
import {GET_PROCESS, START_WORKFLOW_DEFAULT} from '../../queries';
import {mergeMap, skipWhile, take, tap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-order-music',
  templateUrl: './audio-order.component.html',
  styleUrl: './audio-order.component.scss'
})
export class AudioOrderComponent {
  @Input()
  organization: Organization;

  @Input()
  airlines: Airline[] = [];

  availableSystems = []

  ifeFormats: IfeFormat[] = []
  poDestinations: PoDestination[] = []
  poFormGroup: FormGroup;
  ifeFormat: IfeFormat;
  started = false;
  starting = false;
  error = null;

  constructor(private formBuilder: FormBuilder, oAuthService: OAuthService, private apollo: Apollo) {
    this.availableSystems = ALL_SYSTEMS.map(system => ({
      ...system,
      formats: system.formats.filter(format => format.audio_only)
    }));
    this.poFormGroup = formBuilder.group({
      po_number: [clean_datetime(new Date()), Validators.required],
      airline: [null, Validators.required],
      po_destination: [null],
      auto_publish: [false],
      due_date: ['now'],
      poItems: this.formBuilder.array([this.createPoItem('', '')]),
      date_created: [null],
      email_notification: [null, [Validators.required, Validators.email]],
      ife: [null, Validators.required],
      ife_format: [null, Validators.required],
      idType: ['isrc', Validators.required],

    });
    this.poFormGroup.controls['ife'].valueChanges.subscribe(value => {
      this.ifeFormats = this.availableSystems.find(as => as.item_id === value).formats;
      console.log('Ife changed', value)
      if (!this.poFormGroup.controls['ife_format'].value ||
        (
          this.poFormGroup.controls['ife_format'].value &&
          !this.ifeFormats.find(f => f.item_id === this.poFormGroup.controls['ife_format'].value))) {
        console.log('Default to first ife format')
        this.poFormGroup.controls['ife_format'].setValue(this.ifeFormats[0].item_id);
      } else {
        console.log('Keeping ife format')
      }
    })
    this.poFormGroup.controls['ife_format'].valueChanges.subscribe(value => {
      this.ifeFormat = this.ifeFormats.find(f => f.item_id === value)
      console.log('Ife format changed', this.ifeFormat)
      this.poDestinations = this.ifeFormat.po_destinations
      console.log('poDestinations', this.poDestinations)
      if (
        !this.poFormGroup.controls['po_destination'].value ||
        (
          this.poFormGroup.controls['po_destination'].value &&
          !this.poDestinations.find(d => d.destination_id === this.poFormGroup.controls['po_destination'].value))) {

        this.poFormGroup.controls['po_destination'].setValue(this.poDestinations[0].destination_id);
      }
    })
    // todo retrieve email from user profile
    this.poFormGroup.controls['email_notification'].setValue(oAuthService.getIdentityClaims()['email']);

  }

  createPoItem(id, filename): FormGroup {
    console.log('createPoItem', id, filename)
    return this.formBuilder.group({
      id: [id],
      filename: [filename]
    });
  }

  onInputChange(index: number): void {
    const poItems = this.poItems;
    const lastItem = poItems.at(index);
    console.log('onInputChange', index, lastItem)
    if (lastItem.get('id')?.value && lastItem.get('filename')?.value && index === poItems.length - 1) {
      poItems.push(this.createPoItem('', ''));
    }
  }

  get poItems(): FormArray {
    return this.poFormGroup.get('poItems') as FormArray;
  }

  onPaste(event: ClipboardEvent, index: number): void {
    const clipboardData = event.clipboardData?.getData('text') || '';
    const rows = clipboardData.split('\n').filter(row => row.trim() !== ''); // Filter out empty rows

    // Check if the clipboard data is in valid CSV/tab-separated format
    const isCSV = rows.some(row => row.includes('\t'));
    if (!isCSV) {
      console.error('Invalid paste format. Expected tab-separated values.');
      return;
    }

    event.preventDefault(); // Prevent default paste behavior

    rows.forEach((row, rowIndex) => {
      const [id, filename] = row.split('\t').map(item => item.trim()); // Map to remove unnecessary spaces
      if (id && filename) {
        if (rowIndex === 0) {
          // Update the current row
          this.poItems.at(index).patchValue({'id': id, 'filename': filename});
        } else {
          // Add new rows
          this.poItems.push(this.createPoItem(id, filename));
        }
      }
    });

    // Ensure there's an empty row at the end for new entries
    const lastItem = this.poItems.at(this.poItems.length - 1);
    if (lastItem.get('id')?.value || lastItem.get('filename')?.value) {
      this.poItems.push(this.createPoItem('', ''));
    }
  }

  trackByIndex(index: number): number {
    return index;
  }


  compare_item_id(o1: any, o2: any) {
    return o1?.item_id === o2?.item_id;
  }

  compare_destination_id(o1: any, o2: any) {
    return o1?.destination_id === o2?.destination_id;
  }

  submitPo() {
    if (!this.poFormGroup.valid) {
      console.log(this.poFormGroup)
      alert('Invalid')
      this.poFormGroup.markAllAsTouched();
      return
    }
    this.starting = true;
    this.started = false
    this.poFormGroup.disable()
    this.poItems.disable()

    const workflows = []
    const emails = this.poFormGroup.value.email_notification.split(',').map(e => e.trim()).filter(e => e !== '')

    for (const track of this.poItems.value) {
      console.log(this.ifeFormat.vtk_template)
      if (track.id !== '' && track.filename !== '') {
        workflows.push(this.apollo.use('workflow').mutate<Mutation>({
          mutation: START_WORKFLOW_DEFAULT,
          variables: {
            email_notification: emails,
            auto_publish: this.poFormGroup.value.auto_publish,
            aspect_ratio: null,
            filename: track.filename,
            metadata_source: null,
            wrap_up_workflow: this.ifeFormat.wrap_up_workflow,
            content_identifier: `${this.poFormGroup.value.idType}://${track.id}`,
            po_item_id: track.id,
            po_name: `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number.trim()}`,
            po_destination: this.poFormGroup.value.po_destination,
            vtk_template: this.ifeFormat.vtk_template,
            format_specific_data: '{}',
            watermark: false,
            tracks: [{
              'codec_type': CodecType.Audio,
              'lang': 'und'
            }]
          }
        }).pipe(
          tap(d => {
            this.starting = false
            this.started = true;
            console.log('Done')
            this.poFormGroup.enable();
            this.poItems.enable();
          }),
          mergeMap(d => {
            const processId = d.data.start_workflow_default.id
            return this.apollo.use('workflow').watchQuery<Query>(
              {
                query: GET_PROCESS,
                pollInterval: 2000,
                variables: {
                  id: processId
                }
              }
            ).valueChanges
          }),
          skipWhile(d => d.data.process.state === ProcessStateEnum.InProgress),
          take(1)
        ))
      }
    }


    forkJoin(workflows).subscribe(
      () => {

      },
      error => {
        this.starting = false
        this.started = false;
        console.log('Error', error)
        this.poFormGroup.enable();
        this.poItems.enable();
        this.error = error.toString()
      }
    )
  }

  reset() {
    this.poFormGroup.reset();
    this.error = null;
    this.started = false;
    this.starting = false;
    this.poFormGroup.controls['poItems'] = this.formBuilder.array([this.createPoItem('', '')]);
  }
}
