import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {IfeFormat} from '../../../target_systems';


@Component({
  selector: 'app-above-default-format-specific',
  templateUrl: './above-default-format-specific.component.html',
  styleUrls: ['./above-default-format-specific.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AboveDefaultFormatSpecificComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AboveDefaultFormatSpecificComponent,
      multi: true
    }
  ]
})
export class AboveDefaultFormatSpecificComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input()
  parentForm: FormGroup;

  @Input() ifeFormat: IfeFormat;

  form: FormGroup;

  protected readonly environment = environment;

  changed: any = () => {
  };
  touched: any = () => {
  };

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({});
    this.form.valueChanges.subscribe((val) => {
      if (this.touched) {
        this.touched();
      }
      if (this.changed) {
        this.changed(val);
      }
    });
  }

  ngOnInit(): void {
  }

  writeValue(value: any): void {
    if (value) {
      this.form.patchValue(value, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState ?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.ifeFormat && this.form) {
      console.log('Been there done that')
      if (this.ifeFormat.vtk_template === 'nice_media') {
        if (!('license_expiration_date' in this.form.controls)) {
          const twoMonthsFromNow = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 2, 1)
          this.form.addControl('license_expiration_date',
            new FormControl(
              `${twoMonthsFromNow.getDate()}.${twoMonthsFromNow.getMonth()}.${twoMonthsFromNow.getFullYear()}`,
              [Validators.required]));
        }
      } else {
        this.form.removeControl('license_expiration_date');
      }
      if (this.ifeFormat.vtk_template === 'delta-flytedge') {
        if (!('dfe_ca_bits' in this.form.controls)) {
          this.form.addControl('dfe_ca_bits', new FormControl('48'));
        }
        if (!('dfe_key_id' in this.form.controls)) {
          this.form.addControl('dfe_key_id', new FormControl('5EF8A7AB75624939AF735ACC42F5C54D'));
        }
        if (!('dfe_key' in this.form.controls)) {
          this.form.addControl('dfe_key', new FormControl('AC9FC2B4BD3B258D53E6AC8AB6E14C07'));
        }
        if (!('dfe_disable_drm' in this.form.controls)) {
          this.form.addControl('dfe_disable_drm', new FormControl('0'));
        }
      } else {
        this.form.removeControl('dfe_ca_bits')
        this.form.removeControl('dfe_key_id')
        this.form.removeControl('dfe_key')
        this.form.removeControl('dfe_disable_drm')
      }

      if (this.ifeFormat.vtk_template.startsWith('thales-avant-up-avod-')) {
        if (!('thales_fastpath_airline' in this.form.controls)) {
          this.form.addControl('thales_fastpath_airline', new FormControl('', [Validators.required]));
        }
        if (!('video_bitrate' in this.form.controls)) {
          this.form.addControl('video_bitrate', new FormControl('', [
            Validators.min(1000000),
            Validators.max(100000000)
          ]));
        }

      } else {
        this.form.removeControl('thales_fastpath_airline')
        this.form.removeControl('video_bitrate')
      }

      if (this.ifeFormat.vtk_template === 'mezzanine_default') {
        if (!(this.form.controls['preroll'])) {
          this.form.addControl('preroll', new FormControl(''));
        }
        if (!(this.form.controls['card_url'])) {
          this.form.addControl('card_url', new FormControl(''));
        }
        if (!(this.form.controls['card_duration'])) {
          this.form.addControl('card_duration', new FormControl(''));
        }
      } else {
        this.form.removeControl('preroll')
        this.form.removeControl('card_url')
        this.form.removeControl('card_duration')
      }

    }
  }

}
