import {Component, Inject, Input, OnDestroy, OnInit, TrackByFunction} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {map, mergeMap, skipWhile, take} from 'rxjs/operators';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BannerService} from '../../../banner/banner.service';
import {Apollo} from 'apollo-angular';
import {Airline, Mutation, ProcessStateEnum, Query} from '../../../types-workflow';

import {GET_PROCESS, START_WORKFLOW_VOD_DEFAULT} from '../../../queries';
import {forkJoin} from 'rxjs';
import {Router} from '@angular/router';
import {Po2024Service} from '../../po-2024.service';
import {VodDefaultFormatSpecificComponent} from '../vod-default-format-specific/vod-default-format-specific.component';
import {BasicPoitemVodInfoComponent} from '../basics-poitem-vod-info/basic-poitem-vod-info.component';
import {clean_datetime, shortenJanusOrg} from '../../utils';
import {PO_DESTINATIONS, PoDestination} from '../../../target_systems';
import {Organization} from '../../../types';


@Component({
  selector: 'app-vod-default-po',
  templateUrl: './vod-default-po.component.html',
  styleUrls: ['./vod-default-po.component.css'],
})
export class VodDefaultPoComponent implements OnInit, OnDestroy {

  @Input()
  organization: Organization;

  @Input()
  airlines: Airline[] = []
  poDestinations: PoDestination[] = []
  poFormGroup: FormGroup;

  poItemTrackBy: TrackByFunction<FormGroup> = (index: number, poitem: FormGroup) => {
    return poitem.get('id').value;
  }

  constructor(
    private apollo: Apollo,
    public snackBar: MatSnackBar,
    @Inject('vod_default') protected poService: Po2024Service,
    private formBuilder: FormBuilder,
    private banner: BannerService,
    private router: Router
  ) {
    this.poFormGroup = formBuilder.group({
      airline: [null, Validators.required],
      po_number: [clean_datetime(new Date()), Validators.required],
      po_destination: [null, Validators.required],
      auto_publish: [true, Validators.required],
      poItems: formBuilder.array([]),
    });


    this.poService.getFormState().pipe(take(1)).subscribe(savedForm => {
      if (savedForm) {
        savedForm.poItems.forEach(poItem => (this.poFormGroup.controls.poItems as FormArray).push(this.formBuilder.group(poItem)));
        this.poFormGroup.setValue(savedForm, {emitEvent: false})
      }
    });
    this.poFormGroup.controls['airline'].valueChanges.subscribe(airline => {
      const myAirline = this.airlines.filter(a => a.iata_code === airline)[0]
      this.poDestinations = PO_DESTINATIONS.hasOwnProperty(myAirline.iata_code)
        ? [{display_name: myAirline.airline_name, destination_id: myAirline.iata_code}, {
          display_name: 'Default CDN',
          destination_id: 'vod'
        }]
        : [{display_name: 'Default CDN', destination_id: 'vod'}];
      this.poFormGroup.controls['po_destination'].setValue(this.poDestinations[0].destination_id)
    })
    this.poService.poItemToAdd$.subscribe(data => {
      console.log('poItemToAdd$')
      if (data) {
        (this.poFormGroup.controls.poItems as FormArray).push(this.formBuilder.group(
          Object.assign(
            {},
            {
              basics: BasicPoitemVodInfoComponent.DEFAULTS,
              format_specific_data: VodDefaultFormatSpecificComponent.DEFAULTS
            },
            data)))
      }
    });
    this.poService.poItemToRemove$.subscribe(id => {
      console.log('poItemToRemove$')
      if (id) {
        const index = (this.poFormGroup.controls.poItems as FormArray).controls.findIndex(poItem => poItem.get('id').value === id);
        (this.poFormGroup.controls.poItems as FormArray).removeAt(index);
      }
    });
  }

  ngOnInit() {
    if (this.airlines.length === 1) {
      this.poFormGroup.controls['airline'].setValue(this.airlines[0].iata_code);
    }
  }


  compare_destination_id(o1: any, o2: any) {
    console.log(o1, o2)
    return o1?.destination_id === o2?.destination_id;
  }

  submit_po() {

    if (!this.poFormGroup.valid) {
      console.log(this.poFormGroup)
      alert('Invalid')
      this.poFormGroup.markAllAsTouched();
      return
    }
    const workflows = []
    for (const poItem of this.poFormGroup.value.poItems) {


      workflows.push(this.apollo.use('workflow').mutate<Mutation>({
        mutation: START_WORKFLOW_VOD_DEFAULT,
        variables: {
          input_brefix: poItem.basics.input_brefix,
          po_item_id: poItem.basics.po_item_id.trim(),
          po_name: `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number.trim()}`,
          po_destination: this.poFormGroup.value.po_destination,
          vtk_template: 'cmaf-abr',
          auto_publish: this.poFormGroup.value.auto_publish,
          format_specific_data: JSON.stringify(poItem.format_specific_data)
        }

      }).pipe(
        mergeMap(d => {
          const processId = d.data.start_workflow_vod_default.id
          return this.apollo.use('workflow').watchQuery<Query>(
            {
              query: GET_PROCESS,
              pollInterval: 2000,
              variables: {
                id: processId
              }
            }
          ).valueChanges
        }),
        skipWhile(d => d.data.process.state === ProcessStateEnum.InProgress),
        take(1)
      ))
    }
    const sb_ref = this.snackBar.open('Workflows started.', `go to ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number}`,
      {
        duration: 5000
      })


    sb_ref.onAction().subscribe(() => {
      this.router.navigate(
        ['/o', this.organization.id, 'workflows',
          this.poFormGroup.value.airline,
          `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`])
    })
    forkJoin(workflows).subscribe(
      r => {
        sb_ref.dismiss();
        this.snackBar.open(`All workflows in ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number} have completed.`,
          `go to ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number}`,
          {
            duration: 5000
          }).onAction().subscribe(() => this.router.navigate(
          ['/o', this.organization.id, 'workflows',
            this.poFormGroup.value.airline,
            `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`]))
      },
      err => {
        this.banner.open('Workflow submission failed: ' + err, ['Close'])
      }
    )

  }

  get poItems() {
    return this.poFormGroup.get('poItems') as FormArray<FormGroup>;
  }

  ngOnDestroy(): void {
    this.poService.setFormState(this.poFormGroup.value)
  }

}
