import {Component, Input, OnInit} from '@angular/core';

import {BreadCrumb} from '../logo-area/logo-area.component';
import {Airline} from '../../types-workflow';
import {Organization} from '../../types';

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss'],
  providers: []

})
export class WorkflowsComponent implements OnInit {

  @Input()
  airlines: Airline[] = [];

  @Input()
  organization: Organization;

  breadCrumbs: BreadCrumb[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.breadCrumbs = [
      {display: 'Content Portal', commands: ['/o', this.organization.id]},
      {display: 'Workflows', commands: ['/o', this.organization.id, 'workflows']},
    ]
  }

}
