import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-asset-icon',
  templateUrl: './asset-icon.component.html',
  styleUrls: ['./asset-icon.component.scss']
})
export class AssetIconComponent implements OnInit {

  @Input()
  thing: any;

  constructor() { }

  ngOnInit(): void {
  }

}
