import {Component, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-version-upgrader',
  template: '',
})
export class VersionUpgraderComponent implements OnInit {

  constructor(private swUpdate: SwUpdate, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.swUpdate.versionUpdates.pipe(
      filter((evt)  => evt.type === 'VERSION_READY'),
    ).subscribe(event => {
      const snackBarRef = this.snackBar.open('New version available', 'Reload');
      snackBarRef.onAction().subscribe(() => {
        window.location.reload();
      });

    });

  }

}
