import {Component, Input, OnInit} from '@angular/core';
import {distinctUntilChanged, map, skip, switchMap, tap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {Hit, Organization, Query, RestoreState} from '../../types';
import {FormControl, FormGroup} from '@angular/forms';
import {FILTER_DEBOUNCER} from '../filter-section/filter-section.component';


@Component({
  selector: 'app-search-page',
  templateUrl: 'search-page.component.html',
  styleUrls: ['search-page.component.scss']
})
export class SearchPageComponent implements OnInit {

  @Input()
  organization: Organization;

  items: Array<string>;
  search_ongoing = false;
  hits: Hit[] = [];


  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
  });


  searchHits = (term) => this.apollo.query<Query>({
    query: gql`
        {
          search_metadata(search_term: "${term}") {
            hits {
              key
              brefix
              ffprobe
              warnings
              extra_info
              mediainfo
              SequenceNumber
              timestamp
            }
          }
        }
      `
  }).pipe(
    map(res => res.data.search_metadata.hits)
  )

  ngOnInit() {
    FILTER_DEBOUNCER(this.filters.valueChanges).pipe(
      skip(1),
      distinctUntilChanged(),
      tap(term => this.search_ongoing = true),
      map(term => term['t']),
      switchMap(this.searchHits)
    ).subscribe(res => {
        this.hits = res;
        this.search_ongoing = false;
        this.snackBar.open(this.hits.length + ' hits', null, {'duration': 5000})
      }
    );
  }


  constructor(
    private snackBar: MatSnackBar,
    private apollo: Apollo) {

  }

  protected readonly RestoreState = RestoreState;
}
