import {AfterViewInit, Component, Input} from '@angular/core';
import {VERSION} from '../../../environments/version';
import {SideNavService} from '../../services/side-nav.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {cleanLocalStorage} from '../utils';
import {Organization} from '../../types';
import {Observable} from 'rxjs';
import {PortalIdpService} from '../../services/portal-idp.service';
import {OrganizationService} from '../../services/organization.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {UserProfileService} from '../../services/user-profile.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.scss'],
  animations: [trigger('flyInAnimation', [
    transition(':leave', [

      animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
      style({height: 0})
    ]),
    transition(':enter', [
      style({height: 0}),
      animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
    ])

  ])]
})
export class LoggedInComponent implements AfterViewInit {
  orgSelected$: Observable<Organization>;
  licensedProducts$: Observable<String[]>;

  prod = environment.production
  version = VERSION.version + '-' + VERSION.suffix

  navOpened: boolean;

  @Input()
  organizations: Organization[];

  constructor(public oauthService: OAuthService,
              protected sideNavService: SideNavService,
              protected organizationService: OrganizationService,
              public userProfileService: UserProfileService,
              protected portalIdp: PortalIdpService) {

  }

  ngAfterViewInit() {

    this.orgSelected$ = this.organizationService.organization;
    this.licensedProducts$ = this.organizationService.licensedProducts;

    this.sideNavService.open()
    this.sideNavService.isOpen.subscribe(isOpen => this.navOpened = isOpen)

  }

  doLogout() {
    this.oauthService.logOut({
      client_id: this.oauthService.clientId,
      logout_uri: this.oauthService.redirectUri,
    });
  }

  cleanLocalStorage() {
    cleanLocalStorage()
  }
}
