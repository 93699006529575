import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Apollo} from 'apollo-angular';
import {Router} from '@angular/router';
import {BannerService} from '../../../banner/banner.service';
import {File, Folder, Mutation, ProcessStateEnum, Query} from '../../../types';
import gql from 'graphql-tag';
import {FILE_FOLDER_LIST, GET_PROCESS} from '../../../queries';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';

@Component({
  selector: 'app-rename-file-dialog',
  templateUrl: './rename-file-dialog.component.html',
  styleUrls: ['./rename-file-dialog.component.scss']
})
export class RenameFileDialogComponent implements OnInit {
  dest_name: string;
  startmoving = false;

  constructor(public dialogRef: DialogRef<RenameFileDialogComponent>,
              @Inject(DIALOG_DATA) public data: { file: File, folder: Folder },
              private apollo: Apollo,
              public snackBar: MatSnackBar,
              public router: Router,
              public banner: BannerService
  ) {
    this.dest_name = data.file.name;
  }

  ngOnInit() {

  }

  public rename() {
    this.startmoving = true
    this.snackBar.open(`Renaming ${this.data.file.name} to ${this.dest_name}  ... `, '', {
      duration: 5000
    });

    const mutation = `mutation MyMutation($src: String!, $dest: String!) {
      move_file(input: {src: $src, dest: $dest}) {
        state
        id
      }
    }
    `

    this.apollo.mutate<Mutation>({
      mutation: gql(mutation),
      variables: {
        src: this.data.file.id,
        dest: `${this.data.folder.id}${this.dest_name}`
      }
    }).subscribe(d => {


      const wq = this.apollo.watchQuery<Query>({
        query: GET_PROCESS,
        variables: {id: d.data.move_file.id},
        pollInterval: 1000
      }).valueChanges.subscribe(e => {
        this.dialogRef.close()
        if (e.data.process.state === ProcessStateEnum.Failed) {
          this.banner.open(`The rename operation did not succeed.`, ['Close'])
          wq.unsubscribe()
          return
        }
        if (e.data.process.state === ProcessStateEnum.InProgress) {
          return
        }
        wq.unsubscribe()
        this.apollo.query({
          query: FILE_FOLDER_LIST,
          fetchPolicy: 'network-only',
          variables: {
            id: this.data.folder.id,
            show_deleted: true
          }
        }).subscribe()
        this.snackBar.open(
          `Successfully renamed ${this.data.file.name} to ${this.dest_name}.`,
          null,
          {duration: 5000})
      })
    })

  }
}
