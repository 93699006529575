import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList, SimpleChanges
} from '@angular/core';
import {FormControl, FormControlName, FormGroup, NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, take} from 'rxjs/operators';
import {concat, Observable} from 'rxjs';


export const FILTER_DEBOUNCE_TIME = 350;

export function FILTER_DEBOUNCER(inputStream$: Observable<any>) {
  return concat([
    inputStream$.pipe(take(1)),
    inputStream$.pipe(debounceTime(300))
  ]);
}

@Component({
  selector: 'app-filter-section',
  template: `
    <section class="cl-section-regular cl-section-tight" *ngIf="all || filtered">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <details class="cl-dropdown" style="width: 100%;"
                     [open]="searchOpen"
                     (toggle)="searchOpen = $event.target.open">
              <summary>
                <div *ngIf="all && filtered">Filter: {{ (filtered || []).length }}
                  of {{ (all || []).length }} shown
                </div>
                <div *ngIf="!all && filtered">Search: {{ (filtered || []).length }} results
                </div>
              </summary>
              <ng-content></ng-content>
            </details>

          </div>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['./filter-section.component.scss']
})
export class FilterSectionComponent implements AfterContentInit, OnChanges {
  @Input() openByDefault = false;
  @Input() all: any[] | null;
  @Input() filtered: any[] | null;
  @Input() formGroup: FormGroup;

  searchOpen = false;


  /*
    private focusFilterField() {
    return (event) => {
      event.preventDefault()
      this.searchOpen = true;
      this.cdRef.markForCheck();
      setTimeout(() => {
        this.filterfield.nativeElement.focus();
      }, 0);
    }
  }


  ngAfterViewInit(): void {
    if (navigator.userAgent.indexOf('Mac') > -1) {
      this.macListener = this.renderer.listen(window, 'keydown.meta.f', this.focusFilterField())
    } else {
      this.winListener = this.renderer.listen(window, 'keydown.ctrl.f', this.focusFilterField())
    }


  }

    ngOnDestroy(): void {
    if (this.macListener) {
      this.macListener()
    }
    if (this.winListener) {
      this.winListener()
    }
  }

   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {

  }

  filterEmptyParams(params: { [key: string]: any }): { [key: string]: any } {
    const filteredParams: { [key: string]: any } = {};
    Object.keys(params).forEach(key => {
      if (params[key] !== null && params[key] !== '' && params[key]) {
        filteredParams[key] = params[key];
      }
    });
    return filteredParams;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.all && this.filtered && this.all.length !== this.filtered.length) {
      this.searchOpen = true;
    }
  }

  ngAfterContentInit(): void {
    this.searchOpen = this.openByDefault;
    this.formGroup.valueChanges.pipe(debounceTime(FILTER_DEBOUNCE_TIME)).subscribe(val => {
      const mergedParams = Object.assign([], this.activatedRoute.snapshot.queryParams, val)
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.filterEmptyParams(mergedParams)
      })
    });
    this.activatedRoute.queryParams.subscribe(params => {
      const values = this.formGroup.value;
      Object.keys(values).forEach(key => values[key] = params[key] ?? '');
      this.formGroup.setValue(values);
    })

  }
}
