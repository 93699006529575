<div class="row cl-spacing-small" [formGroup]="form" *ngIf="ifeFormat">

  <div class="col-6" *ngIf="form.controls.license_expiration_date">
    <label for="license_expiration_date" class="form-label">License Expiration Date<span
      class="required">*</span></label>
    <input id="license_expiration_date" type="text" formControlName="license_expiration_date">
    <p *ngIf="form.controls.license_expiration_date.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.license_expiration_date.errors" class="valid-feedback">Insert the Nicemedia specific
      license expiration date</p>
  </div>

  <!-- Delta Flytedge -->
  <div class="col-6" *ngIf="form.controls.dfe_ca_bits">
    <label for="dfe_ca_bits" class="form-label">CA bits<span class="required">*</span></label>
    <select id="dfe_ca_bits" class="form-select" formControlName="dfe_ca_bits">
      <option [value]="'48'">
        48 Bits
      </option>
      <option [value]="'24'">
        24 Bits
      </option>
      <option [value]="'0'">
        Disabled
      </option>
    </select>
    <p *ngIf="form.controls.dfe_ca_bits.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.dfe_ca_bits.errors" class="valid-feedback">Number of bits for content armor. 24 or 48,
      anything else disables content armor</p>
  </div>

  <div class="col-6" *ngIf="form.controls.dfe_key_id">
    <label for="dfe_key_id" class="form-label">DRM Key ID<span class="required">*</span></label>
    <input id="dfe_key_id" type="text" formControlName="dfe_key_id">
    <p *ngIf="form.controls.dfe_key_id.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.dfe_key_id.errors" class="valid-feedback">DRM Key ID</p>
  </div>

  <div class="col-6" *ngIf="form.controls.dfe_key">
    <label for="dfe_key" class="form-label">DRM Key<span class="required">*</span></label>
    <input id="dfe_key" type="text" formControlName="dfe_key">
    <p *ngIf="form.controls.dfe_key.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.dfe_key.errors" class="valid-feedback">DRM Key</p>
  </div>

  <div class="col-6" *ngIf="form.controls.dfe_iv">
    <label for="dfe_iv" class="form-label">DRM Key IV<span class="required">*</span></label>
    <input id="dfe_iv" type="text" formControlName="dfe_iv">
    <p *ngIf="form.controls.dfe_iv.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.dfe_iv.errors" class="valid-feedback">DRM IV</p>
  </div>
  <div class="col-6" *ngIf="form.controls.dfe_disable_drm">
    <label for="dfe_disable_drm" class="form-label">Disable DRM<span class="required">*</span></label>
    <select id="dfe_disable_drm" class="form-select" formControlName="dfe_disable_drm">
      <option [value]="'0'">
        DRM enabled (0)
      </option>
      <option [value]="'1'">
        DRM disabled (1)
      </option>
    </select>

    <p *ngIf="form.controls.dfe_disable_drm.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.dfe_disable_drm.errors" class="valid-feedback">Disable DRM? "1" disables it, anything else
      enables it</p>
  </div>
  <!-- Delta Flytedge END -->

  <div class="col-4" *ngIf="form.controls.preroll">
    <label for="preroll" class="form-label">Preroll URL</label>
    <input id="preroll" type="text" formControlName="preroll">
    <p *ngIf="form.controls.preroll.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.preroll.errors" class="valid-feedback">URL to preroll to prepended for Mezzanine
      deliveries</p>
  </div>

  <div class="col-4" *ngIf="form.controls.card_url">
    <label for="card_url" class="form-label">Title Card URL</label>
    <input id="card_url" type="text" formControlName="card_url">
    <p *ngIf="form.controls.card_url.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.card_url.errors" class="valid-feedback">URL to a title card image</p>
  </div>
  <div class="col-4" *ngIf="form.controls.card_duration">
    <label for="card_duration" class="form-label">Title Card Duration</label>
    <input id="card_duration" type="text" formControlName="card_duration">
    <p *ngIf="form.controls.card_duration.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="form.controls.card_duration.errors" class="valid-feedback">Duration of title card display</p>
  </div>

  <div class="col-4" *ngIf="form.controls.video_bitrate">
    <label for="video_bitrate" class="form-label">Override video bit rate</label>
    <input id="video_bitrate" type="number" formControlName="video_bitrate">
    <p *ngIf="form.controls.video_bitrate.errors?.min || form.controls.video_bitrate.errors?.max"
       class="invalid-feedback">
      Must be between 1Mbit and 100Mbit.
    </p>
    <p *ngIf="!form.controls.video_bitrate.errors" class="valid-feedback">Set a specific video bit rate</p>
  </div>
  <div class="col-4" *ngIf="form.controls.thales_fastpath_airline">
    <label for="thales_fastpath_airline" class="form-label">Thales fast-path airline</label>
    <input id="thales_fastpath_airline" type="text" minlength="3" maxlength="3"
           formControlName="thales_fastpath_airline">
    <p *ngIf="form.controls.thales_fastpath_airline.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.thales_fastpath_airline.errors" class="valid-feedback">ICAO airline code for Thales
      destination</p>
  </div>
</div>
