export const sd_payload_sizes = [{
  'id': '8',
  'name': '8 bits / 256 distinct WM',
  'max': 255
},
  {
    'id': '13',
    'name': '13 bits / 8192 distinct WM',
    'max': 8191
  },
  {
    'id': '16',
    'name': '16 bits / 65536 distinct WM',
    'max': 65535
  },
  {
    'id': '24',
    'name': '24 bits / 16777216 distinct WM',
    'max': 16777215
  },
  {
    'id': '32',
    'name': '32 bits / 4294967296 distinct WM',
    'max': 4294967295
  }
]
// 4/8/16/24/32/48
export const ca_payload_sizes = [{
  'id': '4',
  'name': '4 bits / 16 distinct WM',
  'max': 16
},
  {
    'id': '8',
    'name': '8 bits / 256 distinct WM',
    'max': 255
  },
  {
    'id': '16',
    'name': '16 bits / 65536 distinct WM',
    'max': 65536
  },
  {
    'id': '24',
    'name': '24 bits / 16777216 distinct WM',
    'max': 16777216
  },
  {
    'id': '32',
    'name': '32 bits / 4294967296 distinct WM',
    'max': 4294967296
  },
  {
    'id': '48',
    'name': '48 bits / 281474976710656 distinct WM',
    'max': 281474976710656
  }
]
export const strengths = [
  {
    'id': '3',
    'name': '3 - medium robustness, medium visibility'
  },
  {
    'id': '4',
    'name': '4 - good robustness, high visibility'
  },
  {
    'id': '6',
    'name': '6 - higher robustness, suitable for blind decoding'
  },
  {
    'id': '8',
    'name': '8 - higher robustness, suitable for blind decoding'
  }
]
export const densities = [{
  'id': 10,
  'name': '10'
},
  {
    'id': 30,
    'name': '30'
  },
  {
    'id': 50,
    'name': '50'
  },
  {
    'id': 70,
    'name': '70'
  },
  {
    'id': 90,
    'name': '90'
  },
  {
    'id': 100,
    'name': '100'
  }
]
