<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">{{ data.headline }}</div>

    <div class="modal-body">
      <p [innerHtml]="data.message "></p>

    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="dialogRef.close()">
                        <i class="fas fa-angle-left"></i><span>Cancel</span>
                    </button></span>
      <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok" [class.cl-btn-spinner]="performing"
                            (click)="performAction()"
                    >
                        <span>{{ data.actionName }}</span><i class="fas fa-angle-right"></i>
                    </button></span>
    </div>
  </div>
</div>
