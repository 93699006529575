<div class="modal-dialog ">
  <div class="modal-content">
    <div class="modal-header">Restore Files</div>

    <div class="modal-body">
      <p>You are about to restore the following files / remove delete marker </p>
      <table class="cl-table-data">
        <thead>
        <tr>
          <th class="cl-table-head cl-p-tiny ">

          </th>
          <th class="cl-table-head cl-p-tiny ">
              File or folder name
          </th>
          <th class="cl-table-head cl-p-tiny ">
              Last Modified
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of files">
          <td style="width: 40px">
            <i class="fas fa-file"></i>

          </td>
          <td>
            {{file.name}}
          </td>
          <td>
            {{file.last_modified | date: 'd MMM y,&nbsp;HH:mm'}}
          </td>
        </tbody>
      </table>

    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="dialogRef.close()">
                        <i class="fas fa-angle-left"></i><span>Cancel</span>
                    </button></span>
      <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok" [class.cl-btn-spinner]="deleting"
                            (click)="performUndelete()"
                    >
                        <span>Restore files</span><i class="fas fa-angle-right"></i>
                    </button></span>
    </div>
  </div>
</div>
