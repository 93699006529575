import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-cl-tab',
  templateUrl: './cl-tab.component.html',
  styleUrls: ['./cl-tab.component.scss']
})
export class ClTabComponent implements OnInit {

  @Input() tab_title: string;
  @Input() active = false;

  @Output() activated = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
