import {Component, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-headline-section',
  templateUrl: './headline-section.component.html',
  styleUrls: ['./headline-section.component.scss']
})
export class HeadlineSectionComponent implements OnInit {

  @Input()
  title: string

  @Input()
  menu: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
