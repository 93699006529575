import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Organization, Policy} from '../types';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  _organizationId: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(null);
  _policies: BehaviorSubject<Policy[]> = new BehaviorSubject<Policy[]>(null);
  _licensedProducts: BehaviorSubject<String[]> = new BehaviorSubject<String[]>(null);

  constructor() {
  }

  setLicensedServices(products: String[]) {
    this._licensedProducts.next(products);
  }



  setPolicies(policies: Policy[]) {
    this._policies.next(policies);
  }

  setOrganization(organization: Organization) {
    this._organizationId.next(organization);
  }

  get policies() {
    return this._organizationId.asObservable();
  }

  get policiesSnapshot() {
    return this._policies.value;
  }

  get licensedProducts() {
    return this._licensedProducts.asObservable();
  }

  get organization() {
    return this._organizationId.asObservable();
  }

  get organizationSnapshot() {
    return this._organizationId.value;
  }

  public isAllowed(action: string, resource: string, debug = null): boolean {
    if (debug) {
      console.log('Checking if allowed', action, resource, this._policies.value)
    }
    return this._policies.value.some(
      policy => policy.statements.some(statement => {
          if (!statement.actions.includes(action)) {
            return false;
          }
          if (debug) {
            console.log('Action matched: ', statement.actions)
          }

          if (resource === null) {
            return true;
          }
          const a = statement.resources.some(statement_resource => this.fnMatch(statement_resource, resource));
          if (a && statement.conditions && statement.conditions.length > 0) {
            console.log('Conditions not implemented yet')
          }
          if (debug) {
            console.log('Allowed: ', a)
            if (!a) {
              console.log('Resource requested: ', resource)
              console.log('Resources allowed: ', statement.resources)
            }
          }
          return a;
        }
      )
    );
  }

  public isAllowedAnywhere(action: string): boolean {
    return this._policies.value.some(
      policy => policy.statements.some(statement => {
          return statement.actions.includes(action);
        }
      )
    );
  }

  fnMatch(pattern: string, str: string): boolean {
    // Escape special characters, then replace wildcards with RegEx equivalents
    const regexPattern = pattern
      .replace(/[.+^${}()|[\]\\]/g, '\\$&') // Escape special characters
      .replace(/\*/g, '.*') // Replace * with .*
      .replace(/\?/g, '.'); // Replace ? with .

    const regex = new RegExp(`^${regexPattern}$`);

    return regex.test(str);
  }
}
