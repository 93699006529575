import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Constants} from '../../../../globals';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: [
    './language-select.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageSelectComponent),
      multi: true
    }
  ]
})
export class LanguageSelectComponent implements ControlValueAccessor {

  lastSelected = '';
  languages: string[] = [];
  allLanguages = Constants.languages

  addOnBlur = true;

  onChange: any = () => {
  };
  onTouch: any = () => {
  };



  constructor() {

  }

  writeValue(obj: any): void {
    this.languages = obj || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  add(language: string): void {
    if (language !== '') {
      this.languages.push(language);
      this.onChange(this.languages);
      setTimeout(() => {
        this.lastSelected = ''
      });
    }
  }

  remove(language: string): void {
    const index = this.languages.indexOf(language);
    if (index >= 0) {
      this.languages.splice(index, 1);
      this.onChange(this.languages);
    }
  }

  selected(language: { name: string, id: string }): void {
    this.languages.push(language.id);
    this.onChange(this.languages);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.languages, event.previousIndex, event.currentIndex);
    this.onChange(this.languages);
  }
}
