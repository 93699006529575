import {ChangeDetectorRef, Component, Input} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, shareReplay, skipWhile, tap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Folder, Organization, Policy, Query} from '../../../types';
import {FILE_FOLDER_LIST_WITH_ARCHIVE} from '../../../queries';
import {BannerService} from '../../../banner/banner.service';
import {BreadCrumbs} from '../../logo-area/logo-area.component';
import {PoMetaService} from '../../po-meta.service';
import {OrganizationService} from '../../../services/organization.service';
import {Airline} from '../../../types-workflow';


@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent {

  @Input() organization: Organization;
  @Input() policies: Policy[];
  @Input() airlines: Airline[];

  protected allowPoSubmit: boolean;

  assetpath$: Observable<string>;
  view: string;

  folder$: Observable<Folder>;


  public refreshing = new BehaviorSubject<boolean>(false);

  breadcrumbs$: Observable<BreadCrumbs>;


  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public banner: BannerService,
    private apollo: Apollo,
    public poMetaService: PoMetaService,
    public organizationService: OrganizationService,
    private readonly cdRef: ChangeDetectorRef) {

    this.assetpath$ = this.route.paramMap.pipe(
      map(pm => {
        return pm.get('assetpath') || '';
      }),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.route.queryParams.pipe(
      map(qp => qp.view || ''),
    ).subscribe(view => this.view = view)

    this.allowPoSubmit =
      this.organizationService.isAllowedAnywhere('contentplatform:workflow:Mutation/StartWorkflowDefault') ||
      this.organizationService.isAllowedAnywhere('contentplatform:workflow:Mutation/StartWorkflowAerqDefault') ||
      this.organizationService.isAllowedAnywhere('contentplatform:workflow:Mutation/StartWorkflowVodDefault') ||
      this.organizationService.isAllowedAnywhere('contentplatform:workflow:Mutation/StartWorkflowVodBatch');

    this.folder$ = this.assetpath$.pipe(
      mergeMap(brefix => {
        this.refreshing.next(true)

        return this.apollo
          .watchQuery<Query>({
            query: FILE_FOLDER_LIST_WITH_ARCHIVE,
            useInitialLoading: false,
            variables: {
              id: `s3://${brefix}/`,
              show_deleted: true
            },
            nextFetchPolicy: 'cache-only'
          }).valueChanges.pipe(
            skipWhile(res => res.partial),
            map(res => res.data.folder),
            tap(() => {
              this.cdRef.markForCheck()
              this.refreshing.next(false)
            }),
          )
      }),
      tap(folder => {
        if (this.view === '') {
          if (
            folder.folders.length === 0 &&
            folder.files.length < 100 &&
            folder.files.length > 0 &&
            folder.files.some(f => f.tracks && f.tracks.length > 0)
          ) {
            this.view = 'asset';
          } else {
            this.view = 'listing';
          }
          this.router.navigate([], {relativeTo: this.route, queryParams: {view: this.view}, queryParamsHandling: 'merge', replaceUrl: true})
        }
      }),
      shareReplay(1),
    )


    this.breadcrumbs$ = this.folder$.pipe(
      filter(folder => folder.parents !== undefined),
      map(folder => {
          const crumbs = [{
            'commands': `/o/${this.organization.id}/files`,
            'display': 'Assets'
          }]
          crumbs.push(...(folder.parents.length > 0 ? folder.parents.map(f => {
            return {
              'commands': `/o/${this.organization.id}/files/browse/${f.id.replace('s3://', '')}`,
              'display': f.name
            }
          }) : []))

          crumbs.push({
            'commands': `/o/${this.organization.id}/files/browse/${folder.id.replace('s3://', '')}`,
            'display': folder.name
          })
          return crumbs;
        }
      ))

  }


}
