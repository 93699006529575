import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {map, shareReplay, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Folder, Organization, Policy, Query} from '../../../types';
import {OrganizationService} from '../../../services/organization.service';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {FILE_FOLDER_LIST_WITH_ARCHIVE, GET_ROOTS} from '../../../queries';

@Component({
  selector: 'app-home-component',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {
  @Input()
  organization: Organization;

  @Input()
  policies: Policy[];


  loading$ = new BehaviorSubject<boolean>(true);
  roots$: Observable<Folder[]>;
  isSmall$: Observable<boolean>;


  constructor(private apollo: Apollo,
              private breakPointObserver: BreakpointObserver) {
    this.isSmall$ = this.breakPointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map(result => result.matches),
        shareReplay()
      );

  }

  ngOnInit() {
    this.roots$ = this.apollo.query<Query>({
      query: GET_ROOTS(this.organization.id),
    }).pipe(
      tap({
        next: () => this.loading$.next(true),
        error: (err) => {
          this.loading$.next(false);
          alert(err)
        },
        complete: () => this.loading$.next(false)
      }),
      map(res => [...res.data.roots].sort((a, b) => a.name.localeCompare(b.name)))
    );

  }

  /*
    f1: folder(id: "s3://content-repo-sannies-contentsupplier-castlabs-vod/1o1media/"){
    id
    name
  }

   */

  do() {
    this.apollo.query<Query>({
      fetchPolicy: 'network-only',
      query: gql`query test {
  f1: folder(id: "s3://content-repo-sannies-contentsupplier-castlabs-vod/1o1media/"){
    id
    name
  }
  f2: folder(id: "s3://content-repo-sannies-contentsupplier-castlabs/"){
    id
    name
  }
}`,
    }).subscribe(res => {
      console.log(res)
    })
  }

}
