<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>
          <i class="fa fa-folder cl-faded" style="margin-right: 8px"></i>
          {{ this.folder.name }}
          <a *ngIf="parent" class="cl-a-quickaction" [routerLink]="parent | link ">..&nbsp;(up)</a>
        </h2>
      </div>
      <div class="col-12">

        <p class="alert cl-alert-action">
          <a role="button"  *ngIf="'contentplatform:repository:Mutation/PreviewGenerate' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id" (click)="generatePreview()">Generate preview</a>
          <a role="button"  *ngIf="'contentplatform:repository:Mutation/CreateUploadTicket' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id" (click)="generateUploadLink()">Upload files</a>
          <a role="button" *ngIf="this.poMetaService.poService?.action | isAllowed: 'contentplatform:asset:input.input_brefix/' + folder.id.replace('s3://', '')" (click)="addToPo()">Add to PO</a>
          <a role="button" *ngIf="this.poMetaService.poService?.action | isAllowed: 'contentplatform:asset:input.content_identifier/input_brefix://' + folder.id.replace('s3://', '')" (click)="addToPo()">Add to PO</a>
          <a role="button" (click)="viewAsListing()">View as listing</a>
          <app-s3-link style="margin-left: 16px" [fof]="this.folder"></app-s3-link>
        </p>
      </div>
      <div class="col-12" *ngIf="previewTokenPayload">

        <app-mezz-preview-player [m3u8]="previewTokenPayload.hls_url"
                                 [manifest]="previewTokenPayload.dash_url"
                                 [drmtodayToken]="previewTokenPayload.drmtoday_token"
                                 [accessToken]="previewTokenPayload.access_token"
                                 [selectedVideoFileTrack]="selectedVideoFileTrack"
                                 [selectedAudioFileTrack]="selectedAudioFileTrack"
                                 [selectedTextFileTrack]="selectedTextFileTrack"
        >
        </app-mezz-preview-player>


      </div>
    </div>
    <div class="row" *ngIf="videoTracks.length > 0">
      <div class="col-12">
        <div class="cl-h3">Video</div>
      </div>
      <div class="col-12">
        <table class="cl-table-data cl-form">
          <thead>
          <tr>
            <th *ngIf="'contentplatform:repository:Mutation/PreviewGenerate' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id"></th>
            <th>File</th>
            <th>Tr.</th>
            <th style="width: 40px;">En.</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let v of videoTracks">
            <td style="width: 32px" *ngIf="'contentplatform:repository:Mutation/PreviewGenerate' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id">
              <div class="form-check">
                <input [disabled]="!previewTokenPayload" type="radio" name="video" class="form-check-input"
                       [checked]="selectedVideoFileTrack?.file.name === v.file.name && selectedVideoFileTrack?.track.index === v.track.index"
                       (change)="this.selectedVideoFileTrack = v">
              </div>
            </td>
            <td class="cl-font-condensed"><a [routerLink]="v.file |link ">{{ v.file.name }}</a></td>
            <td>{{ v.track.index }}</td>
            <td style="width: 40px;">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="switch-1" [(ngModel)]="v.track.enabled"  [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + v.file.id)">
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="audioTracks.length > 0">
      <div class="col-12">
        <div class="cl-h3">Audio</div>
      </div>
      <div class="col-12">
        <table class="cl-table-data cl-form">
          <thead>
          <tr>
            <th *ngIf="'contentplatform:repository:Mutation/PreviewGenerate' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id"></th>
            <th>File</th>
            <th>Tr.</th>
            <th>Layout</th>
            <th>Language</th>
            <th style="width: 40px;">En.</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let v of audioTracks; index as i">
            <td style="width: 32px" *ngIf="'contentplatform:repository:Mutation/PreviewGenerate' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id">
              <div class="form-check">
                <input [disabled]="!previewTokenPayload" type="radio" name="audio" class="form-check-input"
                       [checked]="selectedAudioFileTrack?.file.name === v.file.name && selectedAudioFileTrack?.track.index === v.track.index"
                       (change)="this.selectedAudioFileTrack = v">
              </div>
            </td>
            <td class="cl-font-condensed"><a [routerLink]="v.file |link ">{{ v.file.name }}</a></td>
            <td>{{ v.track.index }}</td>
            <td>{{ v.channelLayout }} <i *ngIf="v.channelWarn" class="fa fa-exclamation-triangle"></i></td>
            <td>
              <select class="form-select d-lg-none" [(ngModel)]="v.track.language" [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + v.file.id)">
                <option *ngFor="let l of languages" [value]="l.id">{{ l.id }}</option>
              </select>
              <select class="form-select d-none d-lg-block" [(ngModel)]="v.track.language" [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + v.file.id)">
                <option *ngFor="let l of languages" [value]="l.id">{{ l.name }}</option>
              </select>
            </td>
            <td style="width: 40px;">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" [(ngModel)]="v.track.enabled" [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + v.file.id)">
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="textTracks.length > 0">
      <div class="col-12">
        <div class="cl-h3">Text</div>
      </div>
      <div class="col-12">
        <table class="cl-table-data cl-form">
          <thead>
          <tr>
            <th *ngIf="'contentplatform:repository:Mutation/PreviewGenerate' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id"></th>
            <th>File</th>
            <th>Tr.</th>
            <th>Type</th>
            <th>Language</th>
            <th style="width: 40px;">En.</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="('contentplatform:repository:Mutation/PreviewGenerate' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id) && previewTokenPayload">
            <td style="width: 32px" >
              <div class="form-check">
                <input [disabled]="!previewTokenPayload" type="radio" name="text" class="form-check-input"
                       [checked]="selectedTextFileTrack === null"
                       (change)="this.selectedTextFileTrack = null">
              </div>
            </td>
            <td class="cl-font-condensed">No Text Track</td>
            <td></td>
            <td></td>
            <td></td>
            <td style="width: 40px;"></td>
          </tr>
          <tr *ngFor="let v of textTracks">
            <td style="width: 32px" *ngIf="'contentplatform:repository:Mutation/PreviewGenerate' | isAllowed: 'contentplatform:asset:input.folder_id/' + folder.id">
              <div class="form-check">
                <input [disabled]="!previewTokenPayload" type="radio" name="text" class="form-check-input"
                       [checked]="selectedTextFileTrack?.file.name === v.file.name && selectedTextFileTrack?.track.index === v.track.index"
                       (change)="this.selectedTextFileTrack = v">
              </div>
            </td>
            <td class="cl-font-condensed"><a [routerLink]="v.file |link ">{{ v.file.name }}</a></td>
            <td>{{ v.track.index }}</td>
            <td>
              <select class="form-select" [(ngModel)]="v.track.track_type" [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + v.file.id)">
                <option [value]="TrackType.Closedcaption">CC</option>
                <option [value]="TrackType.Subtitle">Subs</option>
              </select>
            </td>
            <td>
              <select class="form-select" [(ngModel)]="v.track.language" [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + v.file.id)">
                <option *ngFor="let l of languages" [value]="l.id">{{ l.name }}</option>
              </select>
            </td>
            <td style="width: 40px;">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" [(ngModel)]="v.track.enabled"  [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + v.file.id)">
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="imageTracks.length > 0">
      <div class="col-12">
        <div class="cl-h3">Images</div>
      </div>
      <div class="col-12">
        <table class="cl-table-data cl-form">
          <thead>
          <tr>
            <th>File</th>
            <th style="width: 40px;">En.</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let v of imageTracks">
            <td class="cl-font-condensed"><a [routerLink]="v.file |link ">{{ v.file.name }}</a></td>

            <td style="width: 40px;">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" [(ngModel)]="v.track.enabled"  [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + v.file.id)">
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="cl-h3">Tags</div>
      </div>
      <div class="col-12">
        <div>
          <table
            class="cl-table-data cl-form">
            <thead>
            <tr>
              <th style="width: 33%">key</th>
              <th style="width: 66%">value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="!tags || tags.length === 0" class="cl-tr-no-data">
              <td colspan="100">
                <div style="display: flex; flex-direction: row; width: 100%; justify-content: center">
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-top: 7px">No tags available.

                      <a role="button" *ngIf="'contentplatform:repository:Mutation/TagFolder' | isAllowed: 'contentplatform:folder:input.folder_id/' + folder.id: true" class="cl-a-quickaction"
                         (click)="onAddTag($event)">
                        Add tag
                      </a></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngFor="let tag of tags; let i = index;let last=last; let first= first">
              <td style="width: 33%">

                <input *ngIf="('contentplatform:repository:Mutation/TagFolder' | isAllowed: 'contentplatform:folder:input.folder_id/' + folder.id); else keyelse" type="text" [(ngModel)]="tags[i].key">

                <ng-template #keyelse>{{ tags[i].key }}</ng-template>
              </td>
              <td style="width: 66%">
                <div style="display: flex; flex-direction: row"
                     *ngIf="('contentplatform:repository:Mutation/TagFolder' | isAllowed: 'contentplatform:folder:input.folder_id/' + folder.id); else valueelse">

                  <input type="text" [(ngModel)]="tags[i].value">
                  <a role="button" *ngIf="last" class="cl-a-quickaction" style="white-space:nowrap;"
                     (click)="onAddTag($event)">
                    Add&nbsp;tag
                  </a>
                </div>
                <ng-template #valueelse>{{ tags[i].value }}</ng-template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 text-end" *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + folder.id">
          <div style="margin-top: 16px" class="cl-spacing">

            <button (click)="save_changes()" [class.cl-btn-spinner]="savingchanges"
                    class="btn btn-sm cl-btn-outline-primary"><span>Save changes</span><i class="fas fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
