import {Injectable} from '@angular/core';
import {OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {Apollo} from 'apollo-angular';
import {GET_USER_PROFILE} from '../queries';
import {Query, UserProfilePayload} from '../types';
import {Observable, switchMap} from 'rxjs';
import {filter, map, shareReplay, startWith} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private up: Observable<UserProfilePayload>

  constructor(oauthService: OAuthService, apollo: Apollo) {
    this.up = oauthService.events.pipe(
      startWith({type: 'dummy_event'}),
      filter((event: OAuthEvent) => oauthService.hasValidAccessToken()),
      switchMap(event => {
        console.log('Fetching user profile', event)
        return apollo.query<Query>({
          query: GET_USER_PROFILE
        }).pipe(
          map(res => res.data.userProfile),
          filter(userProfile => !!userProfile) // Ensure only non-null user profiles are emitted
        );
      }),
      shareReplay(1)
    )
  }

  get staff() {
    return this.up.pipe( map(up => up.staff))
  }

  get name() {
    return this.up.pipe(map(up => up.name))
  }

  get email() {
    return this.up.pipe(map(up => up.email))
  }
}
