import {Pipe, PipeTransform} from '@angular/core';

/*
 * Convert ISO string future time to remaining HH:MM:SS
 * Usage:
 *   time_string | remainingTime[:negative_string_value]
 *
 * negative_string_value - value for possible negative time difference
 */
@Pipe({
  name: 'remainingTime',
  pure: false
})
export class RemainingTimePipe implements PipeTransform {
  hms(seconds: number) {
    const  arr: number[] = [];
    [3600, 60, 1].forEach(val => {
      arr.push(Math.floor(seconds / val));
      seconds %= val;
    });
    return arr.map(val => val < 10 ? ('0' + String(val)) : String(val)).join(':');
  }

  transform(time_str: string, neg_value: string): string {

    if (time_str) {
      const time_diff = (new Date(time_str).valueOf() - new Date().valueOf()) / 1000;
      return time_diff < 0 ? neg_value : this.hms(time_diff);
    }
  }
}
