<div class="row cl-spacing-small" [formGroup]="form">
  <div class="col-6">
    <label for="input_brefix" class="form-label">Source Folder </label>
    <input disabled id="input_brefix" type="text" formControlName="input_brefix">
    <p *ngIf="form.controls.input_brefix.errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form.controls.input_brefix.errors" class="valid-feedback">You can
      <a [routerLink]="'/files/browse/' + form.controls.input_brefix.value  ">navigate to the source folder</a>
    </p>
  </div>
  <div class="col-6">
    <label for="po_item_id" class="form-label">Encode ID<span class="required">*</span></label>
    <input id="po_item_id" type="text" formControlName="po_item_id" pattern="[A-Za-z0-9_\-.]+">
    <p *ngIf="form.controls.po_item_id.errors?.required" class="invalid-feedback">This field is
      required.</p>
  </div>


</div>
