import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {File, Folder, Mutation, Organization, PreviewTokenPayload, ProcessStateEnum, Query, Tag, TagInput, TrackType} from '../../../types';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin, Subscription} from 'rxjs';
import {catchError, filter, map, mergeMap, skipWhile, startWith, take} from 'rxjs/operators';
import {
  FILE_DETAILS,
  GET_FOLDER_ALL_DETAILS,
  GET_PROCESS,
  GET_UPFRONT_TOKEN,
  PREVIEW_GENERATE,
  START_GENERATE_STILLS
} from '../../../queries';
import {PreviewRegistrationService} from '../../../services/preview-registration.service';
import {Apollo} from 'apollo-angular';
import {BannerService} from '../../../banner/banner.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import gql from 'graphql-tag';
import {PoMetaService} from '../../po-meta.service';
import {Constants} from '../../../globals';
import {Dialog} from '@angular/cdk/dialog';
import {GenerateUploadLinkComponent} from '../generate-upload-link-dialog/generate-upload-link-dialog.component';

export interface FileTrack {
  file: File;
  track: {
    index: number;
    enabled: boolean;
    language: string;
    track_type: TrackType;
  }
  channelLayout?: string;
  channelWarn?: boolean;
}

@Component({
  selector: 'app-folder-asset-view',
  templateUrl: './folder-asset-view.component.html',
  styleUrls: ['./folder-asset-view.component.scss']
})
export class FolderAssetViewComponent implements OnInit, OnDestroy {
  @Input() folder!: Folder;
  @Input() organization: Organization;

  _folder: Folder;
  parent: Folder

  videoTracks: FileTrack[] = [];
  audioTracks: FileTrack[] = [];
  textTracks: FileTrack[] = [];
  imageTracks: FileTrack[] = [];

  languages = Constants.languages;

  selectedVideoFileTrack: FileTrack;
  selectedAudioFileTrack: FileTrack;
  selectedTextFileTrack: FileTrack;

  savingchanges = false;
  previewTokenPayload: PreviewTokenPayload;
  tags: Tag[];

  public readonly TrackType = TrackType;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private previewRegistrationService: PreviewRegistrationService,
    private apollo: Apollo,
    private banner: BannerService,
    private snackBar: MatSnackBar,
    public poMetaService: PoMetaService,
    private dialog: Dialog,
  ) {


  }

  ngOnInit(): void {
    this.subscriptions.push(this.previewRegistrationService.getPreviews().pipe(
      map(ps => ps.find(p => p.folder_id === this.folder.id)),
      filter(p => p !== undefined),
      mergeMap(preview_data => {
        return this.apollo.mutate<Mutation>({
          mutation: GET_UPFRONT_TOKEN,
          variables: {
            'asset_id': preview_data.asset_id
          }
        }).pipe(
          catchError((err, caught) => {
            this.previewRegistrationService.removePreview(preview_data)
            this.banner.open(err, ['Close']);
            throw err
          })
        )
      }),
      map(upfrontTokenResponse => upfrontTokenResponse.data.previewGenerateToken),
    ).subscribe(upfrontTokenResponse => this.previewTokenPayload = upfrontTokenResponse))

    this.subscriptions.push(this.apollo.watchQuery<Query>({
        query: GET_FOLDER_ALL_DETAILS,
        variables: {
          id: this.folder.id
        },
        nextFetchPolicy: 'cache-only',
      }
    ).valueChanges.pipe(
      skipWhile(response => response.loading),
      map(response => response.data.folder)).pipe(
      startWith(this.folder)).subscribe(f => {
      if (f.parents) {
        let ps = f.parents
        ps = [...ps].reverse()
        while (ps[0].id === this.folder.id) {
          ps.shift()
        }
        this.parent = ps.shift()
      }

      this._folder = f;
      this.tags = this._folder.tags;
      if (this.tags == null || this.tags.length === 0) {
        this.tags = []
      }
      this.tags = this.tags.map(t => {
        return {'key': t.key, 'value': t.value}
      });
      this.audioTracks = [];
      this.videoTracks = [];
      this.textTracks = [];
      this.imageTracks = [];
      for (const file of f.files) {
        if (file.tracks && !file.deleted) {
          for (const track of file.tracks) {
            if (track.track_type === TrackType.Video) {
              this.videoTracks.push({file, track: {...track}})
            } else if (track.track_type === TrackType.Audio) {
              let channelLayout = '';
              let channelWarn = false;
              if (file.mediainfo_json) {
                const trackJson = file.mediainfo_json
                  .map(s => JSON.parse(s))
                  .find(s => (s.hasOwnProperty('StreamOrder') ? parseInt(s['StreamOrder'], 10) : 0) === track.index)
                if (trackJson) {
                  channelLayout = trackJson.hasOwnProperty('ChannelLayout') ? trackJson['ChannelLayout'] :
                    (trackJson.hasOwnProperty('Channels') ? trackJson['Channels'] + ' Ch' : 'unknown')
                  channelWarn = trackJson.hasOwnProperty('Channels') && trackJson['Channels'] !== '2'
                }
              }
              this.audioTracks.push({file, track: {...track}, channelLayout, channelWarn})
            } else if (track.track_type === TrackType.Subtitle || track.track_type === TrackType.Closedcaption) {
              this.textTracks.push({file, track: {...track}})
            } else if (track.track_type === TrackType.Image) {
              this.imageTracks.push({file, track: {...track}})
            }
          }
        }
      }
      this.selectedAudioFileTrack = this.audioTracks.find(t => t.track.enabled);
      this.selectedVideoFileTrack = this.videoTracks.find(t => t.track.enabled);
      this.selectedTextFileTrack = this.textTracks.find(t => t.track.enabled);
    }))
  }

  viewAsListing() {
    this.router.navigate([], {relativeTo: this.route, queryParams: {view: 'listing'}, queryParamsHandling: 'merge', replaceUrl: true})
  }

  generateUploadLink() {
    this.dialog.open(GenerateUploadLinkComponent, {data: {folder: this.folder}})
  }

  generatePreview() {
    const ref = this.snackBar.open(`Starting preview generation for ${this.folder.name}  ... `, null, {
      duration: 5000
    });
    this.subscriptions.push(this.apollo.mutate<Mutation>({
      mutation: PREVIEW_GENERATE,
      variables: {
        folder_id: this.folder.id
      }
    }).subscribe({
        next: d => {
          const wq = this.apollo.watchQuery<Query>({
            query: GET_PROCESS,
            pollInterval: 1000,
            variables: {
              'id': d.data.previewGenerate.id
            }
          })
          wq.valueChanges.pipe(
            skipWhile((data) => {
              return data.data.process.state === ProcessStateEnum.InProgress
            }),
            take(1),
            map(data => {
              ref.dismiss()
              if (data.data.process.state === ProcessStateEnum.Failed) {
                this.banner.open('Preview generation failed. ' + data.data.process.message, ['CLOSE'])
              } else if (data.data.process.state === ProcessStateEnum.Success) {
                return JSON.parse(data.data.process.data)
              }
            }),
          ).subscribe(upfrontTokenResponse => {
              this.previewRegistrationService.addPreview(upfrontTokenResponse);
            }
          )
        },
        error: err => {
          ref.dismiss()
          this.banner.open(
            `The preview generation for ${this.folder.name} did not succeed.`,
            ['Close'])
        }
      }
    ))
  }

  addToPo() {
    console.log('bangbang')

    console.log('folder')
    console.log(this.folder)
    const preselectedAudio = new Set<string>();
    const preselectedSubtitles = new Set<string>();
    const preselectedCC = new Set<string>();
    const images = new Set<string>();
    for (const file of (this._folder || this.folder).files) {
      if (!file.tracks) {
        continue;
      }

      for (const track of file.tracks) {
        if (!track.enabled) {
          continue;
        }

        switch (track.track_type) {
          case TrackType.Audio:
            preselectedAudio.add(track.language);
            break;
          case TrackType.Subtitle:
            preselectedSubtitles.add(track.language);
            break;
          case TrackType.Closedcaption:
            preselectedCC.add(track.language);
            break;
          case TrackType.Image:
            images.add(file.id.split('/').pop());
            continue;
        }
      }
    }

    console.log('About to add to PO');
    this.poMetaService.poService.addToPO(
      this.folder.id,
      [...preselectedAudio],
      [...preselectedSubtitles],
      [...preselectedCC],
      [...images]
    );

    this.snackBar.open('Added ' + this.folder.name + ' to PO', null,
      {duration: 5000}
    );
  }

  generate_stills() {

    const ref = this.snackBar.open(`Started generation of still images for ${this.selectedVideoFileTrack.file.name}  ... `, null, {
      duration: 5000
    });
    this.apollo.mutate<Mutation>({
      mutation: START_GENERATE_STILLS,
      variables: {
        file_id: this.selectedVideoFileTrack.file.id
      }
    }).pipe(take(1)).subscribe({
        next: d => {
          console.log(d)
          const wq = this.apollo.watchQuery<Query>({
            query: GET_PROCESS,
            pollInterval: 1000,
            variables: {
              'id': d.data.generate_stills.id
            }
          })
          wq.valueChanges.pipe(
            skipWhile((data) => {
              return data.data.process.state === ProcessStateEnum.InProgress
            }),
            take(1),
          ).subscribe(data => {
              if (data.data.process.state === ProcessStateEnum.Success) {
                this.apollo.query<Query>({
                  query: FILE_DETAILS,
                  variables: {
                    id: this.selectedVideoFileTrack.file.id
                  },
                  fetchPolicy: 'network-only'
                }).subscribe(() => {
                  ref.dismiss()
                  this.snackBar.open(
                    `Stills for ${this.selectedVideoFileTrack.file.name} generated successfully.`,
                    null,
                    {duration: 5000})
                })
              } else {
                ref.dismiss()
                this.banner.open(
                  `The preview generation for ${this.selectedVideoFileTrack.file.name} did not succeed - ${data.data.process.state}: ${data.data.process.message}`,
                  ['Close'])
              }
            }
          )
        },
        error: err => {
          ref.dismiss()
          this.banner.open(
            `Submission of preview encode failed: ${err}`,
            ['Close'])
        },
        complete: () => {
          console.log('completed')
        }
      }
    )
  }

  onAddTag(event: Event) {
    this.tags = [...this.tags, {key: '', value: ''}]
  }

  save_changes() {
    this.savingchanges = true;
    const saveMutations = []
    for (const f of this._folder.files) {
      const tracks = [];
      [this.videoTracks, this.audioTracks, this.textTracks].forEach(t => {
        t.filter(v => v.file.id === f.id).forEach(v => {
          tracks.push(`{
            index: ${v.track.index},
            enabled: ${v.track.enabled},
            language: "${v.track.language}",
            track_type: ${v.track.track_type}
          }`)
        })
      })
      saveMutations.push(this.apollo.mutate<Mutation>({
        mutation: gql`mutation save_tracks($id: ID!) {
        updateFile(input: {
          file_id: $id
          tracks: [${tracks.join(',\n')}
          ]
        }) {
          file {
            id
            tracks {
              index
              enabled
              language
              track_type
            }
          }
        }
    }
    `,
        variables: {
          id: f.id,
        }
      }))
    }


    const tagInputArray: Array<TagInput> = this.tags.map(({key, value}) =>
      key && key !== '' ? {key, value} : null).filter(p => p !== null);


    saveMutations.push(this.apollo.mutate<Mutation>({
        mutation: gql`
        mutation foldertags($id: ID!, $tags: [TagInput!]) {
          tag_folder(input: { folder_id: $id, tags: $tags } ) {
            folder {
              id
              tags {
                key
                value
              }
            }
          }
        }`,
        variables: {
          'id': this.folder.id,
          'tags': tagInputArray
        }
      }
    ))
    forkJoin([...saveMutations]).pipe(map(() => this.apollo.watchQuery<Query>({
        query: GET_FOLDER_ALL_DETAILS,
        variables: {
          id: this.folder.id
        },
        nextFetchPolicy: 'network-only',
      }
    ))).subscribe(() => {
      this.savingchanges = false;
      this.snackBar.open('Changes saved successfully', null, {duration: 5000})
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
