import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {GET_POLICIES} from './queries';
import {Observable} from 'rxjs';
import {Policy, Query} from './types';
import {map, tap} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';

export const licensedServicesResolver: ResolveFn<Observable<String[]>> = (route, state) => {
  const apollo = inject(Apollo);
  const query = GET_POLICIES(route.params['organization_id']);
  return apollo.query<Query>({
    query: query,
    context: {
      headers: new HttpHeaders({
        'X-Castlabs-Organization': route.params['organization_id']
      })
    }
  }).pipe(map(res => res.data.policies.licensedServices));
};


