<ng-template #modalFFProbeJSON>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">FFProbe JSON</div>
      <div class="modal-body">
        <div style="  position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);" *ngIf="(refreshDialog | async)">
          <div class="cl-spinner"><i class="fas fa-spinner fa-spin"></i></div>
        </div>
        <pre>{{ ffprobe| json }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="refresh_ffprobe()" *ngIf="'contentplatform:repository:Mutation/RefreshFFProbe'| isAllowed: 'contentplatform:file:input.file_id/' + file.id">
                        <span>Force refresh</span>
                    </button>
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="copy_ffprobe()">
                        <span>Copy to clipboard</span>
                    </button></span>
        <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
        <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>

      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalWarnings>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">Warnings</div>
      <div class="modal-body">
        <div style="  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);" *ngIf="(refreshDialog | async)">
          <div class="cl-spinner"><i class="fas fa-spinner fa-spin"></i></div>
        </div>
        <pre>{{ warnings | json }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="refresh_basic_qc()" *ngIf="'contentplatform:repository:Mutation/RefreshBasicQc'| isAllowed: 'contentplatform:file:input.file_id/' + file.id">
                        <span>Refresh basic QC</span>
                    </button>
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="copy_warnings()">
                        <span>Copy to clipboard</span>
                    </button></span>
        <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
        <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalExtraInfo>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">Extra info</div>
      <div class="modal-body">
        <div style="  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);" *ngIf="(refreshDialog | async)">
          <div class="cl-spinner"><i class="fas fa-spinner fa-spin"></i></div>
        </div>
        <pre>{{ extra_info | json }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="refresh_extra_info()" *ngIf="'contentplatform:repository:Mutation/RefreshExtra'| isAllowed: 'contentplatform:file:input.file_id/' + file.id">
                        <span>Refresh extra info</span>
                    </button>
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="copy_extra_info()">
                        <span>Copy to clipboard</span>
                    </button></span>
        <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
        <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalMediaInfo>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">MediaInfo JSON</div>
      <div class="modal-body">
        <div style="  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);" *ngIf="(refreshDialog | async)">
          <div class="cl-spinner"><i class="fas fa-spinner fa-spin"></i></div>
        </div>
        <pre>{{ mediainfo_json | json }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="refresh_mediainfo()"  *ngIf="'contentplatform:repository:Mutation/RefreshMediaInfo'| isAllowed: 'contentplatform:file:input.file_id/' + file.id">
                        <span>Refresh mediainfo</span>
                    </button>
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="copy_mediainfo_json()">
                        <span>Copy to clipboard</span>
                    </button></span>
        <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
        <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>
      </div>
    </div>
  </div>

</ng-template>


<ng-template #modalMediaInfoTxt>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">MediaInfo text</div>
      <div class="modal-body">
        <div style="  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);" *ngIf="(refreshDialog | async)">
          <div class="cl-spinner"><i class="fas fa-spinner fa-spin"></i></div>
        </div>
        <pre>{{ mediainfo }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="refresh_mediainfo()"  *ngIf="'contentplatform:repository:Mutation/RefreshMediaInfo'| isAllowed: 'contentplatform:file:input.file_id/' + file.id">
                        <span>Refresh mediainfo</span>
                    </button>
                    <button type="button" class="btn cl-btn-outline-text it-cancel"
                            (click)="copy_mediainfo()">
                        <span>Copy to clipboard</span>
                    </button></span>
        <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
        <span class="modal-footer-right cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn cl-btn-outline-primary it-ok"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>
      </div>
    </div>
  </div>
</ng-template>

<app-logo-area [breadcrumbs]="breadcrumps$ | async"></app-logo-area>
<main>
  <app-headline-section [title]="'Assets'"></app-headline-section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>
            <app-asset-icon class="cl-faded" [thing]="file"></app-asset-icon>
            {{ this.file?.name }}
            <a class="cl-a-quickaction" [routerLink]="folder | link"
               style="white-space: nowrap;">..&nbsp;(up)</a>
          </h2>
        </div>
        <div class="col-12">
          <app-s3-link [fof]="file"></app-s3-link>
        </div>
      </div>
    </div>

  </section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row" *ngIf="refresh | async">
        <div class="col-12">
          <div class="cl-spinner">Loading ...<i class="fas fa-spinner fa-spin"></i></div>
        </div>
      </div>
      <div class="row" *ngIf="!(refresh | async)">
        <div class="col-12" style="display: flex; flex-direction: row">
          <div>
            <h3>Tracks</h3>
          </div>
          <div *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + file.id" style="margin-left: auto">
            <button (click)="refreshTracks()" class="btn btn-sm cl-btn-outline-text"><span>Reset tracks</span><i
              class="fas fa-angle-right"></i>
            </button>
          </div>
        </div>
        <div class="col-12">
          <form role="form" class="castlabs-ui" #form="ngForm">

            <table class="cl-table-data" style="table-layout: fixed">
              <colgroup>
                <col span="1" style="width: 60px">
                <col span="1">
                <col span="1">
                <col span="1" style="width: 70px;">
              </colgroup>
              <thead>
              <tr>
                <th>index</th>
                <th>track&nbsp;type</th>
                <th>language</th>
                <th>enabled</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="tracks.length === 0">
                <td colspan="4" style="text-align: center;">No tracks available.</td>
              </tr>
              <tr *ngFor="let track of tracks; let i = index;">
                <td>
                  <div [style.color]="track['enabled']?'unset':'rgba(64, 64, 64, 0.38)'">{{ track['index'] }}</div>
                </td>
                <td>
                  <ng-container
                    *ngIf="track['track_type'] == TRACKTYPES.Audio ||  track['track_type'] == TRACKTYPES.Video||  track['track_type'] == TRACKTYPES.Image ||
               track['track_type'] == TRACKTYPES.Data || !('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + file.id ); else selecttype">
                    <div [style.color]="track['enabled']?'unset':'rgba(64, 64, 64, 0.38)'"
                         style="width: 100%; overflow: visible"> {{ track['track_type'] }}
                    </div>
                  </ng-container>
                  <ng-template #selecttype>
                    <div class="cl-form">


                      <select class="form-select" name="track_type{{i}}" [(ngModel)]="track['track_type']"
                              [disabled]="!track['enabled']"
                              *ngIf="track['track_type'] == TRACKTYPES.Subtitle ||  track['track_type'] == TRACKTYPES.Closedcaption">
                        <option *ngFor="let tt of [TRACKTYPES.Subtitle, TRACKTYPES.Closedcaption]" [value]="tt">
                          {{ tt }}
                        </option>
                      </select>
                    </div>
                  </ng-template>
                </td>
                <td>

                  <ng-container
                    *ngIf="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + file.id); else selectlang">
                    <ng-container *ngFor="let lang of languages">
                      <div [style.color]="track['enabled']?'unset':'rgba(64, 64, 64, 0.38)'"
                           *ngIf="lang.id === track['language']">{{ lang.name }}
                      </div>
                    </ng-container>

                  </ng-container>
                  <ng-template #selectlang>
                    <div style="" class="cl-form">
                      <select
                        [(ngModel)]="track['language']"
                        name="language{{i}}"
                        [disabled]="!track['enabled']"
                        class="form-select">
                        <option *ngFor="let lang of languages" [value]="lang.id">
                          {{ lang.name }}
                        </option>
                      </select>

                    </div>
                  </ng-template>

                </td>
                <td>
                  <div class="form-check" style="margin-top: 0">
                    <input type="checkbox" class="form-check-input" title="{{ track['index'] }} enabled"
                           [(ngModel)]="track['enabled']" name="enabled{{i}}"
                           [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + file.id)">
                  </div>

                </td>
              </tr>
              </tbody>
            </table>

          </form>
        </div>

        <div class="col-12">
          <h3>Tags</h3>
        </div>

        <div class="col-12">
          <table
            class="cl-table-data cl-form">
            <thead>
            <tr>
              <th style="width: 33%">key</th>
              <th style="width: 66%">value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="!tags || tags.length === 0" class="cl-tr-no-data">
              <td colspan="100">
                <div style="display: flex; flex-direction: row; width: 100%; justify-content: center">
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-top: 7px">No tags available.
                      <a role="button" *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + file.id" class="cl-a-quickaction"
                         (click)="onAddTag($event)">
                        Add tag
                      </a></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngFor="let tag of tags; let i = index;let last=last; let first= first">
              <td style="width: 33%">

                <input *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + file.id; else keyelse" type="text" [(ngModel)]="tags[i].key">

                <ng-template #keyelse>{{ tags[i].key }}</ng-template>
              </td>
              <td style="width: 66%">
                <div style="display: flex; flex-direction: row"
                     *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + file.id; else valueelse">

                  <input type="text" [(ngModel)]="tags[i].value">
                  <a role="button" *ngIf="last" class="cl-a-quickaction" style="white-space:nowrap;"
                     (click)="onAddTag($event)">
                    Add&nbsp;tag
                  </a>
                </div>
                <ng-template #valueelse>{{ tags[i].value }}</ng-template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>


        <div class="col-12 " style="display: flex; flex-direction: row" *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + file.id">
          <div style="margin-left: auto">
            <button (click)="save()" class="btn btn-sm cl-btn-outline-primary"><span>Save tags & tracks</span><i
              class="fas fa-angle-right"></i>
            </button>
          </div>
        </div>


        <div class="cl-h3">System-Generated Metadata</div>
        <div class="col-12" style="display: flex; flex-direction: row">
          <div style="margin-left: auto"></div>
          <div style="flex-direction: row; display: flex; justify-content:  space-between; gap: 16px">
            <div>
              <button (click)="openDialog(modalFFProbeJSON)" class="btn btn-sm cl-btn-outline-text">Show FFProbe JSON
              </button>
            </div>
            <div>
              <button (click)="openDialog(modalMediaInfo)" class="btn btn-sm cl-btn-outline-text">Show MediaInfo JSON
              </button>
            </div>

            <button (click)="openDialog(modalMediaInfoTxt)" class="btn btn-sm cl-btn-outline-text">Show MediaInfo text
            </button>
          </div>
        </div>

        <div class="cl-h3">QC and extra information</div>
        <div style="display: flex; flex-direction: row;">
          <div style="margin-left: auto"></div>
          <div style="flex-direction: row; display: flex; justify-content:  space-between; gap: 16px">
            <button (click)="openDialog(modalWarnings)" class="btn btn-sm cl-btn-outline-text">Show basic QC
              <i style="color: #2a80d5" *ngIf="warnings.length > 0" class="fa fa-times-circle"></i>
            </button>
            <button (click)="openDialog(modalExtraInfo)" class="btn btn-sm cl-btn-outline-text">Show extra info</button>

          </div>
        </div>

        <ng-container *ngIf="has_video">
          <div class="cl-h3">Stills</div>
          <ng-container *ngIf="(this.stills) as stills">
            <ng-container *ngIf="stills.length > 0; else nostills">
              <ngx-gallery [options]="galleryOptions" [images]="stills" class="ngx-gallery"></ngx-gallery>
            </ng-container>
            <ng-template #nostills>
              <div style="display: flex; flex-direction: row;align-items: center">
                <div style="flex-grow: 1; display: flex; flex-direction: column; align-self: center">
                  <div style="text-align: center">
                    <img width="125" src="../../../../assets/emptystates/NoImages.png"/>
                  </div>
                  <div class="cl-h3" style="text-align: center">There are no stills.</div>
                </div>
                <div style="align-self: flex-end">
                  <button (click)="generate_stills()" class="btn btn-sm cl-btn-outline-primary">
                    <span>Generate stills</span><i
                    class="fas fa-angle-right"></i>
                  </button>

                </div>
              </div>
            </ng-template>

          </ng-container>
        </ng-container>
      </div>
    </div>
  </section>
</main>
