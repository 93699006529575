import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, Validators
} from '@angular/forms';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-aerq-default-format-specific',
  templateUrl: './aerq-default-format-specific.component.html',
  styleUrls: ['./aerq-default-format-specific.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AerqDefaultFormatSpecificComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AerqDefaultFormatSpecificComponent,
      multi: true
    }
  ]
})
export class AerqDefaultFormatSpecificComponent implements OnInit, ControlValueAccessor, OnChanges {
  static DEFAULTS = {
    render_title_info: false,
    encrypt: true,
    default_keys: false,
    do_forensic_watermark: true,
    album_cover_url: '',
    artist: '',
    album: '',
    title: ''
  };

  @Input()
  parentForm: FormGroup;

  @Input() vtk_template: string;

  form: FormGroup;

  protected readonly environment = environment;

  changed: any = () => {
  };
  touched: any = () => {
  };

  constructor(private formBuilder: FormBuilder) {
    console.log('constructing TrackSelectionComponent')
    this.form = this.formBuilder.group({
      render_title_info: false,
      encrypt: true,
      default_keys: false,
      do_forensic_watermark: true,
      album_cover_url: '',
      artist: '',
      album: '',
      title: ''
    });
    this.form.valueChanges.subscribe((val) => {

      this.touched();
      this.changed(val)
    });
  }

  ngOnInit(): void {
  }

  writeValue(value: any): void {
    if (value) {
      this.form.patchValue(value, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState ?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.vtk_template === 'aerq-ife-audio') {
      this.form.controls['album_cover_url'].setValidators([Validators.required]);
      this.form.controls['artist'].setValidators([Validators.required]);
      this.form.controls['album'].setValidators([Validators.required]);
      this.form.controls['title'].setValidators([Validators.required]);
    } else {
      this.form.controls['album_cover_url'].clearValidators();
      this.form.controls['artist'].clearValidators();
      this.form.controls['album'].clearValidators();
      this.form.controls['title'].clearValidators();
    }
    this.form.controls['album_cover_url'].updateValueAndValidity();
    this.form.controls['artist'].updateValueAndValidity();
    this.form.controls['album'].updateValueAndValidity();
    this.form.controls['title'].updateValueAndValidity();

  }

}
