import {Component, Input, OnInit, Pipe, PipeTransform, TemplateRef, TrackByFunction, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {distinctUntilChanged, filter, map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of} from 'rxjs'
import {MatSnackBar} from '@angular/material/snack-bar';
import {Apollo} from 'apollo-angular';
import {Airline, PoItem, Query as WorkflowQuery} from '../../types-workflow';
import {CREATE_UPLOAD_TICKET, PO_ITEM_LIST_FULL} from '../../queries';
import {FinalizePoDialogComponent} from './finalize-po-dialog/finalize-po-dialog.component';
import {Mutation, Organization} from '../../types';

import {BreadCrumbs} from '../logo-area/logo-area.component';
import {Dialog} from '@angular/cdk/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl, FormGroup} from '@angular/forms';
import {OrganizationService} from '../../services/organization.service';

interface Upload {
  url: string;
  text: string;
}

@Pipe({
  name: 'remove'
})
export class RemovePipe implements PipeTransform {
  transform(value: string, removeValue: string): any {
    return value.replace(removeValue, '');
  }
}


@Component({
  selector: 'app-list-po-items2',
  templateUrl: './list-po-items2.component.html',
  styleUrls: ['./list-po-items2.component.scss']
})
export class ListPoItems2Component implements OnInit {
  opened: SelectionModel<PoItem> = new SelectionModel<PoItem>(false, [], true, ((a, b) => a.po_item_id === b.po_item_id))

  @Input()
  airline: Airline;
  @Input()
  organization: Organization;

  @ViewChild('toolbaractions', {read: TemplateRef})
  public toolbaractions: TemplateRef<any>;

  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
    e: new FormControl<string>(null),
    p: new FormControl<string>(null),
  });


  menu = new BehaviorSubject<TemplateRef<any>>(null);
  uploads: Upload[] = [];
  iata_code$: Observable<string>;
  po$: Observable<string>;
  po_name: string;

  poitems$: Observable<PoItem[]>;
  poitems_filtered$: Observable<PoItem[]>;
  loading = true;

  breadCrumbs: Observable<BreadCrumbs>;
  hasMetadata$: Observable<boolean>;

  constructor(private route: ActivatedRoute,
              private organizationService: OrganizationService,
              protected dialog: Dialog,
              private apollo: Apollo,
              public snackBar: MatSnackBar) {
    this.po$ = this.route.params.pipe(
      map(params => params['po']),
      distinctUntilChanged(),
      tap(po_name => {
        this.po_name = po_name
      }),
      shareReplay()
    );
    this.iata_code$ = this.route.params.pipe(
      map(params => params['airline']),
      distinctUntilChanged()
    )

    this.poitems$ = this.po$.pipe(
      tap(() => {
        this.loading = true
      }),
      mergeMap(po_name => {
        return this.apollo.use('workflow').watchQuery<WorkflowQuery>({
          query: PO_ITEM_LIST_FULL,
          nextFetchPolicy: 'cache-and-network',
          pollInterval: 10000,
          variables: {
            'po_name': po_name
          }
        }).valueChanges
      }),
      filter(wfq => wfq.partial !== true),
      map(wfq => {
        const data = wfq.data
        if (data.list_POs.pos[0] === undefined) {
          return []
        }
        const p = [...data.list_POs.pos[0].poitems]
        p.sort((a, b) => {
          return a.po_item_id > b.po_item_id ? 1 : -1
        })
        return p
      }),
      tap(() => this.loading = false),
      shareReplay()
    )
    this.hasMetadata$ = this.poitems$.pipe(
      map(poItems => poItems.some(poItem => poItem.checkpoint_metadata_available != null))
    );
    this.poitems_filtered$ = combineLatest([this.poitems$, this.filters.valueChanges]).pipe(
      map(([poitems, filters]) => poitems.filter(po => {
        return (
            po.po_item_id.toLowerCase().includes((filters['t'] || '').toLowerCase()) ||
            po.filename.toLowerCase().includes((filters['t'] || '').toLowerCase()))
          && ((filters['e'] || '') === '' || (filters['e'] || '') === po.workflow_process.state)
          && ((filters['p'] || '') === '' || (filters['p'] || '') === po.publish_process.state)
      })),
    )
  }

  poItemTrackBy: TrackByFunction<PoItem> = (index: number, poitem: PoItem) => {
    return poitem.id
  }

  genUploads() {
    this.poitems$.subscribe(poitems => {

      const _uploads: Observable<Upload>[] = [];
      for (const poitem of poitems) {
        if (this.organizationService.isAllowed('contentplatform:repository:Mutation/CreateUploadTicket', 'contentplatform:folder:input.folder_id/' + poitem.input_brefix)) {
          _uploads.push(this.apollo.mutate<Mutation>({
            mutation: CREATE_UPLOAD_TICKET,
            variables: {
              folder_id: `s3://${poitem.input_brefix}`,
              message: poitem.po_item_id
            },
            fetchPolicy: 'network-only'
          }).pipe(
            map(d => {
              return {
                url: d.data.createUploadTicket.url,
                text: poitem.po_item_id
              }
            })));
        } else {
          _uploads.push(of({
            url: null,
            text: 'You are not allowed to upload to ' + poitem.input_brefix
          }))
        }
      }

      forkJoin(_uploads).subscribe(u => this.uploads = u)
    })
  }

  ngOnInit() {

    this.breadCrumbs = this.po$.pipe(
      map(po => {
          return [
            {
              display: 'Content Portal',
              commands: ['/o', this.organization.id],
            },
            {
              display: 'Workflows',
              commands: ['/o', this.organization.id, 'workflows'],
            },
            {
              display: this.airline.airline_name,
              commands: ['/o', this.organization.id, 'workflows', this.airline.iata_code],
            },
            {
              display: po,
              commands: ['/o', this.organization.id, 'workflows', this.airline.iata_code, po],
            }
          ];
        }
      ));
  }

  openDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  publish() {
    this.dialog.open(FinalizePoDialogComponent, {
      data: {po_name: this.po_name}
    })
  }

}
